import React from 'react';
import { withTranslation } from "react-i18next";

const FreePaymentCompletedInformation = ({ t }) => {
    return (
        <div>
            {t('nothing_to_pay')}
        </div>
    )
}

export default withTranslation(['common', 'payment'])(FreePaymentCompletedInformation);