import { SET_MORPH_USER, UNSET_MORPH_USER, UPDATE_MORPH_USER, SET_NEW_MORPH_USER } from "../../constants/ActionTypes"
import { getMorphOrganization } from "../../client/user"

export const setMorphUser = (data) => {
    return {
        type: SET_MORPH_USER,
        payload: data
    }
}

export const setNewMorphUser = (data) => {
    return {
        type: SET_NEW_MORPH_USER,
        payload: data
    }
}

export const unsetMorphUser = () => {
    return {
        type: UNSET_MORPH_USER,
        payload: null
    }
}

function requestMorphUser(id) {
    return {
        type: 'REQUEST_MORPH_USER',
        payload: id
    }
}


export function fetchMorphUser(id) {

    return (dispatch) => {
        dispatch(requestMorphUser(id));
        return getMorphOrganization(id)
            .then(r => r.data)
            .then(data => {
                dispatch(setMorphUser(data));
            }).catch(err => {
                console.log("fetchMorphUser@error", err)
            });
    }
}


export const updateMorphUser = (org) => {
    return {
        type: UPDATE_MORPH_USER,
        payload: org
    }
}