export const APP_NAME = 'Keepsporting'

export const HEAD = {
    TITLE: `${APP_NAME} - Sports Platform for Events and Organizers`,
    DESCRIPTION: `${APP_NAME} is the most comprehensive Sports platform for all sporting events and organizers. The platform addresses everything related to sports.`
}

export const COMPETITION = {
    TYPE: {
        REPORTED: "reported",
        MANAGED: "managed"
    }
};

export const EVENT = {
    TYPE: {
        COMPETITION: 1,
        WORKOUT: 2,
        COURSE: 3,
        CAMP: 4,
        TRAVEL: 5
    }
}


export const USER = {
    FRIENDSHIP_STATUS: {
        ACCEPTED: 1,
        PENDING: 2,
        REJECTED: 3
    },
    TYPE: {
        ATHLETE: 3,
        ORGANIZATION: 4
    },
    ROLES: {
        ADMIN: 'admin',
        USER: 'user',
        MORPH_ONLY: 'morph_only',
        USER_ONLY: 'user_only'
    }
};

export const LANGUAGES = {
    DEFAULT: 'en',
    SUPPORTED: ['en', 'it', 'pt', 'es', 'de']
}


export const LAYOUT = {
    BREAKPOINT: {
        XS: 0,
        SM: 576,
        MD: 768,
        LG: 992,
        XL: 1200,
        XXL: 1600,
    },
    PRIMARY_COLOR: '#205173',
    PRIMARY_COLOR_LIGHT: '#527da2' ///mdtool
}

export const LOADING = 'loading';

export const CLIENT_TIMEOUT = 120//sec