import React from 'react';
import { withTranslation } from "react-i18next";
import { getTypeByPaymentSlug } from "util/payments";

class PayuCompletedInformation extends React.Component {

    /**
     * payment could have full transaction (re-fetch case) or
     * publishable response with keys 'paid, active, link, expire_at, charge_id'
     */
    constructor(props) {
        super(props);

        this.paymentData = {};

        if (props.payment.transaction) {
            const { transaction } = props.payment;
            this.paymentData = {
                active: 'payu',
                paid: props.payment.paid,
                //link: transaction.payoff.charge.link,
                //expire_at: transaction.payoff.charge.expire_at,
                payment_id: transaction.paymentId
            }
        } else {
            this.paymentData = props.payment;
        }
    }


    render() {
        const { t } = this.props;

        if (this.paymentData.paid) {
            return (
                <div>
                    <p>{t('payment_successfully_completed')} ({this.paymentData.active})</p>
                    <ul>
                        <li>{t("payment_type")}: <strong>{t('payment_type_' + getTypeByPaymentSlug(this.paymentData.active))}</strong></li>
                        <li>{t("payment_id")}: <strong>{this.paymentData.payment_id}</strong></li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div>
                    <p>{t('payu_temp_success_response')}</p>
                </div>
            )
        }
    }
}

export default withTranslation([ 'common','payment' ])(PayuCompletedInformation);