import { FETCH_CURRENT_USER, UPDATE_CURRENT_USER, HIDE_MESSAGE, INIT_URL, ON_HIDE_LOADER, ON_SHOW_LOADER, SHOW_MESSAGE, SIGNIN_FACEBOOK_USER, SIGNIN_FACEBOOK_USER_SUCCESS, SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS, SIGNUP_USER, SIGNUP_USER_SUCCESS } from "../../constants/ActionTypes";
import { LOADING } from "../../constants/App";

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (auth) => {  
    return {
        type: SIGNIN_USER,
        payload: auth
    };
};

export const fetchCurrentUser = () => {
    return {
        type: FETCH_CURRENT_USER
    }
}

export const updateCurrentUser = (user) => {
    return {
        type: UPDATE_CURRENT_USER,
        payload: user
    }
}

export const userFetchSuccess = (user) => {
    return {
        type: 'FETCH_USER_SUCCESS',
        payload: user
    }
}

export const userFetchError = (error) => {
    return {
        type: 'FETCH_USER_ERROR',
        payload: error
    } 
}

export const userFetchRequest = () => {
    return {
        type: 'FETCH_USER_REQUEST',
        payload: LOADING
    }
}

/*export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};*/
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authToken) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authToken
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};


export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
