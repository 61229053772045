import { setPaymentMethod } from 'appRedux/actions/Tickets'
import { get, includes } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getGatewayAdviceByTickets, getProviderBySlug } from 'util/Competition'

const EXCEPTION_COMPETITIONS_ID = ['abx2PcPWSaexhEWgB'];

class Bank extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        const { competition } = this.props;
        this.props.dispatch(setPaymentMethod({ active: "bank", payments: competition.payments }));
    }

    onOrderPaymentTotal(tickets, currency) {        
        const { i18n } = this.props;

        let total = 0;

        if (tickets.order && tickets.order.length > 0) {
            tickets.order.forEach(order => {
                //let orderAdds = 0;
                //keys(order.additions).forEach(ak => orderAdds += order.additions[ ak ].value);
                total += order.total;
            });
        }

        if (tickets.discount && tickets.discount.valid) {
            total -= Math.round(total * tickets.discount.percent / 10000) + tickets.discount.fixed;
        }

        return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(total/100);
    }

    /***
     * both runtime and db value
     * @param tickets
     * @param currency
     * @returns {*}
     */
    getPaymentTotal(tickets, currency) {

        const { i18n } = this.props;

        let total = get(tickets, 'payment.transaction.total', - 1);
        if (total === - 1) {
            return this.onOrderPaymentTotal(tickets, currency);
        } else {
            return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(total/100);
        }
    }

    renderAdvice(tickets, competition, t) {
        const adviceDate = getGatewayAdviceByTickets("bank", tickets, competition);

        if (adviceDate) {
            return (
                <h5 dangerouslySetInnerHTML={{ __html: t('payment_expire_date_text', [adviceDate.format('DD-MM-YYYY')]) }}
                    style={{ marginTop: 0 }}>
                </h5>
            )
        }

    }

    render() {

        const { t, tickets, competition, hidePaymentReference } = this.props;

        const bankToken = get(tickets, 'payment.bank_code') ? tickets.payment.bank_code : tickets.id.split('-')[0];
        const currency = competition.payments.currency?.iso;
        const provider = getProviderBySlug(competition, 'bank');

        return (
            <div className="bank-payment" style={{ padding: 15 }}>
                {!hidePaymentReference &&
                    <div>
                        {this.renderAdvice(tickets, competition, t)}

                        <p>{t('payment_reason')}: <strong>{bankToken}</strong></p>

                        <p dangerouslySetInnerHTML={{ __html: provider.description }}></p>
                    </div>
                }
                {!includes(EXCEPTION_COMPETITIONS_ID, competition._id) && //hidden until 06/04/2019
                    <p dangerouslySetInnerHTML={{ __html: t('bank_total_payment_alert', [this.getPaymentTotal(tickets, currency)]) }}></p>
                }
                {!hidePaymentReference &&
                    <p dangerouslySetInnerHTML={{ __html: t('payment_bank_conditions') }}></p>
                }
            </div>
        )
    }
}

Bank.defaultProps = {
    hidePaymentReference: false
};

export default connect()(withTranslation(['common'])(Bank))
