import config from 'config';
import { get, keys, isString, find } from 'lodash';
import { getSafeISOLanguage } from '../lngProvider/i18n';

export const getTranslation = (obj, lang = 'it', def = "") => {

    lang = getSafeISOLanguage(lang)

    if (!obj) {
        return def;
    }
    //try current language
    //TODO FIX ME
    var trans = get(obj, lang, false);

    if (!trans) {
        trans = get(obj, config.fallbackLanguage, '');
    }

    //get first translation
    if (!trans && keys(obj)) {
        const notEmptyLang = find(keys(obj), (key) => obj[key] && isString(obj[key]) && obj[key].length > 0);
        if (notEmptyLang) {
            return obj[notEmptyLang];
        }
    }


    if (!trans) {
        trans = def;
    }

    return trans;
}

export const rightOf = (string, char) => {
    if (string.indexOf(char) > - 1) {
        return string.substring(string.lastIndexOf(char) + 1);
    }
    return string;
}

export const leftOf = (string, char) => {
    if (string.indexOf(char) > - 1) {
        return string.substring(0, string.indexOf(char));
    }
    return string;
}

export const randomString = (chars = 5, pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789") => {
    let text = "";
    for (let i = 0; i < chars; i++)
        text += pool.charAt(Math.floor(Math.random() * pool.length));

    return text;
}

export const safeParseJSON = (json) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
}