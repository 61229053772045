import React from 'react';
import { withTranslation } from 'react-i18next';

const UserAlert = ({ t }) => {
    return (
        <div
            className="gx-alert ant-alert ant-alert-info ant-alert-no-icon gx-mb-3"
            dangerouslySetInnerHTML={{ __html: t('login_if_ks_user', { link: `/login?next=${encodeURIComponent(window.location.pathname)}` }) }}>
        </div>
    )
}

export default withTranslation()(UserAlert)
