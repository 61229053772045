import { clone } from 'lodash';
import { formDataEncode } from "../util/form";
import { buildUrl, getClient, reduceFormData } from "./base";

export const getTicketLimits = id => {
    return getClient().get(`api/competition/tickets/ticket-limits/${id}?${formDataEncode({ t: new Date().getTime() })}`);
};

export const validateDiscountCode = (compId, code) => getClient().get(`api/manager/payment/validate-code/${compId}/${code}`);

export const getTicket = (id, relations = [], query = {}) => {
    return getClient().get(buildUrl(`api/competition/tickets/ticket/${id}`, query, relations))
}

export const getPlayer = (id, relations = [], query = {}) => {
    return getClient().get(buildUrl(`api/competition/manager/player/${id}`, query, relations))
}

export const processTicketsReservation = (reservation) => {
    return getClient().post(`api/competition/tickets/process-reservation`, formDataEncode(reservation));
}

export const processBoletoGerencianetPayment = (data) => {
    let form = clone(data.form);
    form = reduceFormData(form);
    return getClient().post(`api/manager/payment/process-boleto-gerencianet-payment`, formDataEncode({ form: form, paymentToken: data.paymentToken }));
};

export const deleteReservation = (id) => {
    return getClient().get('api/competition/tickets/delete-reservation/' + id);
}

export const restoreTicketsStore = (id) => {
    return getClient().get('api/competition/tickets/restore/' + id)
}


export const processCompetitionOrder = (tickets) => {
    const url = buildUrl('api/competition/tickets/process-tickets')
    return getClient().post(url, formDataEncode(tickets));
}

export const processRenewBoleto = (params) => {
    let query = {};
    if (params.pk) {
        query.pk = params.pk;
    }
    return getClient().get(buildUrl('api/competition/tickets/renew-boleto/' + params.id, query));
}

export const getUserTickets = ({ params }) => {
    return getClient()
        .get(buildUrl(`api/competition/tickets/search`, params))
}