import antdSA from "antd/lib/locale-provider/pt_BR";

const saLang = {
    messages: {
    //...saMessages
    },
    antd: antdSA,
    locale: 'pt',
};
export default saLang;
