import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error500 = () => {

    const { t } = useTranslation();

    return (
        <div className="gx-page-error-container">
            <div className="gx-page-error-content">
                <div className="gx-error-code">500</div>
                <h2 className="gx-text-center">
                    {t('error_500')}
                </h2>

                <p className="gx-text-center">
                    <Link className="gx-btn gx-btn-primary" to="/">{t('goto_homepage')}</Link>
                </p>
            </div>
        </div>
    );
}

export default Error500;
