import { Card } from 'antd';
import React from 'react';
import Bank from '../../Form/Payment/Bank';

class BankData extends React.Component {

    constructor(props) {
        super(props);        
    }

    render() {

        const { t, payment } = this.props;

        return (
            <Card className="gx-card"                               
                title={t('pay_with', [ t(payment.active) ])}>
                <Bank {...this.props} hidePaymentReference={true}/>
            </Card>
        )
    }
}


export default BankData;