import { Col } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import BankData from "./payments/BankData";
import BoletoData from './payments/BoletoData';
import CardGerencianetVerificationData from './payments/CardGerencianetVerificationData';
import PaypalData from "./payments/PaypalData";
import PayUData from "./payments/PayUData";
import StripeData from './payments/StripeData';

class PaymentData extends Component {
    
    renderPaymentBySlug(payment) {
        switch (payment.active){
        case 'paypal':
            return <PaypalData payment={payment} {...this.props}/>;
        case 'payu':
            return <PayUData payment={payment} {...this.props}/>;
        case 'bank':
            return <BankData payment={payment} {...this.props}/>;
        case 'stripe':
            return <StripeData payment={payment} {...this.props}/>;
        case 'boleto':
            return <BoletoData payment={payment} {...this.props} />;
        case 'card-gerencianet':
            return <CardGerencianetVerificationData payment={payment} {...this.props}/>
        }
    }

    render() {

        const { tickets } = this.props;
        const { payment } = tickets;
        return (
            <Col xs={24}>
                {this.renderPaymentBySlug(payment)}
            </Col>
        )
    }
}

export default withTranslation([ 'common' ])(PaymentData)
