import { isNumber, isObject, omitBy } from 'lodash';
import { formDataEncode } from "../util/form";
import { buildUrl, getClient } from "./base";

export const searchCompetitions = (params) => {

    params = omitBy(params, (v) => {
        return !v;
    });

    let page;
    if (!params.page) {
        page = 1;
    } else {
        page = params.page;
        delete params.page;
    }

    if (!params.sort) {
        if (params.date === 'concluded') {
            params.sort = 'desc';
        } else {
            params.sort = 'asc'
        }
    }

    if (isObject(params.location)) {
        params.location = params.location.id;
    }

    return getClient().post(`/api/public-search/competition?${formDataEncode({ page: page, locale: '' })}`, formDataEncode(params))
}

export const searchCompetitionsWithStanding = (params) => {

    params = omitBy(params, (v) => {
        return !v;
    });

    let page;
    if (!params.page) {
        page = 1;
    } else {
        page = params.page;
        delete params.page;
    }   

    if (isObject(params.location)) {
        params.location = params.location.id;
    }

    return getClient({ headers: { 'X-Serializer': 'results' } }).get(`/api/competition/manager/competition-standings?${formDataEncode({ page: page, locale: 'it', ...params })}`)
}

export const searchCompetitionsWithFotop = (params) => {

    params = omitBy(params, (v) => {
        return !v;
    });

    let page;
    if (!params.page) {
        page = 1;
    } else {
        page = params.page;
        delete params.page;
    }   

    if (isObject(params.location)) {
        params.location = params.location.id;
    }

    return getClient({ headers: { 'X-Serializer': 'results' } }).get(`/api/competition/manager/competition-fotop?${formDataEncode({ page: page, locale: 'it', ...params })}`)
}

function prepareUserSearchParams(params) {

    ['business', 'profession', 'usertype'].map(p => params[p] = parseInt(params[p]));

    params = omitBy(params, (v) => {
        return !v;
    });

    if (params.usertype) {
        params.type = params.usertype;
        delete params.usertype;
    }

    if (isObject(params.location) && params.location.id) {
        params.location = params.location.id;
    } else if (!isNumber(params.location)) {
        delete params.location;
    }

    return params;
}


export const searchUsers = (params, uri = 'search/user') => {

    params = prepareUserSearchParams(params);

    let page;
    if (!params.page) {
        page = 1;
    } else {
        page = params.page;
        delete params.page;
    }

    if (params.name) {
        params.complete_name = params.name;
        delete params.name;
    }

    params.sort = 'desc';
    params.type = 4;

    return getClient().post(`/api/${uri}?${formDataEncode({ page: page })}`, formDataEncode(params));
}

export const searchUsersPublic = (params) => {
    params.page = 1;
    params.type = 4;
    params.sort = 'desc';
    return searchUsers(params, 'search/last/user');
}

export const searchUserByEmail = (params) => {
    return getClient().post(`/api/search/user?${formDataEncode({ page: 1 })}`, formDataEncode(params));
}

export const searchUsersWithImagePublic = (params) => {
    return searchUsers(params, 'search/last/image-users');
}

export const searchLastOrganizations = (params) => {
    return searchUsers(params, 'search/last/user');
}

export const searchOrganizationsEventsPhotos = (orgId) => {
    return getClient({ headers: { 'X-Serializer': 'data' } }).get(`/api/competition/manager/organization-events-photos/${orgId}`)
}

export const getCountries = () => {
    return getClient().get('api/location/countries');
}

export const getTicketsPayers = (params) => {
    return getClient().get(buildUrl(`api/competition/tickets/search-admin`, params));
}