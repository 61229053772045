import { cloneDeep, get, find } from 'lodash';
import revalidator from 'revalidator';
import { SUBSCRIPTION } from '../../../../../constants/Tickets';
import BaseValidator from './BaseValidator';

let schema = {
    properties: {
        email: {
            description: '',
            type: 'string',
            format: 'email',
            required: true,
            allowEmpty: false,
        },
        first_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        last_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        gender: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        birthday: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
    },
};

class PlayerValidator extends BaseValidator {
    constructor(values) {
        super();
        this.values = values;
        this.schema = cloneDeep(schema);
        this.result = { errors: [], valid: true };
    }

    validate() {
        this.result = revalidator.validate(this.values, this.schema);
        return this;
    }

    validateField(field) {
        if (this.schema.properties[field]) {
            this.result = revalidator.validate(this.values, { properties: { [field]: this.schema.properties[field] } });
        }
        return this;
    }

    addStandardFields(race) {
        if (!race.standardFields) {
            return;
        }

        if (get(race.standardFields, 'location.active')) {
            const required = get(race.standardFields, 'location.required');
            this.schema.properties['location'] = {
                type: 'integer',
                required: required,
                allowEmpty: !required,
                message: 'location required',
            };
        }

        if (get(race.standardFields, 'phone.active')) {
            const required = get(race.standardFields, 'phone.required');
            this.schema.properties['phone'] = {
                type: ['string', 'null'],
                pattern: /^[0-9]+-[0-9]+$/, //ex +39-123456797 //match server rimosso {1,4} come da accordi
                required: required,
                allowEmpty: !required,
            };
        }

        if (get(race.standardFields, 'citizenship.active')) {
            const required = get(race.standardFields, 'citizenship.required');
            this.schema.properties['citizenship'] = {
                type: 'string',
                required: required,
                allowEmpty: !required,
            };
        }

        if (get(race.standardFields, 'athlete_card.active')) {
            const athleteCard = get(race.standardFields, 'athlete_card');

            const required = athleteCard.required;

            this.schema.properties['athlete_card'] = {
                type: 'string',
                required: required,
                allowEmpty: !required,
            };

            if (get(this.values, 'athlete_card_number_field') === true) {
                const cardConfiguration = find(athleteCard.options, (o) => o.value === this.values.athlete_card);

                const schema = this.getCardNumberSchema(cardConfiguration);

                this.schema.properties['athlete_card_number'] = schema;
            }
        }
    }

    getCardNumberSchema(card) {
        switch (card.type) {
        case SUBSCRIPTION.ATHLETE_CARDS.FIDAL:
            return {
                type: 'string',
                pattern: /^[A-z]{2}[0-9]{6}$/,
                required: true,
                allowEmpty: false,
                messages: {
                    pattern: 'athlete_card_fidal_validation',
                },
            };
        case SUBSCRIPTION.ATHLETE_CARDS.RUNCARD:
            return {
                type: 'string',
                pattern: /^[rR]{2}[0-9]{6}$/,
                required: true,
                allowEmpty: false,
                messages: {
                    pattern: 'athlete_card_runcard_validation',
                },
            };
        case SUBSCRIPTION.ATHLETE_CARDS.FITRI:
            return {
                type: 'string',
                pattern: /^[0-9]{6}$/,
                required: true,
                allowEmpty: false,
                messages: {
                    pattern: 'athlete_card_fitri_validation',
                },
            };
        default:
            return {
                type: 'string',
                required: true,
                allowEmpty: false,
            };
        }
    }

    addCustomFields(race) {
        if (!race.customFields) {
            return;
        }

        race.customFields.forEach((f) => {
            let customMessage = f.label;

            const customType = f.customType || f.custom_type;
            if (customType === 'check_box') {
                this.schema.properties[f.name] = {
                    type: 'string',
                    required: f.required, //todo rr check
                    confirm: (v) => {
                        if (f.required) return v === 'yes';
                        return true;
                    },
                    messages: { required: customMessage, allowEmpty: customMessage, type: customMessage },
                };
            } else {
                this.schema.properties[f.name] = {
                    type: 'string',
                    required: f.required,
                    allowEmpty: !f.required,
                    messages: { required: customMessage, allowEmpty: customMessage }, //pass name of field to retrieve on error
                };
            }
        });
    }

    storeObj(t) {
        let obj = {};
        if (!this.isValid()) {
            this.result.errors.forEach((error) => {
                obj[error.property] = {
                    value: error.actual,
                    message: this.getErrorMessage(error, t),
                    attribute: error.attribute,
                    state: 'error',
                };
            });
        }
        return obj;
    }

    getErrorMessage(error, t) {
        let attribute;
        if (error.property === 'phone') {
            attribute = 'phone';
        } else if (error.property.indexOf('custom_field_') > -1 || error.property.indexOf('cf_') > -1) {
            attribute = 'custom_field';
        } else {
            attribute = error.attribute;
        }

        switch (attribute) {
        case 'phone':
            return t('form_error_phone_pattern');
        case 'pattern':
            if (error.message) {
                return t(error.message);
            }
            return t('form_error_' + error.property + '_' + error.attribute);
        case 'conform':
            return t('form_error_' + error.property + '_' + error.attribute);
        case 'custom_field':
            return `{{${error.message}}} ${t('form_error_' + error.attribute)}`;
        default:
            return t('form_error_' + error.attribute);
        }
    }
}

export default PlayerValidator;
