import React from 'react';
import { useTranslation } from 'react-i18next';

const IntlMessages = ({ id }) => {
    const { t } = useTranslation();
    return <>{t(id)}</>
}

export default IntlMessages;


export const t = (id) => id
