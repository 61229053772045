import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Error404 = ({ t }) => (
    <div className="gx-page-error-container">
        <div className="gx-page-error-content">
            <div className="gx-error-code gx-mb-4">404</div>
            <h2 className="gx-text-center">
                {t("page_not_found")}
            </h2>
            
            <p className="gx-text-center">
                <Link className="gx-btn gx-btn-primary" to="/">{t('goto_homepage')}</Link>
            </p>
        </div>
    </div>
);

export default withTranslation()(Error404);
