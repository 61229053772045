import revalidator from 'revalidator';
import BaseValidator from './BaseValidator';

let schema = {
    properties: {
        name: {
            type: 'string',
            required: true,
            allowEmpty: false
        },

    }
};

class TeamValidator extends BaseValidator {

    constructor(values) {
        super();
        this.values = values;
        this.result = { errors: [], valid: true }
    }

    validate() {
        this.result = revalidator.validate(this.values, schema);
        return this;
    }

    validateField(field) {
        if (schema.properties[field]) {
            this.result = revalidator.validate(this.values, { properties: { [field]: schema.properties[field] } });
        }
        return this;
    }


}

export default TeamValidator;