import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { SUBSCRIPTION } from 'constants/Tickets';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { date, string, url } from "util/index";
import Price from '../../../../components/Competition/Price';
import { range } from 'lodash';
import { EVENT } from '../../../../constants/App';

const SubscriptionsLeft = ({ raceTicketsLimit, t }) => {

    const leftSubscriptions = raceTicketsLimit.left > 0 ? raceTicketsLimit.left : 0;
    if (leftSubscriptions === 0) {
        return null;
    } else if (leftSubscriptions === 1) {
        return (
            <div className="limit-block">
                {t('subscription_left', { "0": 1 })}
            </div>
        )
    } else if (leftSubscriptions > 1) {
        return (
            <div className="limit-block">
                {t('subscriptions_left', { "0": leftSubscriptions })}
            </div>
        )
    }
    return null;
}

class ContestCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tickets: 0, //TODOCHANGE to zero
            teamAdded: false,
            helpTooltip: true,
            teamHelpTooltip: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ helpTooltip: false })
        }, 4000)
    }

    getSubscriptionLimit() {
        const { raceTicketsLimit } = this.props;
        if (raceTicketsLimit.limit && raceTicketsLimit.limit > 0) {
            return raceTicketsLimit.left;
        }
        return SUBSCRIPTION.MAX_TICKETS;
    }

    shouldShowLeftTickets() {
        const { competition, race, raceTicketsLimit } = this.props;
        if (!this.canSubscribeToRace(competition, race, raceTicketsLimit)) {
            return false;
        }
        return raceTicketsLimit.limit > 0 && raceTicketsLimit.left < SUBSCRIPTION.SHOULD_SHOW_LEFT_TICKETS;
    }

    renderImage(item) {
        let src = "";

        if (item.cover) {
            src = url.getS3Asset(item.cover);
        } else {
            src = require('../../../../assets/images/events-platform.jpg');
        }

        return (
            <img src={src} alt={item.name} />
        )
    }

    onAddTicket(e, quantity = 1) {
        e && e.preventDefault();
        const { race } = this.props;        

        const subscriptionLimit = this.getSubscriptionLimit();
        if (this.state.tickets === subscriptionLimit) return;
        const tickets = this.state.tickets < subscriptionLimit ? this.state.tickets + quantity : subscriptionLimit;
        this.setState({ tickets: tickets, helpTooltip: false }, () => {
            this.props.listener({
                tickets: tickets,
                action: 'add',
                data: range(0, quantity).map(() => ({
                    id: string.randomString(15),
                    price: race.price.price,
                    fee: !race.price.feeToOrg ? race.price.ksFee : 0,
                    feeToOrg: race.price.feeToOrg,
                    total: race.price.total,
                    raceId: race.id,
                    title: race.name,
                    quantity: 1,
                    additionsTotal: 0,
                    additions: {}
                }))
            })
        });
    }

    onRemoveTicket(e, quantity = 1) {
        e.preventDefault();
        const { race } = this.props;
        if (this.state.tickets === 0) return;
        const tickets = this.state.tickets > 0 ? this.state.tickets - quantity : 0;
        this.setState({ tickets: tickets }, () => {
            this.props.listener({
                tickets: tickets,
                action: 'remove',
                data: {
                    raceId: race.id,
                    quantity: quantity
                }
            })
        });

    }

    onToggleTeamTickets(e) {
        const isClicked = !this.state.teamAdded;
        const { race } = this.props;
        this.setState({
            teamAdded: isClicked,
            teamHelpTooltip: true
        }, () => {
            isClicked ? this.onAddTicket(e, race.teamPlayers) : this.onRemoveTicket(e, race.teamPlayers)
        });

        setTimeout(() => {
            this.setState({ teamHelpTooltip: false })
        }, 4000)
    }


    canSubscribeToRace(competition, race, limits) {
        //can subscribe and no limit or limit setted and left tickets gt zero
        const hasLeftSub = !limits || limits.limit <= 0 || limits.left > 0
        return race.state.canSubscribe && competition.subscriptionState.subscriptionLeft !== 0 && hasLeftSub
    }

    renderBuyButtons(tickets, limits, competition, race, index, t) {

        if (this.canSubscribeToRace(competition, race, limits)) {
            return (
                <div className="list-card-actions" style={{ fontSize: 24, textAlign: 'right' }}>
                    <a href="#" onClick={this.onRemoveTicket.bind(this)} style={{ margin: "0 10px" }}>
                        <MinusCircleOutlined />
                    </a>

                    <span>{tickets}</span>

                    <a href="#" onClick={this.onAddTicket.bind(this)} style={{ margin: "0 10px" }}>
                        {index === 0 ? <Tooltip visible={this.state.helpTooltip} placement="left" title={t('click_to_add_tickets')}><PlusCircleOutlined /></Tooltip> : <PlusCircleOutlined />}
                    </a>
                </div>
            )
        } else {
            return (
                <div className="list-card-actions">
                    <div style={{ width: 140 }} className="info"><span>{t('subscription_closed')}</span></div>
                </div>
            );
        }
    }



    renderTeamBuyButton(tickets, limits, competition, race, index, t) {
        const { teamHelpTooltip, teamAdded } = this.state;

        const competitionType = competition.type;

        const teamTitleKey = competitionType === EVENT.TYPE.TRAVEL ? 'group' : 'team';

        if (this.canSubscribeToRace(competition, race, limits)) {
            return (
                <div className="list-card-actions" style={{ fontSize: 24, textAlign: 'right' }}>
                    <Tooltip visible={teamHelpTooltip}
                        title={teamAdded ? t(`${teamTitleKey}_subscribed`, { value: race.teamPlayers }) : t(`${teamTitleKey}_unsubscribed`, { value: race.teamPlayers })}
                        placement="left">
                        <Button type={teamAdded ? 'primary' : 'text'}
                            onClick={this.onToggleTeamTickets.bind(this)} className="team-button" style={{ width: 'inherit' }}>
                            {t(teamTitleKey)}
                        </Button>
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className="list-card-actions">
                    <div style={{ width: 140 }} className="info"><span>{t('subscription_closed')}</span></div>
                </div>
            );
        }
    }

    render() {

        const { race, competition, raceTicketsLimit, index, t } = this.props;
        const { timezone, sport } = competition;
        const { tickets } = this.state;

        return (
            <div className="row">
                <div className={`col-sm-12 gx-mt-4`} key={race.id}>
                    <div className="list-card">
                        <div className="list-card-container">
                            <div className="list-card-header">
                                <div className="list-card-image">
                                    {this.renderImage(race)}
                                </div>
                                <span className="list-card-label price">
                                    <Price price={race.price} competition={competition} eventType={competition.type} team={race.hasTeams} />
                                </span>
                            </div>
                            <div className="list-card-content">
                                <div className="_info-block" >
                                    <div className="v4 list-card-date">{date.fromTimestamp(race.timetables.raceStart, 'ddd, DD MMM YYYY', timezone)}</div>
                                    <div className="v1 list-card-title ">{race.name}</div>
                                    {race.location && (
                                        <div className="v4 list-card-location ">
                                            {race.location.state && <i className={`list-flag flag-${race.location.state.toLowerCase()}`}></i>}
                                            {string.leftOf(race.location.name, ',')}
                                        </div>
                                    )}
                                </div>
                                {this.shouldShowLeftTickets() &&
                                    <SubscriptionsLeft raceTicketsLimit={raceTicketsLimit} t={t} />
                                }
                            </div>

                        </div>
                        <div className="list-card-footer-subscription">
                            <div className="list-card-sports">
                                <div className="gx-w-50 text-ellipsis">{t(`sports:sport_${sport}`)} {race.specId ? ' - ' + t(`sports:spec_${race.specId}`) : ''}</div>
                            </div>
                            {race.hasTeams ?
                                this.renderTeamBuyButton(tickets, raceTicketsLimit, competition, race, index, t) :
                                this.renderBuyButtons(tickets, raceTicketsLimit, competition, race, index, t)
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContestCard);