/**
 * Created by ruzz on 16/02/16.
 */

let config;

console.log("Loading env", process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === 'development') {
    config = {
        environment: 'development',
        debug: true,
        apiUrl: window.location.origin + ':8085/api',
        uploadBaseUrl: window.location.origin + ':8085/uploads',
        baseUrl: window.location.origin || 'http://ruzz.keepsporting.com',
        eventBaseUrl: 'http://events.keepsporting.com',
        fallbackLanguage: 'it',
        ravenDns: ''
    }
} else if (process.env.REACT_APP_ENV === 'docker-development') {
    config = {
        environment: 'development',
        debug: true,
        apiUrl: window.location.origin + '/data/api',
        uploadBaseUrl: window.location.origin + '/data/uploads',
        baseUrl: window.location.origin || 'http://dev.keepsporting.com',
        eventBaseUrl: 'http://events.keepsporting.com',
        fallbackLanguage: 'it',
        gnCheckoutUrl: 'https://sandbox.gerencianet.com.br/v1/cdn/2ba8e5a018fedc9bd9852d443b0b4a62',
        ravenDns: ''
    }
} else if (process.env.REACT_APP_ENV === 'docker-development-react') {
    config = {
        environment: 'development',
        debug: true,
        apiUrl: process.env.REACT_APP_API_BASE_URL + '/api',
        uploadBaseUrl: process.env.REACT_APP_API_BASE_URL + '/uploads',
        baseUrl: 'http://dev.keepsporting.com',
        eventBaseUrl: 'http://events.keepsporting.com',
        fallbackLanguage: 'it',
        gnCheckoutUrl: 'https://sandbox.gerencianet.com.br/v1/cdn/2ba8e5a018fedc9bd9852d443b0b4a62',
        ravenDns: ''
    }
} else if (process.env.REACT_APP_ENV === 'stage') {
    config = {
        debug: true,
        apiUrl: 'https://stage.keepsporting.com/data/api',
        uploadBaseUrl: 'https://stage.keepsporting.com/data/uploads',        
        baseUrl: window.location.origin || 'https://stage.keepsporting.com',
        eventBaseUrl: 'http://events.keepsporting.com',
        fallbackLanguage: 'it',
        environment: 'stage'
    }
} else {
    //#migrationchange
    config = {
        debug: false,
        apiUrl: 'https://keepsporting.com/data/api',
        uploadBaseUrl: 'https://keepsporting.com/data/uploads',
        baseUrl: window.location.origin || 'https://keepsporting.com',
        eventBaseUrl: 'http://events.keepsporting.com',
        fallbackLanguage: 'it',
        environment: 'production',
        gnCheckoutUrl: 'https://api.gerencianet.com.br/v1/cdn/2ba8e5a018fedc9bd9852d443b0b4a62',
        ravenDns: 'https://2be39a924eb54111855174cf105f4042@sentry.keepsporting.com/3'
    }

}

config.googleMapsKey = "AIzaSyC3bGG07ngQmPGSssSqaOP-ZXr3FUmoaWg";

export default config;

export const CERTIFICATE_TYPES = {
    NONE: 1,
    NON_COMPETITIVE: 2,
    COMPETITIVE: 3
};

export const ADSENSE_SLOTS = {
    EVENT_FOOTER: "4127570208",
    EVENT_SIDE_BLOCK: "1617840883",
    HOME_SEARCH_FOOTER: "2339845818",
    EVENT_FOOTER_MC: "9563250785"
    //EVENT_SIDE_FEED: ""//todo
}


