import React from 'react';
import { url } from 'util/index';

const BackgroundCover = ({ competition }) => {

    let img = null;

    if (competition.cover) {        
        img = url.getS3Asset(competition.cover)
    } else {
        img = require('assets/images/page/competition.jpg');
    }

    return (
        <div className="event-cover-bg-wrapper">
            <div className="event-cover-bg-content" style={{
                backgroundImage: "linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,.2)) , url(" + img + ")"
            }}></div>
        </div>
    )
}

export default BackgroundCover;