import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getCountries } from '../../../client/search';
import SelectField from './SelectField';
import { get } from 'lodash';
import { Skeleton } from 'antd';

const SelectCountry = ({
    name,
    value = undefined,
    label,
    onSelect,
    onChange,
    className,
    t,
    i18n,
    disabled = false,
    mode = 'default',
    allowClear = true,
    required = false,
    validationState = null,
}) => {
    const [countries, setCountries] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getCountries()
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setCountries(data.results);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    if (loading) return <Skeleton active />;

    return (
        <SelectField
            mode={mode}
            showSearch
            disabled={disabled}
            className={className}
            validationState={validationState}
            label={label}
            required={required}
            name={name}
            allowClear={allowClear}
            placeholder={t('search') + '...'}
            filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            value={value}
            onSelect={onSelect}
            onChange={onChange}
            loading={loading}
            options={countries
                .sort((a, b) => {
                    const aLabel = a.label?.toLowerCase() || '';
                    const bLabel = b.label?.toLowerCase() || '';
                    return aLabel.localeCompare(bLabel);
                })
                .map((country) => ({
                    value: country.country,
                    text: get(country, `name_${i18n.language}`, country.name),
                }))}
        />
    );
};
export default withTranslation()(SelectCountry);
