import { get, last, includes } from 'lodash';
import { default as update } from 'react-addons-update';

const INIT_STATE = {
    params: {
        location: null,
        name: '',
        sport: 0,
        usertype: 4,
    },
    items: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case 'RECEIVE_USER_ITEMS':
        return update(state, {
            $merge: {
                items: action.data.results,
                paginator: action.data.paginator,
                loading: false,
            },
        });

    case 'REQUEST_USER_ITEMS':
        return update(state, {
            $merge: {
                params: action.data,
                loading: true,
            },
        });

    case 'FETCH_USER_SUCCESS': {
        const user = action.payload;

        //should not append
        if (!user.location || user.location.length === 0) return state;

        const coords = get(user, 'location.coords', {});

        const { location } = user;

        let locationSearchParams = null;

        if (location.placetype === 12 && !includes(['IT', 'BR'], location.country)) {
            locationSearchParams = {
                id: 0,
                name: '',
                coords: [coords.latitude, coords.longitude],
                country: location.country,
                placetype: location.placetype,
            };
        } else {
            locationSearchParams = {
                id: parseInt(location.parents_ids[location.parents_ids.length - 1]),
                name: last(location.parents_string.split(',')),
                coords: [coords.latitude, coords.longitude],
                country: location.country,
                placetype: location.placetype,
            };
        }
        const searchParams = {
            location: locationSearchParams,
            name: '',
            sport: 0,
        };

        return {
            ...state,
            params: searchParams,
        };
    }
    }

    return state;
};
