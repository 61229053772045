import { Card, Col } from "antd";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { url } from "util/index";
import CardDescriptions, { CardDescriptionsItem } from "../../../../components/Descriptions/CardDescription";

const styles = {};

styles.img = {
    height: 30,
    width: 30,
    margin: 5,
    float: 'left'
};

class PayerData extends Component {

    renderHeader(user, t) {
        let src;

        if (user) {
            const userImage = (user && user.social && user.photo) ? user.photo : "";
            src = userImage ? userImage : url.getProfileImage(user.id);
        } else {
            src = url.getProfileImage(null);
        }
        return (
            <div>
                <img style={styles.img} className="radius shadowdark"
                    src={src} />
                <span style={{ float: 'left', marginTop: 10 }}>{t('payer')}</span>
                <span className="clearfix"></span>
            </div>
        )
    }   

    render() {

        const { t, user, tickets } = this.props;
        const { payer } = tickets;
        return (
            <Col xs={24} >

                <Card className="gx-card" title={this.renderHeader(user, t)}>
                    <CardDescriptions column={2} className="ks-descriptions">
                        <CardDescriptionsItem label={t('first_name')} >{payer.first_name}</CardDescriptionsItem>
                        <CardDescriptionsItem label={t('last_name')} >{payer.last_name}</CardDescriptionsItem>
                        <CardDescriptionsItem column={1} label={t('email')} >{payer.email}</CardDescriptionsItem>
                    </CardDescriptions>                    
                </Card>
            </Col>
        )
    }

}

PayerData.defaultProps = {};

export default withTranslation(['common'])(PayerData)
