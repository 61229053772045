import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import EventHeader from '../Main/Header';
import TicketsOrderCompleted from './Completed';
import TicketsForm from './Form';
import HeaderStatus from './HeaderStatus';
import Selection from './Selection';
import Timer from './Timer';
import TicketsVerification from './Verification';

const TicketsPage = ({ match }) => {

    const competition = useSelector(state => state.competition.data)
    const tickets = useSelector(state => state.tickets);
    const ticketsLimit = useSelector(state => state.ticketsLimit);

    useEffect(() => {
        //tickets id può essere diverso da quello dell'url, controllare

        // const orderId = getOrderIdFromPathname(location.pathname);

        // if(!orderId){
        //     history.push(`${url.eventUrl(competition)}/subscribe`);
        // }

        // getTicket(orderId)
        //     .then(res => res.data)
        //     .then(data => {
        //         //console.log("data",data)
        //         if (data && data.step === 'completed') {
        //             history.push(`${url.eventUrl(competition)}/subscribe/order/${tickets.id}/completed`);
        //         }
        //     });

    }, [tickets.id])

    return (

        <div className="">
            <div className="gx-card gx-p-3">
                <EventHeader competition={competition} style="vertical" />
                <Row className="gx-mr-3 gx-ml-3">
                    <Col xs={24} md={24}>
                        <HeaderStatus competition={competition} />
                        <Timer
                            key={tickets.id}
                            competition={competition}
                            tickets={tickets}
                            ticketsLimit={ticketsLimit} />
                    </Col>
                </Row>

            </div>
            <div className="gx-bg-main gx-mt-3">
                <Switch>
                    <Route exact path={match.url}>
                        <Selection
                            tickets={tickets}
                            ticketsLimit={ticketsLimit}
                            competition={competition}
                        />
                    </Route>
                    <Route exact path={`${match.url}/order/:id`} render={(routeProps) => <TicketsForm {...routeProps} competition={competition} />} />
                    <Route exact path={`${match.url}/order/:id/confirm`} render={(routeProps) => <TicketsVerification {...routeProps} competition={competition} />} />
                    <Route exact path={`${match.url}/order/:id/completed`} render={(routeProps) => <TicketsOrderCompleted {...routeProps} competition={competition} />} />
                </Switch>
            </div>

        </div>
    )
}

export default TicketsPage;