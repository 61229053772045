import { find, get, includes } from 'lodash';
import moment from 'moment';

export const getGatewayAdviceByTickets = (gatewaySlug, tickets, competition) => {
    const racesId = tickets.order.map(o => o.raceId);

    let minDate = tickets.created_at ? moment(tickets.created_at, 'DD-MM-YYYY').add('days', 3) : null;

    competition.racesSummary.filter(r => includes(racesId, r.id)).forEach(fr => {
        fr.gateways.filter(g => g.slug === gatewaySlug).forEach(gateway => {
            let gatewayAdvice = moment(gateway.advice, "DD-MM-YYYY");
            if (!minDate) {
                minDate = gatewayAdvice;
            } else if (minDate.isAfter(gatewayAdvice)) {
                minDate = gatewayAdvice;
            }
        })
    });

    return minDate;
};

export function getProviders(competition) {
    return get(competition.payments, 'providers', []);
}

function getProvidersFromPayments(payments) {
    return get(payments, 'providers', []);
}

export const getProviderBySlug = (competition, slug) => {
    return getProviderBySlugFromCompetitionPayments(competition.payments, slug);
};

export const getProviderBySlugFromCompetitionPayments = (payments, slug) => {
    const providers = getProvidersFromPayments(payments);

    if (providers.length > 0) {
        return find(providers, function (provider) {
            return provider.slug === slug;
        });
    }

    return null;
};

export const getCompetitionFeesForCalculation = (competition, provider) => {
    return getCompetitionPaymentsFeesForCalculation(competition.payments, provider);
}

export const getCompetitionPaymentsFeesForCalculation = (payments, provider) => {
    //free payment for example //same as free event
    // otherwise throw error on recalcOrderTotal func
    if (!payments) return { percent: 0, fixed: 0, feeToOrg: false };

    const { ksFee } = payments;
    //free event as well
    if (!ksFee || payments.isFree === true) return { percent: 0, fixed: 0, feeToOrg: true };

    //console.log("provider.resetKsFee", provider.resetKsFee);

    let fees = {}
    if (provider && provider.resetKsFee === true) {
        fees.percent = 0;
        fees.fixed = 0;
        fees.min = 0;
        fees.feeToOrg = payments.feeToOrg;
    } else if (provider && provider.sellingPoint === true) {
        fees.percent = ksFee.spPercent;
        fees.fixed = ksFee.spFixed;
        fees.min = 0;
        fees.feeToOrg = payments.spFeeToOrg;
    } else {
        fees.percent = ksFee.percent;
        fees.fixed = ksFee.fixed
        fees.min = get(ksFee, 'min', 0);
        fees.feeToOrg = payments.feeToOrg;
    }
    return fees;
}


/**
 *
 * @param date moment instance
 * @param days
 * @param provider
 * @returns {*}
 */
function getCustomPaymentExpirationDate(date, days = 6, provider = null) {

    if (provider && provider.expiration_span >= 0) {
        days = parseInt(provider.expiration_span / (24 * 60 * 60)); //in days
    }
    return date.subtract(days, 'days');
}

export const isExpiredBankPayment = (momentRefDate, provider = null) => {
    const now = moment();    
    return ! !now.isAfter(getCustomPaymentExpirationDate(momentRefDate, 6, provider));
};

export const isExpiredBoletoPayment = (momentRefDate, provider = null) => {
    const now = moment();
    return ! !now.isAfter(getCustomPaymentExpirationDate(momentRefDate, 6, provider));
};


export const competitionCountry = (competition) => {
    const location = competition.location;

    if (location.state) {
        return location.state.toLowerCase();
    }
    return null;
};

export const areSubscriptionsOpen = competition => {
    return get(competition, 'subscriptionState.hasOpenRace') && get(competition, 'subscriptionState.subscriptionLeft') !== 0;
}

export const guessPhonePrefixByCountry = (competition) => {
    const country = competitionCountry(competition);
    if (!country) return "";
    switch (country) {
    case 'it':
        return 39;
    case 'br':
        return 55;
    case 'ar':
        return 54;
    case 'uy':
        return 598;
    case 'pt':
        return 351;
    }
    return "";
};