import React from 'react'
import './styles/CompetitionFriend.css'
import IntlMessages from '../../../util/IntlMessages';
import { Link } from 'react-router-dom';

const SocialUsers = ({ competition }) =>  {
    let org = competition.organization;
    return (                        
        <div className={'cp-info'}>
            <hr/>
            <a href={`/profile/${org.ksId}/${org.slug}`}>{org.name}</a>
            <br/>                
            <Link target='_blank' to={`/chat?with=${org.ksId}`} className={`fbtn btn ks-btn-outline subscribe-button radius light shadowTransparent`}>
                <IntlMessages id="contact" />
            </Link>
        </div>
    )    
}

export default SocialUsers;