import { Col, Divider, Select, Typography, Form } from 'antd';
import React from 'react';
import { FormatPrice } from '../../../../util/price';
import { get } from 'lodash';

const Product = ({ product, currency, value, onChange, t }) => {

    const getVariantName = (variant) => {
        return variant.attributes.map((attribute) => {
            return <span>{attribute.attribute_name}: {attribute.name}</span>;
        });
    }

    return (
        <div className='gx-mb-2'>

            <Form.Item
                labelCol={{
                    xs: { span: 24 },
                }}
                labelAlign='left'
                label={product.name}
                tooltip={product.description}

            >

                <Select
                    defaultValue={null}
                    value={value}
                    onChange={onChange}
                >
                    <Select.Option value={null} product={product} variant={null}>
                        {t('empty_value')}
                    </Select.Option>
                    {product.variants.map((variant, index) => {
                        return (
                            <Select.Option key={index} value={variant.sku} product={product} variant={variant}>
                                <div className='gx-d-flex'
                                    style={{
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}>
                                    <span>{getVariantName(variant)}</span>  <FormatPrice price={variant.price} currency={currency} />
                                </div>
                            </Select.Option>
                        );
                    })}
                </Select>

            </Form.Item>
        </div>
    );
}

const ProductFields = ({ products, competition, onChange, formValues, t }) => {

    if (!products || products.length === 0) return null;


    const productsWithVariants = products.filter(product => (product.variants || []).length > 0);

    if (productsWithVariants.length === 0) return null;

    const currency = get(competition, 'payments.currency.iso', 'EUR');

    return (
        <Col xs={24}>
            <Divider />

            <Typography.Text className='gx-pt-2 ant-card-head-title'>
                {t('products')}
            </Typography.Text>

            <div className='gx-mt-2'>
                {productsWithVariants.map((product, index) => (
                    <Product key={index} value={formValues[`product_${product.id}`]} product={product} currency={currency} onChange={onChange} t={t} />
                ))}

            </div>
        </Col>
    );
}


export default ProductFields;