import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { isAdminUser } from "../../util/user";
import { RocketOutlined } from '@ant-design/icons';

const HorizontalNav = ({ user }) => {

    const pathname = useSelector(({ settings }) => settings.pathname);

    const { t } = useTranslation();

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <Menu
            className="gx-pt-2"
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            mode="horizontal">
            <Menu.Item key="events">
                <Link to="/events">
                    <span>{t("contests")}</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/organizers">
                    <span>{t("organizators")}</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/rankings">
                    <span>{t("rankings")}</span>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/photos">
                    <span>{t("photos")}</span>
                </Link>
            </Menu.Item>
            {isAdminUser(user) &&
                <Menu.Item>
                    <Link to="/admin">
                        <RocketOutlined />{t("admin")}
                    </Link>
                </Menu.Item>
            }
        </Menu>

    );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

