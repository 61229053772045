import { Skeleton } from "antd";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {

    const { loading } = props;

    if(loading === true){
        return <Skeleton active={true} />        
    }

    return (
        <Scrollbars
            {...props}
            ref={r => {
                if (props.onBindRef) {
                    props.onBindRef(r)
                }
            }}
            autoHide
            renderTrackHorizontal={props =>
                <div {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal" />
            }
        />
    )
}

CustomScrollbars.defaultProps = {
    loading: null
}

export default CustomScrollbars;
