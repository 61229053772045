
import { Button, Col, Row } from "antd";
import { updatePaymentForm } from "appRedux/actions/Tickets";
import AdSense from 'components/AdSense';
import { ADSENSE_SLOTS } from 'config';
import { map, get } from "lodash";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { url } from 'util/index';
import Assistance from "../Assistence";
import { cleanErrors, cleanPayerErrors, cleanTeamErrors, cleanPlayerErrors, deleteReservation, payerFormError, paymentFormError, teamsFormErrors, playersFormErrors, restoreTickets, setStripePaymentMethodSuccess, updatePayerInitState } from "../../../../appRedux/actions";
import { formDataDecode } from "../../../../util/form";
import IntlMessages from "../../../../util/IntlMessages";
import { getProviderBySlug } from "../../../../util/payments";
import InformationBox from "../../Main/InformationBox";
import OrderSummary from "./OrderSummary";
import PayerForm from "./PayerForm";
import PaymentWrapper from "./PaymentWrapper";
import RaceTicket from "./RaceTicket";
import TicketsOrderFormErrors from "./TicketsOrderFormErrors";
import UserAlert from "./UserAlert";
import { validateGerencianetCard } from "./validators/GerencianetCardPaymentValidator";
import PayerValidator from "./validators/PayerValidator";
import PaymentValidator, { fieldKeyToTransKey } from "./validators/PaymentValidator";
import PlayerValidator from "./validators/PlayerValidator";
import { validateStripeElements } from "./validators/StripePaymentValidator";
import CircularProgress from "../../../../components/CircularProgress";
import { eventUrl } from "../../../../util/url";
import { isLoggedUser } from "../../../../util/user";
import TeamsForm from "./TeamsForm";
import TeamValidator from "./validators/TeamValidator";
import { hasTeams } from "../../../../util/Tickets";

const styles = {};

styles.loader = {
    lines: 10,
    length: 15,
    width: 10,
    radius: 15,
    corners: 1,
    rotate: 0,
    direction: 1,
    color: '#205173',
    speed: 1,
    trail: 50,
    shadow: false,
    hwaccel: false,
    zIndex: 2e9,
    top: "0",
    left: '50%',
    scale: 0.60
};

class TicketsOrderForm extends Component {

    constructor(props) {
        super(props);
        this.stripeInstance = null
        this.state = {
            loading: false,
            errors: [],
            stripeCard: null
        };
    }

    setUpStripeInstance() {
        const { competition, i18n } = this.props;

        const provider = getProviderBySlug(competition.payments, 'stripe');

        if (window.Stripe && provider) {
            this.stripeInstance = window.Stripe(provider.publicKey, { locale: i18n.language });
            const elements = this.stripeInstance.elements();
            const card = [
                elements.create('cardNumber', {
                    placeholder: '**** **** **** ****'
                }),
                elements.create('cardExpiry', {
                    placeholder: 'MM / YY'
                }),
                elements.create('cardCvc', {
                    placeholder: 'CVC'
                }),
            ]
            this.setState({ stripeCard: card });
        }
    }

    componentDidMount() {

        const { location, user, tickets, t } = this.props;

        const { search } = location;

        const query = formDataDecode(search);

        if (parseInt(query.restore) === 1) {
            this.props.restoreTickets(tickets.id);
        }

        if (parseInt(query.success) === 0) {

            const message = query.message ? t(query.message) : t('generic_error')

            this.setState({
                errors: [{ form: "payment", field: "", message: message, type: "serverError" }]
            })

        }

        if (user && (!tickets.payer || !tickets.payer.email)) {
            this.props.updatePayerInitState(user)
        }

        this.setUpStripeInstance()
    }

    componentDidUpdate(prevProps) {

        //se c'è un cambio di provider
        if (!!get(prevProps, 'tickets.payment.active') && get(prevProps, 'tickets.payment.active') !== get(this.props.tickets, 'payment.active')) {
            const { search } = this.props.location;

            const query = formDataDecode(search);
            // e non è quello segnato nella query string (provider iniziale)
            // da cosiderare che c'è un passaggio di stato per il cambio del provider se quello in qs non è il primo in priorità
            // ad esempio null => stripe => paypal
            if (get(this.props.tickets, 'payment.active') !== query.provider) {
                this.setState({ errors: [] });
            }

        }

        //se il server rileva errori e non sono segnati nello stato 
        if (this.props.tickets.serverErrors && (!this.state.errors || this.state.errors.length === 0)) {
            //cercali tra nei ticket
            let errors = this.errorsFromTickets(this.props.tickets);
            if (errors && errors.length > 0) {
                //se li trovi aggiungili allo stato      
                this.setState({ errors: errors, loading: false });
            }
            //esci
        }
    }

    validatePayer(tickets, t) {
        const pv = new PayerValidator(tickets.payer, this.props.user);

        const result = pv.validate();

        if (!result.isValid()) {
            //this.errors = this.errors.concat(result.toArray('payer', t));
            this.props.payerFormError(result.storeObj(t));
        } else {
            this.props.cleanPayerErrors();
        }
    }

    validateTeams(tickets, competition, t) {
        let teamErrors = {};

        if (!hasTeams(tickets, competition)) return true;

        map(tickets.teams, (team) => {

            const tv = new TeamValidator(team);

            const result = tv.validate();

            if (!result.isValid()) {
                teamErrors[team.raceId] = result.storeObj(t);
            } else {
                this.props.cleanTeamErrors();
            }
        });

        this.props.teamsFormErrors(teamErrors);
    }

    validatePlayers(tickets, competition, t, language, callback) {
        let playerErrors = {};

        map(tickets.players, (pl, key) => {

            const playerValidator = new PlayerValidator(pl);
            const race = competition.racesSummary.find(r => r.id === pl.raceId);

            playerValidator.addCustomFields(race, language);
            playerValidator.addStandardFields(race);

            const playerValidatorResult = playerValidator.validate();

            if (!playerValidatorResult.isValid()) {

                playerErrors[key] = playerValidatorResult.storeObj(t);

            } else {
                this.props.cleanPlayerErrors(key);
            }
        });

        //going sync
        this.props.playersFormErrors(playerErrors).then(callback);
    }

    validatePayment(tickets, callback) {
        const { paymentFormError, updatePaymentForm, setStripePaymentMethodSuccess, t } = this.props;
        const { payment } = tickets;
        //validate payment
        const paymentValidator = new PaymentValidator(payment);

        const paymentValidatorResult = paymentValidator.validate();

        if (!paymentValidatorResult.isValid()) {
            //this.errors = this.errors.concat(paymentValidatorResult.toArray('payer', t));
            paymentFormError(paymentValidatorResult.storeObj(t)).then(callback)

        } else {
            //isValid
            if ('card-gerencianet' === payment.active) {
                validateGerencianetCard(tickets, t, (error, token) => {
                    if (error) {
                        paymentFormError(error).then(callback);
                    } else {
                        updatePaymentForm({
                            value: token, field: "token", valid: true
                        });
                        callback();
                    }

                })
            } else if (payment.active === 'stripe') {

                validateStripeElements(tickets, this.stripeInstance, this.state.stripeCard, t.language, (error, stripeData) => {

                    if (error) {
                        paymentFormError(error).then(callback);
                    } else {
                        setStripePaymentMethodSuccess({
                            card: {
                                token: stripeData.id,
                                card_holder: stripeData.billing_details.name,
                                number: stripeData.card.last4,
                                brand: stripeData.card.brand,
                                exp_month: stripeData.card.exp_month,
                                exp_year: stripeData.card.exp_year
                            }
                        });
                        callback();
                    }
                });
            } else {
                callback();
            }
        }

    }

    /**
     * create local state errors
     * triggered on submit
     * @param tickets
     * @returns {Array}
     */
    errorsFromTickets(tickets) {
        let errors = [];

        map(tickets.payer.errors, (err, k) => {
            if (err.state === 'error') {
                errors.push({ form: 'payer', field: k, message: err.message, type: err.attribute });
            }
        });

        let teamCount = 1;
        map(tickets.teams, (form) => {
            map(form.errors, (err, errorKey) => {
                if (err.state === 'error') {
                    errors.push({ form: 'team_' + teamCount, field: errorKey, message: err.message, type: err.attribute })
                }
            });
            teamCount++;
        });

        let playerCount = 1;
        map(tickets.players, (form) => {
            map(form.errors, (err, errorKey) => {
                if (err.state === 'error') {
                    errors.push({ form: 'ticket_' + playerCount, field: errorKey, message: err.message, type: err.attribute })
                }
            });
            playerCount++;
        });

        map(tickets.payment.errors, (err, k) => {
            if (err.state === 'error') {
                errors.push({ form: 'payment', field: fieldKeyToTransKey(k), message: err.message, type: err.attribute });
            }
        });

        return errors;
    }

    /**
     * validation is completed with
     */
    onValidationCompleted() {

        const { tickets, history, competition } = this.props;


        const errors = this.errorsFromTickets(tickets);

        //retry on server errors
        if (errors.filter(e => e.type !== 'serverError').length > 0) {

            this.setState({ errors: errors, loading: false });
        } else {
            this.props.cleanErrors();
            history.push(`${url.eventUrl(competition)}/subscribe/order/${tickets.id}/confirm`);
        }
    }

    /**
     * continue to next step
     */
    onSubmitOrder() {

        const { tickets, competition, t, i18n } = this.props;

        this.setState({ loading: true, errors: [] });
        this.errors = [];

        this.validatePayer(tickets, t);

        this.validateTeams(tickets, competition, t);

        this.validatePlayers(tickets, competition, t, i18n.language, () => {

            this.validatePayment(tickets, this.onValidationCompleted.bind(this));

        });

    }

    render() {
        const { user, tickets, competition, t, countryIp } = this.props;

        const { loading, errors } = this.state;

        if (tickets.fetching) return null;

        if (!tickets.order) {
            return <Redirect to={eventUrl(competition) + '/subscribe'} />
        }

        return (
            <Col xs={24} className="gx-p-0 gx-p-md-3">
                <Row>
                    <Col xs={24} md={16} className="gx-pt-0 gx-pl-0 gx-pr-md-3 gx-pl-md-3">
                        {!isLoggedUser(user) && <UserAlert />}
                        <PayerForm user={user} competition={competition} countryIp={countryIp} />

                        <TeamsForm user={user} competition={competition} tickets={tickets} t={t} />

                        {tickets.order.map((order, index) => {
                            let race = competition.racesSummary.find(r => r.id === order.raceId);
                            return <RaceTicket
                                key={`${order.raceId}-${index}`}
                                tickets={tickets}
                                ticket={order}
                                race={race}
                                countryIp={countryIp}
                                competition={competition}
                                team={get(tickets, `teams.${race.id}`, null)}
                                index={index + 1}
                            />
                        })}

                        <Row>
                            <OrderSummary tickets={tickets} competition={competition} />
                        </Row>
                        <Row>
                            <PaymentWrapper
                                tickets={tickets}
                                competition={competition}
                                user={user}
                                countryIp={countryIp}
                                stripeCard={this.state.stripeCard}
                            />
                        </Row>
                        <hr />

                        <TicketsOrderFormErrors errors={errors} tickets={tickets} />

                        <Row className="gx-xs-pb-4">
                            <Col xs={24} md={12} >
                                <CircularProgress loading={loading} parentClassName="order-form-spinner">
                                    <Button className="ant-btn gx-btn-outline-primary gx-btn-lg btn-subscribe gx-w-100" type="submit"
                                        onClick={this.onSubmitOrder.bind(this)}>
                                        {t("continue")}
                                    </Button>
                                </CircularProgress>
                            </Col>
                            <Col xs={24} md={8} className="gx-text-center gx-mb-5 gx-mb-md-0 gx-mt-2">
                                <Link onClick={() => this.props.deleteReservation(tickets.id)} className="btn-wide" to={`${url.eventUrl(competition)}/subscribe`}>
                                    <IntlMessages id='back' />
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={8} >
                        <InformationBox competition={competition} />
                        <hr />
                        <div className="row">
                            <Assistance assistanceDealerOrganizationTextKey="assistance_buy_dealer_organization" dealerOnly={false} competition={competition} />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <AdSense
                                    label={<IntlMessages id="ads" />}
                                    client="ca-pub-2892314018985211"
                                    slot={ADSENSE_SLOTS.EVENT_SIDE_BLOCK} />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Col>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        tickets: state.tickets,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePaymentForm: params => dispatch(updatePaymentForm(params)),

        payerFormError: error => dispatch(payerFormError(error)),

        teamsFormErrors: errors => dispatch(teamsFormErrors(errors)),

        playersFormErrors: errors => dispatch(playersFormErrors(errors)),

        paymentFormError: error => dispatch(paymentFormError(error)),

        cleanErrors: () => dispatch(cleanErrors()),

        cleanPayerErrors: () => dispatch(cleanPayerErrors()),

        cleanTeamErrors: () => dispatch(cleanTeamErrors()),

        cleanPlayerErrors: key => dispatch(cleanPlayerErrors(key)),

        restoreTickets: id => dispatch(restoreTickets(id)),

        deleteReservation: id => dispatch(deleteReservation(id)),

        setStripePaymentMethodSuccess: params => dispatch(setStripePaymentMethodSuccess(params)),

        updatePayerInitState: user => {
            if (user) {
                return dispatch(updatePayerInitState(user))
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TicketsOrderForm)))
