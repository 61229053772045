import antdSA from "antd/lib/locale-provider/de_DE";

const saLang = {
    messages: {
    //...saMessages
    },
    antd: antdSA,
    locale: 'de',
};
export default saLang;
