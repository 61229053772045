import React from 'react';
import { Empty as AntdEmpty } from 'antd';
import { useTranslation } from 'react-i18next';


const Empty = ({ ...rest }) => {

    const { t } = useTranslation();

    if (!rest.description) {
        rest.description = t('no_information')
    }

    return <AntdEmpty {...rest} />
}

export default Empty;