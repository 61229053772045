
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Popover } from 'antd';
import { keys } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Price from '../../../../components/Competition/Price';


const ExtraItemContent = ({ addKeys, competition, ticket }) => {
    return (
        <>
            {addKeys.map(addKey => {
                const add = ticket.additions[addKey];
                return (
                    <div key={`${add.value}_${add.field_value}`}>
                        {add.label} | <span className={`${add.value >= 0 ? 'has-error' : 'has-success'}`}>{<Price price={{ price: add.value }} competition={competition} />}</span>
                    </div>
                )
            })}
        </>
    )
}

class OrderSummaryContestColumn extends Component {

    constructor(props) {
        super(props);
        this.state = { expand: false };
    }

    render() {
        const { ticket, competition } = this.props;
        const { expand } = this.state;
        const addKeys = keys(ticket.additions);

        if (addKeys && addKeys.length > 0) {

            return (                
                <Popover
                    onVisibleChange={visible => this.setState({ expand: visible })}
                    content={<ExtraItemContent competition={competition} ticket={ticket} addKeys={addKeys} />}
                    trigger={'click'}>
                    {ticket.title} <span className="gx-text-primary">{expand ? <MinusOutlined /> : <PlusOutlined />}</span>
                </Popover>
            )

        } else {
            return <span>{ticket.title}</span>
        }        
    }
}


class OrderSummary extends Component {

    constructor(props) {
        super(props);

        this.total = 0;

        this.state = {
            expandPanel: true
        }
    }

    onExpandPanel(e) {
        e.preventDefault();
        this.setState({ expandPanel: !this.state.expandPanel });
    }

    renderHeader(t) {
        return (
            <span style={{ cursor: 'pointer' }} onClick={this.onExpandPanel.bind(this)}>
                {t('order_summary')}
            </span>
        )
    }

    /**
     * set fee to org to zero if fee to org
     * @param ticket
     * @param currency
     * @returns {*}
     */
    getFee(ticket, competition) {        
        //console.log('OrderSummaryContestColumn@getFee', ticket);
        const fee = !ticket.feeToOrg ? ticket.fee : 0;
        return <Price price={{ price: fee }} competition={competition} />
    }

    renderBody(tickets, competition, t) {

        let total = 0;

        const elem = tickets.order.map((ticket, i) => {

            //let title = ticket.title;
            let price = ticket.price;

            if (ticket.subtotal) {
                //ticket review
                price = ticket.subtotal;
            } else {
                //live order
                price += ticket.additionsTotal ? parseInt(ticket.additionsTotal) : 0;
            }

            let subtotal = ticket.total;
            //cart total
            total += subtotal;

            return (
                <tr key={'t_' + i}>
                    <td scope="row"><OrderSummaryContestColumn ticket={ticket} competition={competition} t={t} /></td>
                    <td style={{ minWidth: 80 }}><Price price={{ price: price }} competition={competition} /></td>
                    <td>{this.getFee(ticket, competition)}</td>
                    <td>{ticket.quantity}</td>
                    <td><Price price={{ price: subtotal }} competition={competition} /></td>
                </tr>
            )
        });

        return { total: total, body: elem }
    }

    renderDiscount(discount, total, competition) {
        if (discount && discount.valid) {

            const discountValue = Math.round(total * discount.percent / 10000) + discount.fixed;

            return {
                discountValue: discountValue,
                discountRows: [
                    <tr key="d-price" className="hidden-xs">
                        <td colSpan={3}></td>
                        <td className="text-right">{`${this.props.t("discount")}:`}</td>
                        <td><Price price={{ price: discountValue }} competition={competition} /></td>
                    </tr>,
                    <tr key="d-proce-1" className="visible-xs">
                        <td>{`${this.props.t("discount")}:`} <Price price={{ price: discountValue }} competition={competition} /></td>
                        <td colSpan={4}></td>
                    </tr>
                ]
            }
        }

        return { discountValue: 0, discountRows: null }
    }

    render() {

        const { t, tickets, competition } = this.props;


        if (tickets.payment.migrated === true) {
            //do not display if old payment migrated
            return <div></div>
        }

        const { discount } = tickets;

        const { total, body } = this.renderBody(tickets, competition, t);

        const { discountValue, discountRows } = this.renderDiscount(discount, total, competition);

        return (
            <Col xs={24} >
                <Card className="gx-card" title={this.renderHeader(t)}>
                    <div className="gx-w-100 gx-table-responsive" style={{ border: "none", overflowX: 'auto' }}>
                        <table className={"gx-table gx-table-no-border ant-table "}>
                            <thead>
                                <tr>
                                    <th>{t('contest')}</th>
                                    <th>{t('price')}</th>
                                    <th>{t('fees')}</th>
                                    <th>{t('quantity')}</th>
                                    <th>{t('subtotal')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {body}
                            </tbody>
                            <tfoot>
                                {discountRows}
                                <tr className="hidden-xs">
                                    <td colSpan={3}></td>
                                    <td className="text-right">{`${t("total")}:`}</td>
                                    <td><Price price={{ price: total - discountValue }} competition={competition} /></td>
                                </tr>
                                <tr className="visible-xs">
                                    <td>{`${t("total")}:`} <Price price={{ price: total - discountValue }} competition={competition} /></td>
                                    <td colSpan={4}></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </Card>
            </Col>
        )

    }

}

export default withTranslation(['common', 'payment'])(OrderSummary)
