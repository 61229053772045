import { Alert, List } from "antd";
import { truncate } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

const FormError = ({ error, t }) => {    

    const formText = (form, t) => {
        if (form.indexOf('_') > - 1) {
            const [a, b] = form.split('_');
            return t(a) + ' ' + b;
        }
        return t(form)
    }

    /**
     * field name translated
     * @param field
     * @param t
     * @returns {*}
     */
    const fieldText = (field, t) => {
        if (field.indexOf('custom_field_') > - 1 || field.indexOf('cf_') > - 1) {
            //do not use with custom field
            return "";
        }
        return t(field) + ':';
    }

    const customFieldMessageText = (message, m) => {
        //custom field
        const MAX_CUSTOM_FIELD_TEXT_LENGTH = 40;
        if (m[0] && m[0].length > MAX_CUSTOM_FIELD_TEXT_LENGTH) {
            message = message.replace(m[0], truncate(m[0], { length: MAX_CUSTOM_FIELD_TEXT_LENGTH }));
        }
        return message.replace("{{", "").replace("}}", ":").trim();
    }


    const messageText = (message = "", t) => {

        const m = message.match(/^({{.*}})/);

        if (m) {
            return customFieldMessageText(message, m);
        }
        //translate only keys (without spaces)
        if (message.indexOf(' ') === -1) {
            return t(message);
        }

        return message;
    }

    let message = `${formText(error.form, t)} ${fieldText(error.field, t)} ${messageText(error.message, t)}`;
    //sub link
    let links = message.match(/<a[^>]+href="(.*?)"[^>]*>(.*)?<\/a>/);

    if (links && links.length === 3) {
        message = <>{message.replace(links[0], '')} <br /> <div><a target="_blank" rel="noreferrer" href={links[1]}>{links[2]}</a></div></>
    }

    return <List.Item ><div>{message}</div></List.Item>

}

const AlertContent = ({ errors, t }) => {

    return (
        <List size="small" className="gx-list" dataSource={errors} renderItem={(item) => <FormError error={item} t={t} />} />
    )
}


class TicketsOrderFormErrors extends Component {

    constructor(props) {
        super(props);
        this.state = { show: props.errors.length > 0 }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors.length !== this.props.errors.length) {
            this.setState({ show: nextProps.errors.length > 0 });
        }
    }

    render() {
        const { show } = this.state;
        const { errors, t } = this.props;

        console.log('TicketsOrderFormErrors', errors);

        return (
            <>
                {show &&
                    <Alert
                        className="gx-alert gx-mb-3"
                        showIcon
                        message={t('form_errors')}
                        description={<AlertContent errors={errors} t={t} />}
                        type="error"
                        closable
                        afterClose={() => this.setState({ show: false })} />
                }
            </>
        )
    }
}

TicketsOrderFormErrors.defaultProps = {
    errors: []
}

export default withTranslation(['common'])(TicketsOrderFormErrors)
