import { Carousel } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useWindowSize from '../../../hooks/useWindowSize';

const HomeSlider = () => {

    const size = useWindowSize();    

    let images = [];

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 6000,
    //     arrows: false,
    //     adaptiveHeight: false
    // };


    if (!size.width) {
        //todo better handling
        return null;
    }

    if (size.width <= 767) {
        images = [
            { src: require('../../../assets/images/homepage/slide-3mr.jpg') },
            { src: require('../../../assets/images/homepage/slide-5mr.jpg') },
            { src: require('../../../assets/images/homepage/slide-1mr.jpg') },
            { src: require('../../../assets/images/homepage/slide-2mr.jpg') }
        ];
    } else {
        images = [
            { src: require('../../../assets/images/homepage/slide-5r.jpg') },
            { src: require('../../../assets/images/homepage/slide-1r.jpg') },
            { src: require('../../../assets/images/homepage/slide-2r.jpg') },
        ];
    }


    return (

        <Carousel dotPosition={'right'} autoplay={true} className="gx-slick-slider slider-container home-slider" afterChange={() => { }}>
            {images.map((image, i) => {
                return (
                    
                    <div className="slider-image-container" key={`sl_${i}`}>
                        <div className="slider-image"
                            style={{ backgroundImage: `url('${image.src}')`, backgroundSize: 'cover', backgroundPosition: "center" }}>
                        </div>
                    </div>
                    
                )
            })}
        </Carousel>

    )
}

export default withRouter(HomeSlider);