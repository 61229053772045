import React, { useEffect } from 'react'
import { Card, Button, Input, Divider, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setRegisteringUser } from '../../appRedux/actions/User';
import { replace, push } from 'react-router-redux';
import { userStatusByRuid, completeOrganizationRegistration } from '../../client/auth';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Form } from 'antd';
import { layout, tailLayout } from '../../components/Form/formLayout';
import SelectSport from '../../components/Form/Legacy/SelectSport';
import SelectLocation from '../../components/Form/Legacy/SelectLocation';
import FormServerErrors from '../../components/Form/FormServerErrors';
import { USER } from '../../constants/App';
import { showFormErrors } from '../../appRedux/actions';

const FormItem = Form.Item;


const CreateOrganization = ({ i18n, match, t }) => {

    const user = useSelector(({ user }) => user);

    const dispatch = useDispatch();

    useEffect(() => {

        if (user.id) return;

        const ruid = match.params.id;

        userStatusByRuid(ruid)
            .then(res => res.data)
            .then(data => {
                dispatch(setRegisteringUser(data))
            })
            .catch(err => {
                const errors = get(err, 'response.data.errors', null);
                if (errors && errors.code && errors.code.length > 0) {
                    if (errors.code[0] == 1) {
                        dispatch(replace('/login'));
                    }
                } else {
                    dispatch(replace('/'));
                }
            })
    }, [dispatch, user])

    const handleSubmitError = (error) => {
        console.log(error);
    }

    const handleSubmitSuccess = (values) => {

        completeOrganizationRegistration({
            'created_by': user.id,
            'organization': values.name,
            'first_name': values.first_name,
            'last_name': values.last_name,
            'sports': [values.sport],
            'location_id': values.location.value,
            'type': USER.TYPE.ORGANIZATION,
            'language': i18n.language
        })
            .then(res => res.data)
            .then(() => {
                dispatch(push(`/register/${match.params.id}/verify`));
            })
            .catch(error => {
                dispatch(showFormErrors(error.response))
            })
    }

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <FormServerErrors className="gx-mb-2" />
                <Card title={t('create_organization')}>
                    <Form
                        fields={[
                            { name: 'email', value: user.username },
                            { name: 'first_name', value: user.first_name },
                            { name: 'last_name', value: user.last_name },
                            { name: 'sport', value: user.sport }
                        ]}
                        {...layout}
                        onFinish={handleSubmitSuccess}
                        onFinishFailed={handleSubmitError}
                        className="gx-signup-form gx-form-row0">

                        <Row>
                            <Col xs={{ offset: layout.labelCol.span, span: layout.wrapperCol.span }} >
                                <h4>{t('general_organization_data')}</h4>
                            </Col>
                        </Row>

                        <FormItem
                            label={t('organization_name')}
                            name="name"
                            rules={[
                                { required: true, message: t('form_error_required') },
                            ]}
                        >
                            <Input placeholder={t('name')} />
                        </FormItem>

                        <FormItem
                            label={t('email')}
                            name="email"
                            rules={[
                                { type: 'email', message: t('validator_email') }
                            ]}
                        >
                            <Input placeholder={t('email')} />
                        </FormItem>

                        <FormItem
                            label={t('main_sport')}
                            name="sport"
                            rules={[{ required: true, message: t('form_error_required') }]}
                            trigger={"onChange"}
                        >
                            <SelectSport allSports={false} />
                        </FormItem>

                        <FormItem
                            label={t('headquarters')}
                            name="location"
                            trigger={'onChange'}
                            rules={[{ required: true, message: t('form_error_required') }]}
                        >
                            <SelectLocation countryIp={get(user, 'location.country', null)} placeholder={t('search')} hideLabel={true} />
                        </FormItem>

                        <Divider />

                        <Row>
                            <Col xs={{ offset: layout.labelCol.span, span: layout.wrapperCol.span }} >
                                <h4 className="">{t('accountable_data')}</h4>
                            </Col>
                        </Row>

                        <FormItem
                            label={t('first_name')}
                            name="first_name"
                            rules={[
                                { required: false, message: 'This field is required!' },
                            ]}
                        >
                            <Input placeholder={t('first_name')} />
                        </FormItem>
                        <FormItem
                            label={t('last_name')}
                            name="last_name"
                            rules={[
                                { required: false, message: 'This field is required!' }
                            ]}
                        >
                            <Input placeholder={t('last_name')} />
                        </FormItem>


                        <FormItem  {...tailLayout}>
                            <Button type="primary" className="gx-mb-0" htmlType="submit">
                                {t('complete')}
                            </Button>
                        </FormItem>
                    </Form>

                </Card>
            </div>
        </div>
    )
}

export default withTranslation(['common', 'morph'])(CreateOrganization);