import React, { useState } from "react";
import OrganizationItem from "./OrganizationItem";
import CustomScrollbars from 'util/CustomScrollbars'
import { Link } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { getMorphOrganization } from "../../client/user";
import { message } from "antd";
import { setMorphUser } from "../../appRedux/actions";
import { setMorphUserId } from 'util/persistence';
import onClickOutside from "react-onclickoutside";

function OrganizationsMenu({ onClickLink, onClickOutsideEvent, user, t }) {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(null);

    const morphToOrganization = (e, id) => {

        e.preventDefault()
        setLoading(true);
        getMorphOrganization(id)
            .then(r => r.data)
            .then(data => {
                setLoading(false);
                dispatch(setMorphUser(data));
                setMorphUserId(data.id);                
                onClickLink();

            })
            .catch(() => {
                message.error(t('generic_error'));
                setLoading(false);
            })
    }

    OrganizationsMenu.handleClickOutside = () => {
        onClickOutsideEvent()
    }


    return (
        <div>
            <div className="gx-popover-header">
                <h3 className="gx-mb-0">{t('organizators')} ({user.morphs.length})</h3>
                <i className="gx-icon-btn icon icon-charvlet-down" />
            </div>
            <CustomScrollbars className="gx-popover-scroll">
                <ul className="gx-sub-popover">
                    {user.morphs.map((org, index) =>
                        <OrganizationItem
                            loading={loading}                         
                            onClickMorph={morphToOrganization}
                            t={t}
                            key={index}
                            org={org} />
                    )}
                </ul>
            </CustomScrollbars>
            <div className="gx-text-center gx-mt-3 gx-mb-0">
                <Link onClick={onClickLink} className="gx-btn gx-btn-primary gx-btn" to="/settings/create-organization">
                    <PlusOutlined /> {t('create_organization')}
                </Link>
            </div>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => OrganizationsMenu.handleClickOutside
};

export default onClickOutside(OrganizationsMenu, clickOutsideConfig);



