import { Popover } from "antd";
import React, { useState } from 'react';
import UserInfo from '../../../components/User/UserInfo';
import OrganizationInfo from '../../../components/User/OrganizationInfo';
import { useTranslation } from "react-i18next";
import OrganizationsMenu from "../../../components/OrganizationsMenu";

function OrganizationsPopover({ layout = "desktop", morph, user, t }) {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Popover visible={isOpen} overlayClassName="gx-popover-horizantal" placement="bottomRight"
            content={<OrganizationsMenu
                onClickLink={() => setIsOpen(false)}
                onClickOutsideEvent={() => setIsOpen(false)}
                user={user}
                t={t}
            />}
        >
            {!morph || !morph.id &&
                <a onClick={toggle} className="gx-text-white gx-pointer gx-status-pos gx-d-block gx-text-center gx-mt-2 gx-mt-lg-0">
                    {layout === 'desktop' && (
                        <>
                            <img src={require('../../../assets/images/users/organizator.jpg')} style={{ maxWidth: 40, borderRadius: "50%" }} className="icon icon-wall gx-position-relative" />
                            <div className="gx-fs-sm gx-d-inline-block gx-d-lg-block">{t('my_organizers')}</div>
                        </>
                    )}
                    {layout === 'mobile' &&
                        <>
                            <img src={require('../../../assets/images/users/organizator.jpg')} className="icon icon-wall gx-mr-2 gx-position-relative" style={{ maxWidth: 20, borderRadius: "50%", top: 1 }} />
                            <div className="gx-fs-sm gx-d-inline-block gx-d-lg-block">{t('my_organizers')}</div>
                        </>
                    }
                </a>
            }
        </Popover>
    )
}


const PrivateHeader = ({ user, morph }) => {

    const { t } = useTranslation();

    return (
        <ul className="gx-header-notifications gx-ml-auto">

            {user.morphs && user.morphs.length > 0 &&

                <li className="gx-msg gx-d-none gx-d-lg-block">
                    <OrganizationsPopover layout={"desktop"} morph={morph} user={user} t={t} />
                </li>
            }

            <li className="gx-user-nav gx-ml-3">
                {morph && morph.id ? <OrganizationInfo morph={morph} user={user} /> : <UserInfo user={user} />}
                {user.morphs && user.morphs.length > 0 &&
                    <div className="gx-d-block gx-d-lg-none">
                        <OrganizationsPopover layout={"mobile"} morph={morph} user={user} t={t} />
                    </div>
                }
            </li>
        </ul>
    )
}

export default PrivateHeader;