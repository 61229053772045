import { PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class SelectPhone extends Component {

    constructor(props) {
        super(props);

        let prefix = '';
        let number = '';

        if (props.value) {
            let values = props.value.split('-');
            prefix = values[0];
            number = values[1];
        }

        this.state = {
            prefix: prefix,
            number: number
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.value && this.props.value != nextProps.value) {
            let values = nextProps.value.split('-');
            this.setState({ prefix: values[0] });
        }
    }

    onPhoneChange(field, e) {
        var value = e.target.value;

        this.setState({
            [field]: value
        }, () => {
            this.props.onPhoneChange(this.state.prefix + '-' + this.state.number)
        });
    }

    render() {

        let { t, validationState } = this.props;
        let { prefix, number } = this.state;

        return (
            <div name={this.props.name}>
                <Input.Group compact className={`${validationState === 'error' && 'has-error'} ${validationState === 'success' && 'has-success'}`}>
                    <div style={{ width: '30%' }} >
                        <label style={{ display: 'block' }} htmlFor="prefix" className="gx-d-block gx-text-truncate">{`${t('phone_prefix')} ${this.props.required ? '*' : ''}`}</label>
                        <PlusOutlined className="gx-d-inline-block" style={{ width: "30%" }} />
                        <Input style={{ width: "70%" }} className="gx-d-inline-block" value={prefix} type="text" onChange={this.onPhoneChange.bind(this, 'prefix')} />
                    </div>
                    <div style={{ width: '70%' }}>
                        <label htmlFor="number" className="gx-d-block">{`${t('phone_number')} ${this.props.required ? '*' : ''}`}</label>
                        <Input value={number} type="text" onChange={this.onPhoneChange.bind(this, 'number')} />
                    </div>
                    {this.props.help && <div className="help-block">{this.props.help}</div>}
                </Input.Group>

            </div>
        )
    }

}

export default withTranslation()(SelectPhone)
