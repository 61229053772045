import { Button, Col, Input, Row, Form, Alert } from 'antd';
import { processDiscount, resetDiscount } from "appRedux/actions/Tickets";
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class DiscountCode extends Component {

    constructor(props, context) {
        super(props, context);
        this.currency = props.competition.payments.currency?.symbol;
    }

    getDiscountDesc(discount) {
        var text = "";
        if (!isEmpty(discount)) {
            var { t, i18n } = this.props;

            if (discount.valid) {
                if (discount.percent > 0 && discount.fixed == 0) {
                    text = t('discount_features_percent', {
                        percent: new Intl.NumberFormat(i18n.language, { style: 'percent' }).format(discount.percent / 10000)
                    });
                } else if (discount.fixed > 0 && discount.percent == 0) {
                    text = t('discount_features_fixed', {
                        fixed: this.currency + ' ' + new Intl.NumberFormat(i18n.language).format(discount.fixed / 100)
                    });
                } else {
                    text = t('discount_features_percent_fixed', {
                        fixed: this.currency + ' ' + new Intl.NumberFormat(i18n.language).format(discount.fixed / 100),
                        percent: new Intl.NumberFormat(i18n.language, { style: 'percent' }).format(discount.percent / 10000)
                    });
                }
            } else {
                text = t('form_error_invalid_discount_code')
            }


        }
        return text;
    }   

    onClickReset(e) {
        e.preventDefault();        
        this.props.resetDiscount();        
    }

    getValidationState(discount, code) {
        if (discount.valid === true) {
            return 'success';
        } else if (discount.valid === false) {
            return 'error';
        } else if (code.validationState) {
            return code.validationState
        }
        return null
    }

    onFinish = (values) => {
        const { competition } = this.props;

        this.props.processDiscount(competition._id, values.code);        
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { tickets, t } = this.props;
        
        let { discount } = tickets;

        if (!discount) {
            discount = {};
        }

        return (
            <Row className="row-white-border ">

                <Form
                    layout={'inline'}
                    name="discount"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        placeholder={t('code')}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: t('insert_discount_code'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            {t('validate_code')}
                        </Button>
                    </Form.Item>


                </Form>

                {discount.valid !== null &&
                    <Col xs={24}>
                        <Alert onClose={this.onClickReset.bind(this)} closable={!discount.valid} type={discount.valid ? 'success' : 'error'} showIcon message={this.getDiscountDesc(discount)} />
                    </Col>
                }
                {discount.valid === true &&
                    <Col xs={24}>
                        <a href="#" onClick={this.onClickReset.bind(this)} className="gx-mt-2 gx-float-right">
                            {t("reset_code")}
                        </a>
                    </Col>
                }
            </Row>
        )
    }


}

function mapDispatchToProps(dispatch) {
    return {
        processDiscount: (compId, code) => {
            dispatch(processDiscount(compId, code))
        },
        resetDiscount: () => dispatch(resetDiscount())

    }
}

export default connect(null, mapDispatchToProps)(withTranslation()(DiscountCode))
