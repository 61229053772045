import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PublicHeader = () => {

    const { t } = useTranslation();

    return (

        <ul className="gx-header-notifications gx-ml-auto _ant-menu-submenu-title _gx-login-list">
            <li key="login">
                <Link className="gx-text-white" to="/login">{t('login')}</Link>
            </li>
            <li key="register">
                <Link className="gx-text-white" to="/register">{t('register')}</Link>
            </li>            
            {/* <li>
                <Link className="" to="/facebook-register" ><FacebookOutlined /></Link>
            </li> */}
        </ul>

    )
}

export default PublicHeader;