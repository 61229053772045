import React from "react";
import { Typography } from "antd";

const Heading = ({ child, children }) => {
    const { tag } = child;
    switch (tag) {
    case "h1":
        return <h1>{children}</h1>;
    case "h2":
        return <h2>{children}</h2>;
    case "h3":
        return <h3>{children}</h3>;
    default:
        return { children };
    }
};

const List = ({ child, children }) => {
    const { tag } = child;
    switch (tag) {
    case "ul":
        return <ul>{children}</ul>;
    case "ol":
        return (
            <ol style={{ listStyleType: "decimal", marginLeft: "1em" }}>
                {children}
            </ol>
        );
    default:
        return { children };
    }
};

const ChildrenComponent = ({ items }) => {
    return items.map((child, index) => {
        return <EditorComponent child={child} key={index} />;
    });
};

const BOLD = 1;
const ITALIC = 2;
const STRIKETHROUGH = 4;
const UNDERLINE = 8;

const formatToClassName = (format) => {
    let className = "";
    if (format & BOLD) className += " gx-font-weight-bold";
    if (format & ITALIC) className += " gx-font-italic";
    if (format & STRIKETHROUGH) className += " gx-text-strikethrough";
    if (format & UNDERLINE) className += " gx-text-underline";
    if (format === "center") className += " gx-text-center";
    if (format === "right") className += " gx-text-right";
    if (format === "left") className += " gx-text-left";
    return className;
};

const stringCssToReactClassName = (css) => {
    if (!css) return {};
    const cssArray = css.split(";");
    let className = {};
    cssArray.forEach((css) => {
        const cssKeyValue = css.split(":");
        if (cssKeyValue.length === 2) {
            className[cssKeyValue[0]] = cssKeyValue[1];
        }
    });
    return className;
};

const EditorComponent = ({ child }) => {
    const { type, children } = child;

    switch (type) {
    case "paragraph":
      
        return (
            <p className={`gx-my-3 ${formatToClassName(child.format)}`}>
                <ChildrenComponent items={children} />
            </p>
        );
    case "heading":
        return (
            <Heading child={child}>
                <ChildrenComponent items={children} />
            </Heading>
        );
    case "list":
        return (
            <List child={child}>
                <ChildrenComponent items={children} />
            </List>
        );
    case "listitem":
        return (
            <li>
                <ChildrenComponent items={children} />
            </li>
        );
    case "text":
        return (
            <span
                style={stringCssToReactClassName(child.style)}
                className={formatToClassName(child.format)}
            >
                {child.text}
            </span>
        );
    case "link":
    case "autolink":
        return (
            <a href={child.url} rel="noreferrer" target="_blank">
                <ChildrenComponent items={children} />
            </a>
        );
    case "quote":
        return (
            <Typography.Paragraph>
                <blockquote>
                    <ChildrenComponent items={children} />
                </blockquote>
            </Typography.Paragraph>
        );
    case "image":
        return (
            <img
                src={child.src}
                alt={child.altText}
                style={{
                    maxWidth: child.maxWidth || "inherit",
                }}
            />
        );
    default:
        return null;
    }
};

const LexicalEditorViewer = ({ editorState }) => {
    if (!editorState) return null;

    const { root } = editorState;

    const { children } = root;

    return (
        <div>
            {children.map((child, index) => {
                return <EditorComponent child={child} key={index} />;
            })}
        </div>
    );
};

export default LexicalEditorViewer;
