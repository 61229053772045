import { Card } from "antd";
import { includes } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class PayUData extends Component {

    constructor(props) {
        super(props);

        this.formRef = null;
    }

    getSupportedLanguage() {
        const supported = [ 'es', 'pt', 'en' ];

        return includes(supported, this.props.i18n.language) ? this.props.i18n.language : 'es';
    }

    componentDidUpdate(prevProps) {
        if (this.props.payment && this.props.payment.form && ! prevProps.payment.form) {
            this.formRef.submit();
        }
    }

    renderForm() {
        const { tickets, payment, competition } = this.props;

        const { form } = payment;
        const { payer } = tickets;

        return (
            <form ref={formRef => this.formRef = formRef} method="post" action={form.action}>
                <input name="merchantId" type="hidden" value={form.merchantId} />
                <input name="accountId" type="hidden" value={form.accountId} />
                <input name="description" type="hidden" value={form.description} />
                <input name="referenceCode" type="hidden" value={form.referenceCode} />
                <input name="amount" type="hidden" value={form.amount} />
                <input name="tax" type="hidden" value="0" />
                <input name="taxReturnBase" type="hidden" value="0" />
                <input name="currency" type="hidden" value={competition.payments.currency?.iso} />
                <input name="signature" type="hidden" value={form.signature} />
                <input name="test" type="hidden" value={form.test} />
                <input name="buyerEmail" type="hidden" value={payer.email} />
                <input name="buyerFullName" type="hidden" value={`${payer.last_name} ${payer.first_name}`} />
                <input name="responseUrl" type="hidden" value={form.responseUrl} />
                <input name="lng" type="hidden" value={this.getSupportedLanguage()} />
                <input name="confirmationUrl" type="hidden" value={form.confirmationUrl} />
                <input name="expirationDate" type="hidden" value={form.expirationDate} />
            </form>
        )
    }

    render() {

        const { payment, t } = this.props;

        const { form } = payment;

        return (
            <Card title={<span>{t('pay_with', [ payment.active ])}</span>}>
                <p>
                    {t('payu_payment_description')}
                </p>
                {! ! form && this.renderForm()}
            </Card>
        )
    }
}

export default withTranslation()(PayUData);