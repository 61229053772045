import { default as update } from "react-addons-update";

const INIT_STATE = {
    params: {
        location: null,
        name: "",
        sport: 0,        
    },
    items: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case "RECEIVE_COMPETITIONS_WITH_STANDING_ITEMS":            

        return update(state, {
            $merge: {
                items: action.data.results,
                paginator: action.data.meta?.pagination,
                loading: false
            }
        });

    case "REQUEST_COMPETITIONS_WITH_STANDING_ITEMS":
        return update(state, {
            $merge: {
                params: action.data,
                loading: true
            }
        })
       
    }

    return state;
}