
const INIT_STATE = {
    data: {},
    isFetching: true,
    lastUpdate: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case "RECEIVE_RACE":
        return {
            isFetching: false,
            data: action.data,
            lastUpdate: action.receivedAt
        }

    case "REQUEST_RACE":
        return {
            ...state,
            isFetching: true
        }                
        

    default:
        return state;
    }
}