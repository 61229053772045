import React from 'react';
import { withTranslation } from "react-i18next";
import { getTypeByPaymentSlug } from "util/payments";

class PaypalCompletedInformation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, payment } = this.props;

        const transactionId = payment.transaction ? payment.transaction.payment_id : payment.transactionId;

        if(payment.paid){
            return (
                <div>
                    <p>{t('payment_successfully_completed')} ({payment.active})</p>
                    <ul>
                        <li >{t("payment_type")}: <strong>{t('payment_type_' + getTypeByPaymentSlug(payment.active))}</strong></li>
                        <li >{t("payment_id")}: <strong>{t(transactionId)}</strong></li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div>
                    <p className="">{t('payment_generic_error')}</p>
                </div>
            )
        }
    }
}

export default withTranslation([ 'common','payment' ])(PaypalCompletedInformation);