import { useState, useEffect } from 'react';
import { values, keys } from 'lodash'

const isWindowClient = typeof window === "object";

const useBreakpointSize = (breakpoints) => {

    const getBreakPoint = (width) => {
        let index = 0
        let _values = values(breakpoints);
        _values.find((bp, i) => {
            if (!_values[i + 1]) {
                index = _values.length - 1;
                return;
            }
            index = i;
            return width >= bp && width < _values[i + 1];
        })

        return keys(breakpoints)[index]

    };

    const [windowSize, setWindowSize] = useState(
        isWindowClient
            ? getBreakPoint(window.innerWidth)
            : undefined
    );

    useEffect(() => {
        //a handler which will be called on change of the screen resize
        function setSize() {
            setWindowSize(getBreakPoint(window.innerWidth));
        }

        if (isWindowClient) {
            //register the window resize listener
            window.addEventListener("resize", setSize);

            //unregister the listerner on destroy of the hook
            return () => window.removeEventListener("resize", setSize);
        }
    }, [isWindowClient, setWindowSize]);

    return windowSize;
}

export default useBreakpointSize;