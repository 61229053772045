import { UserOutlined, SettingOutlined, CalendarOutlined, PlusOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Popover } from "antd";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { userSignOutSuccess } from '../../appRedux/actions/Auth';
import { url } from 'util/index';

const UserInfo = ({ user }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    //const lastUpdate = useSelector(({user}) => user.updated_at);

    const userMenuOptions = (
        <ul className="gx-user-popover" style={{ minWidth: 200 }}>
            <li><Link className="gx-d-block" to="/profile"><UserOutlined /> {t('profile')}</Link></li>
            <li><Link className="gx-d-block" to="/tickets"><CalendarOutlined /> {t('tickets')}</Link></li>
            <li><Link className="gx-d-block" to="/settings"><SettingOutlined /> {t('edit_profile')}</Link></li>
            <li>
                <Link className="gx-d-block" to="/settings/create-organization">
                    <PlusOutlined />
                    {t('create_organization')}
                </Link>
            </li>
            <li>
                <Link className="gx-d-block" to="/" onClick={(e) => {
                    e.preventDefault();
                    dispatch(userSignOutSuccess())
                    history.push('/')

                }}>
                    <LogoutOutlined />&nbsp;{t('logout')}
                </Link>
            </li>
        </ul>
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
            trigger="click">
            <span className="gx-d-inline-block gx-pointer">{user.first_name}</span>
            <Avatar src={url.getProfileImage(user.id, '200x200', true) || <UserOutlined />}
                className="gx-avatar gx-pointer" alt="" />
        </Popover>
    )
};


export default UserInfo;
