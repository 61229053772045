import { get as _get, isObject } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

const date = {

    fromTimestamp(t, format = 'LL', tz, printZone = true) {

        if (isObject(t)) {
            t = t.$date.$numberLong / 1000;
        }

        var m = moment(t, 'X');
        var date = m.format(format);
        if (tz) {
            var userOffset = m.utcOffset();
            var tzOffset = m.tz(tz).utcOffset();
            if (tzOffset !== userOffset) {
                date = m.tz(tz).format(format);
                if (printZone) {
                    if (tzOffset / 60 !== 0) {
                        date += ' (UTC ' + tzOffset / 60 + ')';
                    } else {
                        date += ' (UTC)';
                    }
                }

            }
        }

        return date;
    },

    fromMongoDateToUnixTimestamp(field) {
        const unixTime = _get(field, '$date.$numberLong', new Date().getTime() * 1000);
        return parseInt(unixTime / 1000);

    },

    fromMongoDateToMoment(field) {
        return moment(this.fromMongoDateToUnixTimestamp(field), 'X')
    },

    millisToFormatted(value) {

        function n(n) {
            return n > 9 ? "" + n : "0" + n;
        }

        var d = moment.duration(value);

        return n(d.hours()) + ':' + n(d.minutes()) + ':' + n(d.seconds()) + ',' + d.milliseconds() / 100
    },

    formatStringDate(date) {
        const [y, m, d] = date.split('-');
        return `${d}-${m}-${y}`;
    }

};

export default date;
