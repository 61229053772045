import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal, Pagination, Typography, Row, Col } from "antd";
import { values } from 'lodash';
import React, { Component } from 'react';
import { url } from "util/index";
import { getCompetitionGallery } from "../../../client/event";
import { FullPageLoader } from "../../../components/CircularProgress";
import IntlMessages from "../../../util/IntlMessages";
import './styles/gallery.css';

//styles.loaderOptions = componentsConfig.loader;

const PER_PAGE = 16;

const Item = ({ photo, onClick }) => {
    return (
        <li>
            <img onClick={onClick} src={url.getFSImage(photo, 'preview')} alt="" className="img-responsive" style={{ cursor: 'pointer' }} />
        </li>
    )
}

const ImageModal = ({ photo, show, title, onLeft, onRight, onHide, photo_id }) => {

    let name = "";
    if (photo) name += photo.original.name;

    let fulPath = url.getFSImage(photo, 'original');

    return (
        <Modal
            className="gx-modal"
            title={<Typography.Title level={4}  ><strong>{photo_id + 1}</strong> - {title} - {name}</Typography.Title>}
            visible={show}
            onCancel={onHide}
            footer={null}
        >
            <div style={{ overflow: 'auto' }}>
                <img src={fulPath} alt="" className="img-responsive" />
            </div>
            <div className="image-modal-controls gx-mt-3">
                <a onClick={() => { onLeft() }} className={'modal-arrow'}><LeftOutlined /></a>
                <a download={name} href={fulPath} rel="noreferrer" target={'_blank'}><IntlMessages id="download" /></a>
                <a onClick={() => { onRight(); }} className={'modal-arrow'}><RightOutlined /></a>
            </div>
        </Modal>
    )

}

ImageModal.defaultProps = {
    show: false,
    title: '',
    text: '',
    onConfirm: () => {
    },
    onHide: () => {
    }
};


class Gallery extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            images: [],
            pagination: null,
            showItemModal: false,
            ph_id: 0,
            ph: null,
            loaded: false
        };

        this.pages_loaded = [];

        this.keyUpHandler = this.keyUpHandler.bind(this);
    }


    keyUpHandler(e) {
        if (e.keyCode === 37) this.prevImage();
        else if (e.keyCode === 39) this.nextImage();
    }

    changeImage(pos) {
        let gallery = this.state.images;
        if (this.state.showItemModal === true && pos >= 0 && pos < gallery.length) {
            this.setState({
                ph: gallery[pos],
                ph_id: pos
            });
        }
    }

    prevImage() {
        let _this = this;
        const compID = this.props.competition._id;
        let pagination = this.state.pagination;

        if (this.state.images[this.state.ph_id - 1] === null) {
            this.getGallery(compID, pagination.current_page - 1, function () {
                _this.changeImage(_this.state.ph_id + 1);
            });
        } else {
            this.changeImage(this.state.ph_id - 1);
        }
    }


    nextImage() {
        let _this = this;
        const compID = this.props.competition._id;
        let pagination = this.state.pagination;

        if (this.state.images[this.state.ph_id + 1] === null) {
            this.getGallery(compID, pagination.current_page + 1, function () {
                _this.changeImage(_this.state.ph_id + 1);
            });
        } else {
            this.changeImage(this.state.ph_id + 1);
        }

    }


    getGallery(compID, page = 1) {

        if (!this.pages_loaded[page]) {

            this.setState({ loaded: false });

            getCompetitionGallery(compID, PER_PAGE, page)
                .then(res => {

                    if (!res.data || res.status !== 200) return null;

                    let data = res.data;

                    let pagination = data.meta.pagination;

                    delete data.meta;

                    this.setState({ pagination: pagination, images: values(data), loaded: true });

                });
        } else {
            this.setState({ pagination: this.pages_loaded[page] });
            this.forceUpdate();
            //if (callback) callback();
        }

    }

    renderPagination(compID) {
        let pagination = this.state.pagination;
        let pages_links = [];

        for (let i = 1; i <= pagination.total_pages; i++) {
            pages_links.push(
                <li key={i} className={(pagination.current_page === i) ? 'active' : ''}>
                    <a className="" href="#" onClick={e => {
                        e.preventDefault();
                        this.getGallery(compID, i);
                    }}>{i}</a>
                </li>);
        }

        if (pagination.total_pages > 1)
            return (
                <ul className="gallery pagination">
                    <li className={(pagination.links.previous) ? '' : 'disabled'}>
                        <a className="" onClick={e => {
                            e.preventDefault();
                            this.getGallery(compID, pagination.current_page - 1);
                        }}>
                            <span className="glyphicon glyphicon-chevron-left"></span>
                        </a>
                    </li>
                    {pages_links}
                    <li className={(pagination.links.next) ? '' : 'disabled'}>
                        <a className="" onClick={e => {
                            e.preventDefault();
                            this.getGallery(compID, pagination.current_page + 1);
                        }}>
                            <span className="glyphicon glyphicon-chevron-right"></span>
                        </a>
                    </li>
                </ul>
            );
    }

    componentDidMount() {
        const compID = this.props.competition._id;
        this.getGallery(compID);

    }

    render() {
        const compID = this.props.competition._id;

        if (!this.state.loaded) {
            return <Row><Col xs={24} style={{ minHeight: 400, width: '100%' }}><FullPageLoader /></Col></Row>
        }

        const { pagination, images, showItemModal, ph_id, ph } = this.state;

        let from = (pagination.current_page - 1) * pagination.per_page;

        if (!images || images.length === 0) return null;

        return (
            <div className="row" id="competition-gallery">
                <hr key={'gallery_hr'} />
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12 gallery-wrapper">
                            <ul className="gallery-list">
                                {images.map((photo, i) => {
                                    if (photo !== null) {
                                        return <Item
                                            key={photo._id}
                                            photo={photo}
                                            onClick={() => {
                                                this.setState({ ph: photo });
                                                this.setState({ ph_id: from + i });
                                                this.setState({ showItemModal: true });
                                            }}
                                        />
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 gx-mt-4 gx-text-center">
                            {/* {this.renderPagination(compID)} */}
                            <Pagination
                                defaultCurrent={1}
                                current={pagination.current_page}
                                pageSize={PER_PAGE}
                                onChange={page => {
                                    console.log("page", page)
                                    this.getGallery(compID, page);
                                }}
                                total={pagination.total} />
                        </div>
                    </div>

                    <ImageModal
                        show={showItemModal}
                        photo={ph}
                        photo_id={ph_id}
                        title={<IntlMessages id="gallery" />}
                        onKeyUp={this.keyUpHandler}
                        onLeft={() => {
                            this.prevImage();
                        }}
                        onRight={() => {
                            this.nextImage();
                        }}
                        onHide={() => {
                            this.setState({ showItemModal: false })
                        }}
                    />
                </div>
            </div>

        );
    }
}


export default Gallery;