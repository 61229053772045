import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from "react";


const CircularProgress = ({ children = null, loading = false, className = "", size = '' }) => {
    //if (!loading) return null;
    let fontSize = 24;
    if (size === 'large') {
        fontSize *= 2;
    }

    const antIcon = <LoadingOutlined style={{ fontSize: fontSize }} spin />

    if (children) {
        return (
            <Spin indicator={antIcon} spinning={loading} className={className} size={size}>
                {!loading ? children : null}
            </Spin>
        )
    }

    return <Spin indicator={antIcon} className={className} spinning={loading} size={size} />
}

export const FullPageLoader = ({ loading = true, style = null, spinnerClassName = '' }) => {

    return (
        <div className="gx-loader-view gx-loader-position" style={{ height: '85%', ...style }}>
            <CircularProgress loading={loading} size="large" className={spinnerClassName} />
        </div>
    )
}

export default CircularProgress;
