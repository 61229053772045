import { clone } from 'lodash';
import React from 'react';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const markerStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: - K_WIDTH / 2,
    top: - K_HEIGHT / 2,

    borderRadius: K_HEIGHT,
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4
};

const CustomMarker = ({ hover }) => {
    let styleCopy = clone(markerStyle);
    styleCopy.backgroundColor = hover ? '#567b95' : '#fff';
    return (
        <img style={styleCopy} src={require('../../assets/images/logo/keepsporting_square_120.jpg')} />
    )
};

export default CustomMarker