
import React from 'react';
import { withTranslation } from 'react-i18next';
import { getTypeByPaymentSlug } from '../../../../../util/payments';
import Bank from '../../Form/Payment/Bank';


class BankCompletedInformation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { tickets,t } = this.props;

        const { payment } = tickets;

        const { transaction } = payment;

        if( ! tickets.payment.paid ){

            return <Bank {...this.props}/>

        } else {

            return (
                <div>
                    <p>{t('payment_completed')} </p>
                    <ul>
                        <li >{t("payment_type")}: <strong>{t('payment_type_' + getTypeByPaymentSlug(payment.active))}</strong></li>
                        <li >{t("payment_id")}: <strong>{transaction ? transaction.payment_id : payment.payment_id}</strong></li>
                    </ul>
                </div>
            )
        }
    }
}

export default withTranslation([ 'common' ])(BankCompletedInformation);