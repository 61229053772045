import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
/**
 * format price with currency
 * @param price
 * @param currency
 * @returns {*}
 */
export const FormatPrice = ({ price, currency = null }) => {
    const { i18n } = useTranslation();
    try {
        if (currency) {
            return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(price / 100);
        }
        return new Intl.NumberFormat(i18n.language).format(price / 100);
    } catch (e) {
        return price / 100;
    }
};

export function getCurrency(competition) {
    return get(competition, 'payments.currency.symbol', '€');
}

export const roundNumber = (num, decimal = 2) => {
    return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};
