import { searchCompetitions } from "../../client/search";


export function requestCompetitionItems(params) {
    return {
        type: "REQUEST_COMPETITION_ITEMS",
        data: params
    }
}

function receiveCompetitionItems(response) {
    return {
        type: "RECEIVE_COMPETITION_ITEMS",
        data: response,
        receivedAt: Date.now()
    }
}

export function performCompetitionSearch(params) {

    return dispatch => {

        dispatch(requestCompetitionItems(params));

        return searchCompetitions(params)
            .then(res => res.data)
            .then(response => {
                dispatch(receiveCompetitionItems(response));
            }).catch((err, msg) => {
                console.log(err, msg)
            })
    }
}
