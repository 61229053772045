import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { fetchCurrentUser } from "../../appRedux/actions/Auth";
import { LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL, NAV_STYLE_ABOVE_HEADER, NAV_STYLE_BELOW_HEADER, NAV_STYLE_DARK_HORIZONTAL, NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK } from "../../constants/ThemeSetting";
import MainApp from "./MainApp";
import { getMorphUserId } from "../../util/persistence";
import { fetchMorphUser } from "../../appRedux/actions";
import { FullPageLoader } from "../../components/CircularProgress";


const App = (props) => {

    const { match, location } = props;

    const dispatch = useDispatch();

    //const locale = useSelector(({ settings }) => settings.locale);
    const navStyle = useSelector(({ settings }) => settings.navStyle);
    const themeType = useSelector(({ settings }) => settings.themeType);
    const layoutType = useSelector(({ settings }) => settings.layoutType);
    const authToken = useSelector(({ auth }) => auth.authToken);

    useEffect(() => {
        dispatch(fetchCurrentUser())
        if (getMorphUserId()) {
            dispatch(fetchMorphUser(getMorphUserId()))
        }
    }, [dispatch, authToken]);


    const setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    const setNavStyle = (navStyle) => {
        if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };

    if (themeType === THEME_TYPE_DARK) {
        document.body.classList.add('dark-theme');
    }

    if (location.pathname === '/') {
        return <Redirect to={'/home'} />;
    }

    setLayoutType(layoutType);

    setNavStyle(navStyle);    

    return (
        <>
            <Suspense fallback={<FullPageLoader />}>
                <Route path={`${match.url}`} component={MainApp} />
            </Suspense>
        </>
    )
};

export default App;
