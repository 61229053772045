import { get } from 'lodash';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_FORM_ERRORS, HIDE_MESSAGE, SHOW_FORM_ERRORS, SHOW_MESSAGE } from '../../constants/ActionTypes';

const INIT_STATE = {
    error: "",
    loading: false,
    message: '',
    formErrors: null
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
    case 'REQUEST_COMPETITION':
    case FETCH_START: {
        return { ...state, error: '', message: '', loading: true };
    }
    case 'RECEIVE_COMPETITION':
    case FETCH_SUCCESS: {
        return { ...state, error: '', message: '', loading: false };
    }
    case SHOW_MESSAGE: {
        return { ...state, error: '', message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
        return { ...state, loading: false, error: action.payload, message: '' };
    }
    case HIDE_MESSAGE: {
        return { ...state, loading: false, error: '', message: '' };
    }

    case SHOW_FORM_ERRORS: {
        return { ...state, loading: false, formErrors: get(action, 'payload.data.errors', []), statusCode: get(action, 'payload.status', 500) }
    }
    case HIDE_FORM_ERRORS: {
        return { ...state, loading: false, formErrors: null, statusCode: null }
    }
    default:
        return state;
    }
}
