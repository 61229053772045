import { Col } from 'antd';
import React from 'react';
import ContentButtons from '../../../components/Buttons/ContentButtons';
import { EVENT } from '../../../constants/App';
import IntlMessages from '../../../util/IntlMessages';
import RaceCard from './RaceCard';
import './styles/race_list_main.css';

const RacesList = ({ competition, t }) => {

    const { racesSummary } = competition;

    return (
        <Col className="race-card-list" xs={24} sm={24} lg={{ span: 16, offset: 4 }} >
            <h3 className="gx-text-center gx-text-uppercase">{<IntlMessages id="contests" />}</h3>
            {racesSummary.map(race => {
                return <RaceCard key={race.id} race={race} competition={competition} t={t} />
            })}
            <hr />
            {competition.type === EVENT.TYPE.COMPETITION &&
                <div className="gx-mt-4 gx-text-center">
                    <ContentButtons competition={competition} />
                </div>
            }
        </Col>

    )
}

export default RacesList