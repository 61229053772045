import { setPaymentMethod } from "appRedux/actions/Tickets";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

class FreePayment extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.dispatch(setPaymentMethod({ active:'free' }));
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ant-col ant-col-xs-24">
                <h3>{t('nothing_to_pay')}</h3>
            </div>
        )
    }

}

export default connect()(withTranslation([ 'common' ])(FreePayment));
