import React from 'react';
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isAdminUser, isLoggedUser } from '../../util/user';
import ShouldLogin from '../../routes/Extra/ShouldLogin';
import { USER } from '../../constants/App';
import Error404 from '../../routes/Extra/Error404';

const PermittedRoute = ({ canView = null, role, ...rest }) => {

    const user = useSelector(({ user }) => user);

    if(canView !== null){
        if(!canView(user)){
            return <ShouldLogin />
        }
    }

    if (!isLoggedUser(user)) {
        return <ShouldLogin />;
    }

    if (role  === USER.ROLES.ADMIN && !isAdminUser(user)) {
        return <Error404 />
    }

    return <Route {...rest} />
}

export default PermittedRoute;