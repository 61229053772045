import React from 'react';
import { Card, Row, Col } from 'antd';
import TextField from '../../../../components/Form/Legacy/TextField';
import { updateTeamsForm } from '../../../../appRedux/actions/Tickets';
import { useDispatch } from 'react-redux';
import TeamValidator from './validators/TeamValidator';
import { get, map } from 'lodash';
import { hasTeams } from '../../../../util/Tickets';

const TeamsForm = ({ tickets, competition, t }) => {

    const dispatch = useDispatch();    

    if (!hasTeams(tickets, competition)) return null;

    const onChangeName = (e, team) => {

        const isValid = new TeamValidator({ name: e.target.value }).validateField('name').isValid();

        dispatch(updateTeamsForm({
            field: 'name',
            value: e.target.value,
            valid: isValid,
            key: team.raceId
        }));
    }

    return (
        <Card title={t('teams_information')} className="gx-card">
            {map(tickets.teams, (team) => {
                return (
                    <Row>
                        <Col xs={24} sm={18} className="gx-mb-3" >
                            <TextField
                                label={`${t('team_name')} *`}
                                placeholder={t('name')}
                                name="name"
                                type="text"
                                value={team.name}
                                validationState={get(team, 'errors.name.state', null)}                                
                                onChange={e => onChangeName(e, team)}
                                help={t('choose_name_for_team', { value: team.raceName })}
                            />
                        </Col>
                    </Row>
                )
            })}
        </Card>
    )
}

export default TeamsForm;