import enLang from "./entries/en-US";
import esLang from "./entries/es_ES";
import itLang from "./entries/it_IT";
import ptLang from "./entries/pt_BR";
import deLang from "./entries/de_DE";

const AppLocale = {
    en: enLang,
    it: itLang,
    es: esLang,
    pt: ptLang,
    de: deLang,
};

export default AppLocale;
