import { Alert } from 'antd';
import CircularProgress from "components/CircularProgress/index";
import { keys } from 'lodash';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { hideFormErrors } from '../../appRedux/actions';

const ErrorsMessage = ({ errors, t }) => {

    const getKeyText = k => {
        const trans = t(k.replace('_id', ''));
        if (!trans || trans.indexOf('_') > -1) {
            return ''
        }
        return trans + ' :';
    }

    return (
        <ul className="gx-list">
            {keys(errors).map((k, index) => {
                return (
                    <li key={index}>{getKeyText(k)} {t(errors[k][0])}</li>
                )
            })}
        </ul>
    )
}

const FormServerErrors = ({ className, timeout = 6000, t }) => {

    const loading = useSelector(({ commonData }) => commonData.loading);
    let errors = useSelector(({ commonData }) => commonData.formErrors);
    const statusCode = useSelector(({ commonData }) => commonData.statusCode);

    const dispatch = useDispatch();

    if ((!errors || errors.length === 0) && statusCode >= 400) {
        switch (statusCode) {
        case 401:
            errors = { 'generic_error': ['401'] }
            break;
        case 500:
            errors = { 'generic_error': ['500'] }
            break;
        }
    }

    useEffect(() => {
        if (errors) {
            setTimeout(() => dispatch(hideFormErrors()), timeout)
            return () => {
                //do not timeout?!
                //clearTimeout(timer)
            }
        }
    }, [errors])    

    return (
        <div className={className}>
            {loading &&
                <div className="gx-loader-view gx-loader-position">
                    <CircularProgress />
                </div>
            }

            {errors && <Alert type="error" closable={false} message={<ErrorsMessage errors={errors} t={t} />} />}

        </div>
    );
};

export default withTranslation()(FormServerErrors);
