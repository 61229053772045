import { setPaymentMethod } from 'appRedux/actions/Tickets'
import CircularProgress from 'components/CircularProgress'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

class Paypal extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        const { competition } = this.props;
        this.props.dispatch(setPaymentMethod({ active: 'paypal', payments: competition.payments }));
    }
    
    render() {
        const { t } = this.props;        

        return (

            <div style={{ padding: 15 }}>
                
                <CircularProgress className="spinner">
                    {t('paypal_payment_description')}
                </CircularProgress>
            </div>
        )
    }
}


export default connect()(withTranslation(['common'])(Paypal))
