/**
 * Created by ruzz on 18/02/16.
 */
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { string, url } from 'util/index';
import IntlMessages from '../../util/IntlMessages';
import Empty from '../Widgets/Empty';

const Attachments = ({ i18n, t, attachments = [] }) => {

    return (
        <Card className="gx-card" title={<IntlMessages id="attachments" />}>
            {attachments && attachments.length > 0 ?
                attachments.map((a, index) => {
                    return (
                        <Card.Grid key={index}>
                            <a target="_blank" rel="noreferrer" href={url.getS3Asset(a)}>
                                <FontAwesomeIcon icon={faPaperclip} />&nbsp;
                                {string.getTranslation(a.label, i18n.language, t('attachment_type_' + a.category))}
                            </a>
                        </Card.Grid>
                    )
                }) : <Empty />}
        </Card>
    )
}

export default withTranslation()(Attachments)
