
import { includes } from 'lodash';

export const worldLocation = t => ({
    value: '',
    label: t('all_countries'),
    coords: '45.0,11.0',
    woeid: 0
});

export const worldLocationCountry = t => {
    return {
        id: 0,
        name: t('all_countries'),
        coords: [45, 11]
    };
};


export function parseCoords(coords) {
    if (!coords) {
        return []
    }

    const [lat, lng] = coords.split(',');

    return [parseFloat(lat), parseFloat(lng)];
}

export const locationObjToString = (obj) => {
    if (!obj || !obj.name) return '';

    let stack = [];

    let str = obj.name;
    stack.push(obj.name)

    if (obj.parents && obj.parents.length > 0) {

        obj.parents.map(p => {
            if (!includes(stack, p.name)) {
                str += ', ' + p.name
                stack.push(p.name);
            }
        });

    }
    stack = [];
    return str;
}

export const formatLocationName = (location) => {
    let str = location.name;
    
    if (location.parents) {
        if (location.parents.length > 1) {
            str += `, ${location.parents[location.parents.length - 2].name || ''}`;
        }
        if (location.parents.length > 0) {
            str += ` (${location.parents[location.parents.length - 1].name})`;
        }
    }
    
    return str;
}