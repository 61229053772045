import { get, keys, includes, isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getSafeISOLanguage } from '../../../lngProvider/i18n';
import SelectField from './SelectField';

const SelectSport = ({ name, value = undefined, label, onSelect, onChange, className, t, i18n, allSports = true, disabled = false, mode = 'default', exclude = [], allowClear = false }) => {

    const [sports, setSports] = useState([]);

    useEffect(() => {

        const safeISOLanguage = getSafeISOLanguage(i18n.language);

        let sports = get(i18n.store, 'data.' + safeISOLanguage + '.sports', {});
        let sportsArr = [];
        if (allSports) sportsArr.push({ value: 0, text: t('all_sports') });

        keys(sports)
            .filter(s => s.match(/^sport_[\d]+$/))
            .map(key => {
                sportsArr.push({ value: parseInt(key.replace('sport_', '')), text: sports[key] })
            })

        if (exclude && exclude.length > 0) {

            sportsArr = sportsArr.filter(s => {
                return !includes(exclude, s.value)
            })
            
            if (value && isArray(value)) {
                
                value = value.filter(sportId => !includes(exclude, sportId))
            }
        }

        sportsArr = sportsArr.sort((a, b) => {
            let _a = a.text.toLowerCase();
            let _b = b.text.toLowerCase();
            if (_a > _b) return 1;
            if (_a < _b) return -1;
            else return 0;
        });

        if (sportsArr.length === 0) {
            sportsArr.push({ value: 94, text: "Running" })
        }

        setSports(sportsArr)

    }, [t, i18n.language, i18n.store]);
        
    return (
        <SelectField
            mode={mode}
            showSearch
            disabled={disabled}
            className={className}
            label={label}
            name={name}
            allowClear={allowClear}
            placeholder={t('search') + '...'}
            filterOption={(input, option) => {
                if (!option || !option.children) return false
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            value={value}
            onSelect={onSelect}
            onChange={onChange}
            options={sports}
        />
    )
}
export default withTranslation(['common', 'sports'])(SelectSport);

