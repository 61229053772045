import config from 'config/index';
import { deburr, get as _get, includes } from 'lodash';
import { formDataEncode } from './form';
import { getAuthToken } from './persistence';

export const getProfileImage = (userId, format = '40x40', addTime = false) => {
    var baseUrl = process.env.REACT_APP_MEDIA_URL;

    var path;
    if (userId) {
        path = baseUrl + '/profiles/' + idToPath(userId) + '/' + userId + '.jpg';
    } else {
        path = `${baseUrl}/profiles/404.jpg`;
    }

    path = addSizeToFilename(path, format);

    if (addTime) {
        path += '?t=' + Date.now();
    }

    return path;
};

export const getEventImage = (competition, size = 'medium') => {
    if (competition.imageId && competition.cover) {
        return getS3Image(competition.cover, 'images', size);
    }
    return require('assets/images/page/competition.jpg');
};

export const getAssetEventImage = (cover) => {
    if (!cover) {
        return require('assets/images/page/competition.jpg');
    }
    return getS3Asset(cover);
};

export const getProfileUrl = (user, { abs = false } = {}) => {
    let name = user.name ? user.name : user.complete_name;
    if (!user.id || !name) {
        return '#';
    }
    if (abs) {
        return config.baseUrl + '/profile/' + user.id + '/' + slugify(name);
    }
    return '/profile/' + user.id + '/' + slugify(name);
};

export const getDetailsUrl = (type, id, name) => {
    return config.baseUrl + '/' + type + '/' + id + '/' + slugify(name);
};

export const getS3Image = (image, type = 'images', size = 'original') => {
    const id = image ? image._id : null;
    const name = _get(image, 'original.name', '');

    if (!id || !name) {
        return config.baseUrl + '/assets/img/imgheader_competitions.jpg';
    }

    //https://kscomp.s3.amazonaws.com/images/8HS9xR6CoNtmbdzWq-FruitLogistica2015_22.jpg
    var baseUrl = getS3BaseUrl();
    var path = '/' + type + '/' + size + '/' + id + '-' + name;

    return baseUrl + path;
};

export const getS3Asset = (asset) => {
    const baseUrl = getS3BaseUrl();
    return `${baseUrl}/${asset.key}`;
};

export const getS3BaseUrl = () => {
    let baseUrl;

    if (includes(['development'], config.environment)) {
        let bucket = 'kscomp';
        baseUrl = 'https://' + bucket + '.s3.amazonaws.com';
    } else {
        baseUrl = 'https://comp-01.keepsporting.com';
    }
    return baseUrl;
};

export const getFSImage = (image, size = 'preview') => {
    var baseUrl = config.uploadBaseUrl;
    var uri = _get(image, 'copies.' + size + '.path');
    if (!uri) {
        uri = _get(image, 'copies.original.path');
    }

    return baseUrl + '/' + uri;
};

export const getGalleryImage = (image, aws, size, type = 'images') => {
    return aws ? getS3Image(image, type, size) : getFSImage(image, size);
};

export const getS3Attachment = (id, name) => {
    if (!id || !name) {
        return false;
    }
    const baseUrl = getS3BaseUrl();
    const path = '/attachments/' + id + '-' + name;
    return baseUrl + path;
};

export const idToPath = (id) => {
    return (id % 100) + '/' + (Math.floor(id / 100) % 100);
};

export const addSizeToPath = (path, size) => {
    return path.replace(':size', size);
};

export const addSizeToFilename = (image, format) => {
    image = image || '';
    if (!image.length) return false;

    var filename = image.substring(0, image.lastIndexOf('.'));
    var extension = image.substring(image.lastIndexOf('.'));
    const path = filename + '-' + format + extension;
    return path;
};

export const slugify = (string) => {
    if (!string) return '';
    return deburr(
        string
            .toString()
            .trim()
            .toLowerCase()
            .replace(/[()\s&°ª'",/]/g, '-')
            .replace(/([^a-zA-Z0-9._-]+)/, '')
    );
};

export const getCompetitionUrl = (competition, abs = true) => {
    const uri = `/cr/${competition._id}/${slugify(competition.name || 'competition')}`;
    if (abs) {
        return config.baseUrl + uri;
    }
    return uri;
};

export const getCertificateUrl = (certificate) => {
    if (!certificate || !certificate.filename) {
        return '#';
    }

    return config.baseUrl + '/media/certificates/' + idToPath(certificate.user_id) + '/' + certificate.filename;
};
export const absUrl = (uri, qParams = null) => {
    if (uri && uri[0] === '/') {
        url = uri.substr(1);
    }

    var url = config.baseUrl + '/' + uri;

    if (qParams) {
        url += '?' + formDataEncode(qParams);
    }
    return url;
};

export const receiptURL = (receipt) => {
    return config.baseUrl + '/media/competitions/receipt/' + receipt.orgId + '/' + receipt.filename;
};

export const getNotFoundImageSrc = () => {
    return config.baseUrl + '/assets/img/album_placeholder.jpg';
};

export const getStaticMapSrc = (location, zoom = 10, size = '100x100') => {
    if (!location || !location.id) {
        return getNotFoundImageSrc();
    }

    return (
        config.apiUrl +
        '/../assets/cache/maps/' +
        idToPath(location.id) +
        '/' +
        location.id +
        '-' +
        zoom +
        '-' +
        size +
        '.jpg'
    );
};
export const getEventsPlatformUrl = (uri = '') => {
    let baseUrl = 'http://events.keepsporting.com';
    if (uri) {
        baseUrl += `/${uri}.html`;
    }
    return baseUrl;
};

export const eventUrl = (event) => {
    return `/cr/${event._id}/${slugify(event.name)}`;
};

export const contestUrl = (event, contest) => {
    return `${eventUrl(event)}/contest/${contest.id}/${slugify(contest.name)}`;
};

export const empHome = (orgId) => {
    return `${process.env.REACT_APP_EMP_URL}/profile/${orgId}?t=${getAuthToken()}`;
};

export const empAccessUrl = (orgId) => {
    return `${process.env.REACT_APP_EMP_URL}/signin-emp/${orgId}/${getAuthToken()}`;
};

export const getOrderIdFromPathname = (path) => {
    if (path && path.indexOf('/subscribe/order/') > -1) {
        let m = path.match(/subscribe\/order\/([A-z0-9-]+)/);
        if (m && m.length > 1) {
            return m[1];
        }
    }
    return null;
};

export const getWiclaxUrl = (event) => {
    if (event.wiclax_live_standings && event.wiclax_live_standings.length > 0) {
        return event.wiclax_live_standings[0];
    }
    return null;
};

export const parseQueryString = (queryString) => {
    if (!queryString) return {};

    if (queryString[0] === '?') {
        queryString = queryString.substr(1);
    }

    if (!queryString) {
        return {};
    }

    const searchParams = new URLSearchParams(queryString);

    return Array.from(searchParams.keys()).reduce((acc, key) => {
        acc[key] = searchParams.get(key);
        return acc;
    }, {});
};
