import React from 'react';
import { Route, Switch } from 'react-router-dom';
import '../styles/legacy/style.css';
import Event from './Event';
import CompleteRegistration from './Signup/CompleteRegistration';
import VerifyAccount from './Signup/VerifyAccount';
import CreateOrganization from './Signup/CreateOrganization';
import VerifyAccountCode from './Signup/VerifyAccountCode';
import ResendActivation from './Signin/ResendActivation';
import PermittedRoute from '../components/Router/PermittedRoute';
import { USER } from '../constants/App';
import Error404 from './Extra/Error404';
import ResetPasswordCode from './Signin/ResetPasswordCode';
import Error500 from '../components/Errors/500';
import { ErrorBoundary } from '@sentry/react';
import { lazyWithRetry } from '../util/router';
import { includes } from 'lodash';
import config from '../config';
import { useTranslation } from 'react-i18next';

const BlogRedirect = () => {
    
    const { i18n } = useTranslation();

    const baseBlogUrl = `${config.baseUrl}/blog`;

    let url;

    if (includes(['pt', 'it'], i18n.language)) {
        url = `${baseBlogUrl}/${i18n.language}`;
    } else {
        url = `${baseBlogUrl}/it`;
    }

    window.location = url;

    return null;
};

const App = ({ match }) => (
    <div className="gx-main-content-wrapper">
        <ErrorBoundary fallback={<Error500 />}>
            <Switch>
                <Route path={`${match.url}home`} component={lazyWithRetry(() => import('./Home'))} />
                <Route path={`${match.url}cr/:id/:slug`} component={Event} />
                <Route path={`${match.url}events`} component={lazyWithRetry(() => import('./Events'))} />
                <Route path={`${match.url}organizers`} component={lazyWithRetry(() => import('./Organizations'))} />
                <Route path={`${match.url}rankings`} component={lazyWithRetry(() => import('./Rankings'))} />
                <Route path={`${match.url}photos`} component={lazyWithRetry(() => import('./Photos'))} />
                <Route path={`${match.url}advertisement`} component={lazyWithRetry(() => import('./Advertisement'))} />
                <Route path={`${match.url}presentation`} component={lazyWithRetry(() => import('./Presentation'))} />
                <Route path={`${match.url}company`} component={lazyWithRetry(() => import('./Company'))} />
                <Route path={`${match.url}live-tracking`} component={lazyWithRetry(() => import('./LiveTracking'))} />
                <Route path={`${match.url}photo-video`} component={lazyWithRetry(() => import('./PhotoVideo'))} />
                <Route path={`${match.url}back-office`} component={lazyWithRetry(() => import('./BackOfficeService'))} />
                <Route path={`${match.url}media-service`} component={lazyWithRetry(() => import('./MediaService'))} />
                <Route path={`${match.url}contact-us`} component={lazyWithRetry(() => import('./Contact'))} />
                <Route path={`${match.url}global`} component={lazyWithRetry(() => import('./Global'))} />
                <Route path={`${match.url}reference`} component={lazyWithRetry(() => import('./Reference'))} />
                <Route path={`${match.url}work-with-us`} component={lazyWithRetry(() => import('./WorkWithUs'))} />
                <Route path={`${match.url}login`} component={lazyWithRetry(() => import('./Signin'))} />
                <Route
                    exact={true}
                    path={`${match.url}reset-password`}
                    component={lazyWithRetry(() => import('./Signin/ResetPassword'))}
                />
                <Route path={`${match.url}reset-password/:code`} component={ResetPasswordCode} />
                <Route path={`${match.url}resend-activation`} component={ResendActivation} />
                <Route exact={true} path={`${match.url}register`} component={lazyWithRetry(() => import('./Signup'))} />
                <Route exact={true} path={`${match.url}register/:id`} component={CompleteRegistration} />
                <Route exact={true} path={`${match.url}register/:id/verify`} component={VerifyAccount} />
                <Route exact={true} path={`${match.url}register/:id/organization`} component={CreateOrganization} />
                <Route exact={true} path={`${match.url}register/:id/verify/:code`} component={VerifyAccountCode} />

                <Route exact={true} path={`${match.url}online-subscription`} component={lazyWithRetry(() => import('./OnlineSubscription'))} />
                <Route exact={true} path={`${match.url}marketing`} component={lazyWithRetry(() => import('./Marketing'))} />
                <Route exact={true} path={`${match.url}timing`} component={lazyWithRetry(() => import('./Timing'))} />


                <PermittedRoute
                    exact={true}
                    path={`${match.url}tickets`}
                    component={lazyWithRetry(() => import('./Tickets/List'))}
                />
                <Route
                    exact={true}
                    path={`${match.url}tickets/:id`}
                    component={lazyWithRetry(() => import('./Tickets/Item'))}
                />
                <Route
                    path={`${match.url}tickets/:tickets_id/player/:id`}
                    component={lazyWithRetry(() => import('./Tickets/PlayerView'))}
                />

                <PermittedRoute
                    role={USER.ROLES.USER}
                    exact={true}
                    path={`${match.url}profile`}
                    component={lazyWithRetry(() => import('./MyProfile'))}
                />
                <Route
                    exact={true}
                    path={`${match.url}profile/:id/:slug?`}
                    component={lazyWithRetry(() => import('./Profile'))}
                />
                <PermittedRoute
                    role={USER.ROLES.USER}
                    path={`${match.url}chat`}
                    component={lazyWithRetry(() => import('./Chat'))}
                />
                <PermittedRoute
                    role={USER.ROLES.USER}
                    path={`${match.url}settings`}
                    component={lazyWithRetry(() => import('./Settings'))}
                />
                <PermittedRoute
                    role={USER.ROLES.ADMIN}
                    path={`${match.url}admin`}
                    component={lazyWithRetry(() => import('./Admin'))}
                />
                <Route path={`${match.url}legal/cookies`} component={lazyWithRetry(() => import('./Legal/Cookie'))} />
                <Route path={`${match.url}legal/privacy`} component={lazyWithRetry(() => import('./Legal/Privacy'))} />
                <Route
                    path={`${match.url}legal/terms-conditions`}
                    component={lazyWithRetry(() => import('./Legal/Terms'))}
                />
                <Route path={`${match.url}blog`} exact component={BlogRedirect} />

                <Route component={Error404} />
            </Switch>
        </ErrorBoundary>
    </div>
);

export default App;
