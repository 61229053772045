import React, { Component } from 'react';
import Slider from 'react-slick';
import { url } from 'util/index';
import { Card } from 'antd';
import './styles/sponsor.css';

class Sponsor extends Component {

    constructor(props, context) {
        super(props, context)
    }

    render() {
        //var sponsorsNum = this.props.sponsors.length;

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 580,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            adaptiveHeight: false //true do not work
        };

        const { t } = this.props;

        return (
            <Card title={t('competitions:sponsor')} className="gx-card" style={{ 'padding': '0px' }}>
                
                <Slider {...settings}>
                    {this.props.sponsors.filter(s => !!s.image).map((s) => {
                        let image_url = url.getS3Image(s.image, 'images', 'medium');
                        return (

                            <a key={s._id} rel="noreferrer" target="_blank" href={s.siteUrl} style={{ display: 'flex', alignItems: 'center' }}>
                                <img className="img-responsive panel-img" src={image_url} alt={s.image.original.name} />
                            </a>

                        )
                    })}
                </Slider>
            </Card>
        )
    }
}

export default Sponsor
