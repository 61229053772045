export const orgToUserMorph = (org) => {
    //TODO check other data
    return {
        id: org.id,
        random_id: org.random_id,
        type: 4,
        complete_name: org.complete_name,
        location_id: org.location_id,
        photo: org.photo,
        timezone: org.timezone,
        email: org.email,
        active: org.active,
        language: org.language        
    }
}