export default class BaseValidator {
    constructor() {

    }

    isValid() {
        return this.result.valid === true;
    }

    storeObj(t) {
        let obj = {};
        if (! this.isValid()) {
            this.result.errors.forEach(error => {
                obj[ error.property ] = {
                    value: error.actual,
                    message: this.getErrorMessage(error, t),
                    attribute: error.attribute,
                    state: 'error'
                }
            });
        }
        return obj;
    }

    toArray(form, t) {

        let arr = [];
        if (! this.isValid()) {
            this.result.errors.forEach(error => {
                arr.push({ form: form, field: error.property, message: this.getErrorMessage(error, t) });
            })
        }
        return arr;
    }

    getErrorMessage(error, t) {
        let attribute;
        if (error.property === 'phone') {
            attribute = 'phone';
        } else {
            attribute = error.attribute;
        }

        switch (attribute) {
        case 'phone':
            return t('form_error_phone_pattern');
        case 'pattern':
            if(error.message.indexOf('_') > -1){
                //added custom message
                return t(error.message);
            } else {
                return t('form_error_' + error.property + '_' + error.attribute);
            }
        case 'conform':
            return t('form_error_' + error.property + '_' + error.attribute);
        default:
            return t('form_error_' + error.attribute, { value: error.expected })
        }
    }
}