import { setPaymentMethod } from 'appRedux/actions/Tickets';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


class Payu extends Component {

    constructor(props, context) {
        super(props, context);        
        this.state = {
            message: "",
            loading: false,
            payu: {},
            payuError: false,
            payuSuccess: false
        };
    }

    componentDidMount() {
        const { competition } = this.props;
        this.props.dispatch(setPaymentMethod({ active: "payu", payments: competition.payments }));        

    }

    handleAlertDismiss() {
        this.setState({ payuError: false });
    }

    render() {
        const { t } = this.props;

        return (
            <div style={{ padding: 15 }}>
                {t('payu_payment_description')}
            </div>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        setPaymentMethod: (params) => dispatch(setPaymentMethod(params))
    }
};

export default connect(null,mapDispatchToProps)(withTranslation([ 'common' ])(Payu))