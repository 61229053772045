import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTypeByPaymentSlug } from 'util/payments';

class BoletoCompletedInformation extends React.Component {
    /**
   * payment could have full transaction (re-fetch case) or
   * publishable response with keys 'paid, active, link, expire_at, charge_id'
   */
    constructor(props) {
        super(props);

        this.paymentData = {};

        if (props.payment.transaction) {
            const { transaction } = props.payment;

            this.paymentData = {
                active: 'boleto',
                paid: props.payment.paid,
                link: transaction.payoff?.charge.link,
                expire_at: transaction.payoff?.charge.expire_at,
                payment_id: transaction.payoff?.charge.barcode,
            };

            this.paymentData.is_expired = !this.paymentData.link || this.isExpiredBoleto(this.paymentData.expire_at);
        } else {
            this.paymentData = props.payment;
        }

        this.state = {
            processingRenew: false,
        };
    }

    isExpiredBoleto(expire_at) {
        return moment(expire_at, 'YYYY-MM-DD').isBefore(moment().add('days', 1));
    }

    render() {
        const { t } = this.props;

        if (this.paymentData.paid) {
            return (
                <div>
                    <p>
                        {t('payment_successfully_completed')} ({this.paymentData.active})
                    </p>
                    <ul>
                        <li>
                            {t('payment_type')}: <strong>{t('payment_type_' + getTypeByPaymentSlug(this.paymentData.active))}</strong>
                        </li>
                        <li>
                            {t('payment_id')}: <strong>{this.paymentData.payment_id}</strong>
                        </li>
                    </ul>
                </div>
            );
        } else {
            return (
                <div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('boleto_payment_check_your_mail', [this.paymentData.link]),
                        }}
                    ></p>
                    <p dangerouslySetInnerHTML={{ __html: t('boleto_payment_advice') }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('boleto_not_renewable') }}></p>
                    {this.paymentData.link && (
                        <div className="gx-text-center">
                            <a href={this.paymentData.link} target="_blank" rel="noreferrer" className="btn btn-ks btn-lg">
                                {t('download_boleto')}
                            </a>
                        </div>
                    )}
                </div>
            );
        }
    }
}

BoletoCompletedInformation.contextTypes = {
    router: PropTypes.object,
};

export default connect()(withTranslation(['common', 'payment'])(BoletoCompletedInformation));
