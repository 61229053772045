import { keys, find, includes, get } from 'lodash';
import { EVENT } from '../constants/App';
import { getPersistKey, persist } from './persistence';
import { formDataDecode } from './form';

/**
 *
 * @param price
 * @param discount object on redux store
 * @returns {{newPrice: *, discountValue: number}}
 */
export function applyDiscount(price, discount) {
    if (!discount || !discount.valid || !price) {
        return { newPrice: price, discountValue: 0 };
    }

    const discountValue = price * (discount.percent / 10000) + discount.fixed;

    let newPrice = price - discountValue;

    if (newPrice < 0) {
        newPrice = 0;
    }
    return { newPrice: newPrice, discountValue: discountValue };
}

/**
 * controlla se c'è almeno una iscrizione di squadra
 * @param {*} tickets
 */
export function hasTeams(tickets, competition) {
    if (competition && competition.type === EVENT.TYPE.TRAVEL) return false;

    if (!tickets.teams || !keys(tickets.teams)) return false;

    if (!tickets.order) return false;

    const teamRaces = keys(tickets.teams);

    if (teamRaces.length === 0) return false;

    const orderRaces = tickets.order.map((t) => t.raceId);

    return !!find(teamRaces, (teamRaceId) => includes(orderRaces, teamRaceId));
}

export const storeTicketsPrivateKey = (ticketsId, key) => {
    persist(`tickets_${ticketsId}`, key);
};

export const getTicketsPrivateKey = (tickets, location = null) => {

    if(!tickets) return null;
    const privateKeyFromPayment = get(tickets, 'payment.key', null);
    if (privateKeyFromPayment) return privateKeyFromPayment;

    const privateKeyFromStorage = getPersistKey(`tickets_${tickets.id}`);
    if (privateKeyFromStorage) return privateKeyFromStorage;

    if (location && location.search) {
        const qs = formDataDecode(location.search);
        return get(qs, 'pk', null);
    }
    return null;
};
