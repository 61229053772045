import { Col, Modal, Row } from 'antd';
import { setPaymentMethod, updatePaymentForm } from 'appRedux/actions/Tickets';
import CircularProgress from 'components/CircularProgress';
import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextField from '../../../../../components/Form/Legacy/TextField';
import PaymentValidator from '../validators/PaymentValidator';

class StripeCheckoutForm extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { stripeCard } = this.props;

        stripeCard[0].mount('#card-number');
        stripeCard[1].mount('#card-expiration');
        stripeCard[2].mount('#card-cvc')
        /*stripeCard.addEventListener('change', function (event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });*/
    }

    handleChange(field, event) {

        const value = event.target.value;

        const isValid = new PaymentValidator({ active: 'stripe', form: { [field]: value } }).validateField(field).isValid();

        this.props.dispatch(updatePaymentForm({
            value: value, field: field, valid: isValid
        }));
    }


    render() {

        const { t, showDialog, payment } = this.props;

        return (
            <>
                <Row>
                    <Col xs={24} className="gx-mb-3">
                        <TextField
                            type="text"
                            size="20"
                            label={t('card_holder')}
                            onChange={this.handleChange.bind(this, 'card_holder')}
                            value={get(payment, 'form.card_holder')}
                            validationState={get(payment.errors, 'card_holder.state')}
                            help={get(payment.errors, 'card_holder.message')} />
                    </Col>
                </Row>
                <div id="card-errors"></div>
                <Row>
                    <Col xs={24} md={14} className="gx-mb-3 gx-pr-md-3" >
                        <div>
                            <label>{t('card_number')}</label>
                            <div id="card-number" className="ant-input form-control"></div>
                        </div>
                    </Col>
                    <Col xs={24} md={10} className="gx-mb-3">
                        <div>
                            <label>{t('expire_date_m_y')}</label>
                            <div id="card-expiration" className="ant-input form-control"></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} className="gx-mb-3">
                        <div>
                            <label>{t('cvc')}</label>
                            <div id="card-cvc" className="ant-input form-control"></div>
                        </div>
                    </Col>
                    <Col xs={14} className="gx-mt-4 gx-pl-3">
                        <a href="#ccv" onClick={showDialog} className=""><h5>{t('what_is_cvc')}</h5></a>
                    </Col>
                </Row>

            </>
        );
    }
}

class StripeElements extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            message: "",
            loading: false,
            showCvcDialog: false,
            paymentError: false,
            paymentMessage: ""
        };
    }

    componentDidMount() {

        this.props.dispatch(setPaymentMethod({ active: "stripe", payments: this.props.competition.payments }));

        if (window.Stripe) {
            //this.props.dispatch(updatePaymentForm({ field: 'stripe', value: window.Stripe(provider.publicKey) }));

        } else {
            /*document.querySelector('#stripe-js').addEventListener('load', () => {
                //this.props.dispatch(updatePaymentForm({ field: 'stripe', value: window.Stripe(provider.publicKey) }));
                //this.props.dispatch(setPaymentMethod({ active: "stripe", payments: competition.payments, providerInstance: window.Stripe(provider.publicKey) }));
            });*/
        }


    }

    stopInput(fieldName, value) {
        if (fieldName === 'exp_month' && value.length > 2) {
            return true;
        } else if (fieldName === 'exp_year' && value.length > 4) {
            return true;
        } else if (fieldName === 'cvc' && value.length > 6) {
            return true;
        } else if (fieldName === 'number' && value.length > 20) {
            return true;
        }

        return false;
    }

    handleChange = (field, event) => {

        const value = event.target.value;

        if (this.stopInput(field, value)) return;

        const isValid = new PaymentValidator({ active: 'stripe', form: { [field]: value } }).validateField(field).isValid();

        this.props.dispatch(updatePaymentForm({
            value: value, field: field, valid: isValid
        }));
    }

    showDialog = (e) => {
        e.preventDefault();
        this.setState({ showCvcDialog: true });
    }

    hideDialog = () => {
        this.setState({ showCvcDialog: false });
    }

    render() {

        const { t, stripeCard } = this.props;

        if (!stripeCard) {
            return <CircularProgress loading={true} size="large" className="gx-mt-4" />
        }

        return (
            <div className="gx-text-left" >
                <StripeCheckoutForm {...this.props} showDialog={(e) => this.showDialog(e)} />
                <Modal title={t('what_is_cvc')} visible={this.state.showCvcDialog} onCancel={this.hideDialog} onOk={this.hideDialog}>                    
                    <img className="img-responsive" src={require('assets/images/cvc.png')} alt="CVC helper" />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        payment: state.tickets.payment
    };
};

export default connect(mapStateToProps)(withTranslation(['common'])(StripeElements))
