import { getRace } from "../../client/contest";

function requestRace(raceId) {
    return {
        type: 'REQUEST_RACE',
        raceId
    }
}


function receiveRace(raceId, json) {
    return {
        type: "RECEIVE_RACE",
        raceId,
        data: json,
        receivedAt: Date.now()
    }
}

export function fetchRace(contestId) {

    return (dispatch) => {
        dispatch(requestRace(contestId));
        return getRace(contestId)
            .then((response) => {
                dispatch(receiveRace(contestId, response.data));
            }).catch(err => {
                console.log("fetchRace@error", err)
                //window.location.href = '/r/competitions';
            });
    }
}