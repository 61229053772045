import { Button, Checkbox, Col, Divider, message, Row } from "antd";
import { formServerErrors, processOrder, processStripeScaError } from 'appRedux/actions/Tickets';
import AdSense from 'components/AdSense';
import CircularProgress from 'components/CircularProgress';
import { ADSENSE_SLOTS } from 'config';
import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { push } from "react-router-redux";
import { url } from 'util/index';
import { getProviderBySlug } from "../../../../util/payments";
import InformationBox from "../../Main/InformationBox";
import OrderSummary from "../Form/OrderSummary";
import TicketsOrderFormErrors from "../Form/TicketsOrderFormErrors";
import { onlinePaymentErrorCodes } from "../Form/validators/StripePaymentValidator";
import PayerData from "./PayerData";
import PaymentData from "./PaymentData";
import Assistance from "../Assistence";
import PlayerData from "./PlayerData";
import { getCompetitionUrl } from "../../../../util/url";
import TeamData from "./TeamData";

class TicketsOrderVerification extends Component {

    constructor(props) {
        super(props);
        this.stripeInstance = null;
        this.state = {
            conditionAccepted: false,
            conditionState: null,
            conditionReadMore: false
        };
    }

    componentDidMount() {
        const { history, tickets, competition, t } = this.props;

        //se l'ordine è "completed" rimanda alla schemata di conferma (l'ultima)
        if (tickets.step === 'completed') {
            message.info(t('tickets_purchase_completed_message', { value: tickets.id }));
            const url = `${getCompetitionUrl(competition, false)}/subscribe/order/${tickets.id}/completed?pk=${get(tickets, 'payment.key', '')}`;
            return history.push(url);
        }

    }

    onProcessOrder() {

        const { processOrder, tickets } = this.props;

        if (!this.state.conditionAccepted) {
            this.setState({ conditionState: 'error' });
            return null;
        }

        processOrder(tickets)

    }

    processStripeScaRequest() {
        const { tickets, competition, processOrder, processStripeScaError, pushRoute, i18n } = this.props;

        const provider = getProviderBySlug(competition.payments, 'stripe');

        if (window.Stripe && provider) {
            this.stripeInstance = window.Stripe(provider.publicKey, { locale: i18n.language });
            this.stripeInstance.handleCardAction(
                this.props.tickets.payment.client_key
            ).then(function (result) {
                if (result.error) {
                    if (i18n.language === 'pt' && onlinePaymentErrorCodes[result.error.code]) {
                        result.error.message = onlinePaymentErrorCodes[result.error.code];
                    }
                    processStripeScaError(result.error);
                    pushRoute(`${getCompetitionUrl(competition, false)}/subscribe/order/${tickets.id}`);
                } else {
                    tickets.payment.form.intent_token = result.paymentIntent.id;
                    processOrder(tickets)
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const objKey = 'tickets.payment.action';
        if (!get(prevProps, objKey) && get(this.props, objKey) === true) {
            this.processStripeScaRequest();
        }
    }

    handleDisclaimerCondition() {
        this.setState({ conditionAccepted: !this.state.conditionAccepted, conditionState: 'success' })
    }

    render() {

        const { t, tickets, competition, user } = this.props;

        if (!tickets || !tickets.id) {
            return <Redirect to={getCompetitionUrl(competition, false)} />
        }

        if (!tickets.payer || !tickets.payer.email) {
            return <Redirect to={getCompetitionUrl(competition, false) + '/subscribe/order/' + tickets.id} />
        }

        return (
            <Col xs={24} className="gx-p-0 gx-p-md-3">

                <Row>
                    <Col xs={24} md={16} className="gx-pr-0 gx-pr-md-3">
                        <Row>
                            <PayerData tickets={tickets} user={user} />
                        </Row>
                        <Row>
                            <TeamData tickets={tickets} competition={competition}/>
                        </Row>
                        <Row>
                            {tickets.order.map((order, index) => {
                                let race = competition.racesSummary.find(r => r.id === order.raceId);

                                return <PlayerData key={`${order.raceId}-${index}`}
                                    ticket={order}
                                    race={race}
                                    index={index + 1}
                                    player={tickets.players[order.id]}
                                    team={get(tickets, `teams.${race.id}`)}
                                    competition={competition}
                                    t={t}
                                />
                            })}
                        </Row>
                        <Row>
                            <OrderSummary tickets={tickets} competition={competition} />
                        </Row>
                        <Row>
                            <PaymentData tickets={tickets} user={user} competition={competition} />
                        </Row>
                        <Row>
                            <Col className="tickets-conditions">
                                <p dangerouslySetInnerHTML={{
                                    __html:
                                        t('payment_conditions', [competition.organization.name, `/legal/privacy`])
                                            .slice(0, this.state.conditionReadMore ? -1 : 250)

                                }}></p>
                                <a href="#" className="gx-d-block gx-text-center gx-mb-2" onClick={e => {
                                    e.preventDefault()
                                    this.setState({ conditionReadMore: !this.state.conditionReadMore })
                                }}>
                                    <span>{!this.state.conditionReadMore ? t('common_read_all') : ''}</span>
                                </a>
                                <Checkbox
                                    type="checkbox"
                                    className={`payment-checkbox ${this.state.conditionState ? `has-${this.state.conditionState}` : ''}`}
                                    checked={this.state.conditionAccepted}
                                    onClick={this.handleDisclaimerCondition.bind(this)}>
                                    {t('competition_subscription_conditions_check')}
                                </Checkbox>
                            </Col>
                        </Row>
                        <Divider />
                        <Row className="gx-xs-pb-4">
                            <Col xs={24}>
                                <TicketsOrderFormErrors errors={tickets.payment.errors} />
                            </Col>
                            <Col xs={24} md={12} >
                                <CircularProgress loading={tickets.fetching} parentClassName="order-form-spinner">
                                    <Button className="ant-btn gx-btn-outline-primary gx-btn-lg btn-subscribe gx-w-100"
                                        type="submit"
                                        onClick={this.onProcessOrder.bind(this)}>
                                        {t('complete')}
                                    </Button>
                                </CircularProgress>
                            </Col>
                            <Col xs={24} md={8} className="gx-text-center gx-mt-2 gx-mb-4 gx-mb-md-0">
                                <Link className="btn-wide" to={`${url.eventUrl(competition)}/subscribe/order/${tickets.id}`}>{t('back')}</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={8}>
                        <InformationBox competition={competition} />
                        <Divider />
                        <Row>
                            <Assistance assistanceDealerOrganizationTextKey="assistance_buy_dealer_organization" dealerOnly={false} competition={competition} />
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <AdSense label={t('ads')}
                                    client="ca-pub-2892314018985211"
                                    slot={ADSENSE_SLOTS.EVENT_SIDE_BLOCK} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        tickets: state.tickets,
        user: state.user
    }
};

function mapDispatchToProps(dispatch) {
    return {
        processOrder: tickets => { dispatch(processOrder(tickets)) },
        processStripeScaError: (error) => { dispatch(processStripeScaError(error)) },
        pushRoute: (route) => dispatch(push(route)),
        processServerError: errors => dispatch(formServerErrors(errors))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common'])(TicketsOrderVerification))
