import { get } from 'lodash';
import React from 'react';
import { getTicketsPrivateKey } from '../../../../util/Tickets';

/**
 *
 * @param race (raceSummary obj)
 * @param t
 * @constructor
 */

export const CertificateRaceAdvice = ({ race, tickets, t }) => {
    const certificateType = get(race, 'certificate.type', 0);
    if (certificateType > 1) {
        const text = t('order_complete_race_certificate_advice', [race.name, t('certificate_type_' + certificateType).toLowerCase()]);
        return (
            <div>
                <p dangerouslySetInnerHTML={{ __html: text }}></p>
                <div className="text-center">
                    <a href={`/tickets/${tickets.id}?pk=${getTicketsPrivateKey(tickets)}`} className="btn btn-lg ks-btn-outline">
                        {t('load_certificate')}
                    </a>
                </div>
            </div>
        )
    }
    return null;

};

export const AthleteCardPlayerAdvice= ({ race, card, t }) => {
    const text = t('order_complete_race_card_advice', [race.name, card.label]);
    return (
        <div className="">
            <h4>{t('athlete_card')}</h4>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>            
        </div>
    )
}

export const CertificatePlayerAdvice = ({ race, player, t }) => {    
    let certificateType = get(race, 'certificate.type', 0);
    if(player.should_upload_certificate && certificateType < 2){
        certificateType = 3;
    }
    const text = t('order_complete_race_certificate_advice', [race.name, t('certificate_type_' + certificateType).toLowerCase()]);
    return (
        <div className="">
            <h4>{t('medical_certificate')}</h4>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>            
        </div>
    )
};