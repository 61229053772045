import { TwitterOutlined } from '@ant-design/icons';
import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import './styles/SocialShareLinks.css';

const SocialShareLinks = ({ competition, url }) => {

    const encode = (s) => encodeURIComponent(s);

    return (
        <div>
            <div className={'v2 share-title marginleft10'}> {<IntlMessages id="share_with_friends" />} </div>
            <ul className="icons-list">
                {/* <li className="icon">
                    <a href={`${config.baseUrl}/sharer?url=${encode(url)}`}
                        target={'_blank'} >
                        <img width="15" height="15" src={require('assets/images/logo/keepsporting-icon-white.png')} alt="Keepsporting" />
                    </a>
                </li> */}
                <li className="icon">
                    <a href={`https://www.facebook.com/sharer.php?u=${encode(url)}&title=${encode(competition.name)}`}
                        target={'_blank'} rel="noreferrer">
                        <i className="i-custom icon-facebook"></i>
                    </a>
                </li>
                <li className="icon">
                    <a href={`https://twitter.com/intent/tweet?url=${encode(url)}&text=${encode(competition.name)}&hashtags=Keepsporting`}
                        target={'_blank'} rel="noreferrer">
                        <TwitterOutlined />
                    </a>
                </li>                
            </ul>
        </div>
    )
}

SocialShareLinks.defaultProps = {
    url: window.location.href
};

export default SocialShareLinks;