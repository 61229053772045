import { Modal, Row, Col } from 'antd';
import { processGnInstallments, setPaymentMethod, updatePaymentForm } from 'appRedux/actions/Tickets';
import { birthdayDefault, getTicketsTotal } from 'appRedux/reducers/helpers/tickets';
import CircularProgress from 'components/CircularProgress';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Price from '../../../../../components/Competition/Price';
import SelectDatepicker from '../../../../../components/Form/Legacy/SelectDatepicker';
import SelectField, { emptyOption } from '../../../../../components/Form/Legacy/SelectField';
import TextField from '../../../../../components/Form/Legacy/TextField';
import PaymentValidator from '../validators/PaymentValidator';
import { LoadingOutlined } from '@ant-design/icons';

const PAYMENT_SLUG = 'card-gerencianet';

export const GN_DEFAULT_CARD_BRAND = 'mastercard';
export const GN_DEFAULT_INSTALLMENTS = '1';

const CardGerencianet = (props) => {
    const initState = {
        message: '',
        loading: false,
        showCvcDialog: false,
        paymentError: false,
        paymentMessage: '',
    };

    const [formState, setFormState] = useState(initState);

    useEffect(() => {
        const { competition, tickets } = props;
        props.dispatch(setPaymentMethod({ active: PAYMENT_SLUG, payments: competition.payments }));

        if (window.$gn.checkout) {
            //come back to this payment
            props.dispatch(processGnInstallments(window.$gn.checkout, getTicketsTotal(tickets), GN_DEFAULT_CARD_BRAND));
        }
    }, [props.checkoutReady]);

    const updateFormState = (newState) => {
        setFormState({ ...formState, ...newState });
    };

    const stopInput = (fieldName, value) => {
        if (fieldName === 'exp_month' && value.length > 2) {
            return true;
        } else if (fieldName === 'exp_year' && value.length > 4) {
            return true;
        } else if (fieldName === 'cvc' && value.length > 6) {
            return true;
        } else if (fieldName === 'number' && value.length > 20) {
            return true;
        }

        return false;
    };

    const onBirthdayChange = (date) => {
        handleChange('birthday', date.format('YYYY-MM-DD'));
    };

    const handleChange = (field, event) => {
        let value = event.target ? event.target.value : event;

        if (stopInput(field, value)) return;

        if (field === 'province') {
            value = value.toUpperCase();
        }

        const isValid = new PaymentValidator({ active: PAYMENT_SLUG, form: { [field]: value } })
            .validateField(field)
            .isValid();

        props.dispatch(
            updatePaymentForm({
                value: value,
                field: field,
                valid: isValid,
            })
        );

        if (field === 'card_brand') {            
            props.dispatch(processGnInstallments(props.gnCheckout, getTicketsTotal(props.tickets), value));
        }
    };

    const showDialog = (e) => {
        e.preventDefault();
        updateFormState({ showCvcDialog: true });
    };

    const hideDialog = () => {
        updateFormState({ showCvcDialog: false });
    };

    const { t, payment, checkoutReady, tickets, competition } = props;

    if (!payment.form || !checkoutReady || tickets.payment.active !== PAYMENT_SLUG) {
        return (
            <h3 className="col-md-12 xs-m-10">
                <LoadingOutlined />
            </h3>
        );
    }

    return (
        <div className="gx-text-left" style={{ padding: 15 }}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} className="gx-pt-4">
                    <p>{t('accepted_card')}</p>
                </Col>
                <Col xs={24} sm={16}>
                    <img style={{ float: 'left', marginTop: 6 }} src={require('assets/images/cc_mastercard.png')} />
                    <img
                        style={{ float: 'left', marginTop: 6, marginLeft: 15 }}
                        src={require('assets/images/cc_visa.png')}
                    />
                    <img
                        style={{ float: 'left', marginTop: 6, marginLeft: 15 }}
                        src={require('assets/images/cc_amex.png')}
                    />
                </Col>
            </Row>

            <Row className="gx-mt-4 gx-mt-md-0" gutter={[16, 16]}>
                <Col xs={24} md={16}>
                    <TextField
                        type="text"
                        size="20"
                        label={t('card_number')}
                        onChange={(e) => handleChange('number', e)}
                        value={payment.form.number}
                        validationState={get(payment.errors, 'number.state')}
                        help={get(payment.errors, 'number.message')}
                    />
                </Col>
                <Col xs={24} md={8}>
                    <SelectField
                        label={t('card_brand')}
                        name="card_brand"
                        value={payment.form.card_brand}
                        onChange={(e) => handleChange('card_brand', e)}
                        options={[
                            { value: GN_DEFAULT_CARD_BRAND, text: 'MasterCard' },
                            { value: 'visa', text: 'Visa' },
                            { value: 'amex', text: 'Amex' },
                        ]}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} sm={8} className="gx-pr-3">
                    <TextField
                        type="text"
                        size="4"
                        label={t('cvc')}
                        onChange={(e) => handleChange('cvc', e)}
                        validationState={get(payment.errors, 'cvc.state')}
                        value={payment.form.cvc}
                        help={get(payment.errors, 'cvc.message')}
                    />
                </Col>
                <Col xs={24} sm={8} className="gx-pt-4">
                    <a href="#ccv" onClick={(e) => showDialog(e)} className="">
                        <h5>{t('what_is_cvc')}</h5>
                    </a>
                </Col>
                <Col xs={24} sm={8} className="col-sm-4">
                    <CircularProgress
                        loading={tickets.payment.installmentsLoading}
                        className="gx-mt-4"
                    >
                        <SelectField
                            label={t('installments')}
                            value={payment.form.installments}
                            onChange={(e) => handleChange('installments', e)}
                            options={[emptyOption()].concat(
                                payment.installments &&
                                    payment.installments
                                        .filter((i) => i.value)
                                        .map((inst) => {
                                            return {
                                                value: inst.installment,
                                                text: (
                                                    <span>
                                                        {`${inst.installment} - `}{' '}
                                                        <Price
                                                            price={{ price: inst.value }}
                                                            competition={competition}
                                                        />
                                                    </span>
                                                ),
                                            };
                                        })
                            )}
                        />
                    </CircularProgress>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} sm={12} className="gx-pr-3">
                    <TextField
                        type="text"
                        size="2"
                        label={t('expire_date_month')}
                        onChange={(e) => handleChange('exp_month', e)}
                        value={payment.form.exp_month}
                        validationState={get(payment.errors, 'exp_month.state')}
                        help={t('month') + ' ( ' + t('example').slice(0, 2) + '. 12 )'}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        type="text"
                        size="4"
                        label={t('expire_date_year')}
                        onChange={(e) => handleChange('exp_year', e)}
                        validationState={get(payment.errors, 'exp_year.state')}
                        value={payment.form.exp_year}
                        help={
                            t('year') +
                            ' ( ' +
                            t('example').slice(0, 2) +
                            '. ' +
                            (parseInt(new Date().getFullYear()) + 2) +
                            ' )'
                        }
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} md={24}>
                    <SelectDatepicker
                        name="birthday"
                        day={birthdayDefault.date()}
                        month={birthdayDefault.month()}
                        year={birthdayDefault.year()}
                        required={true}
                        onDateChange={(e) => onBirthdayChange(e)}
                        value={payment.form.birthday}
                        validationState={get(payment.errors, 'birthday.state', null)}
                        help={get(payment.errors, 'birthday.message', null)}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} md={18}>
                    <TextField
                        type="text"
                        name="city"
                        label={t('residence_location') + ' *'}
                        onChange={(e) => handleChange('city', e)}
                        value={payment.form.city}
                        validationState={get(payment.errors, 'city.state')}
                        help={get(payment.errors, 'city.message', ' ')}
                    />
                </Col>
                <Col xs={24} md={6}>
                    <TextField
                        type="text"
                        name="phone_number"
                        label={t('phone') + ' *'}
                        onChange={(e) => handleChange('phone_number', e)}
                        value={payment.form.phone_number}
                        validationState={get(payment.errors, 'phone_number.state')}
                        help={get(payment.errors, 'phone_number.message')}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} md={18}>
                    <TextField
                        type="text"
                        name="street"
                        label={t('street') + ' *'}
                        onChange={(e) => handleChange('street', e)}
                        value={payment.form.street}
                        validationState={get(payment.errors, 'street.state')}
                        help={get(payment.errors, 'street.message')}
                    />
                </Col>
                <Col xs={24} md={6}>
                    <TextField
                        type="text"
                        name="street_number"
                        label={t('street_number') + ' *'}
                        onChange={(e) => handleChange('street_number', e)}
                        value={payment.form.street_number}
                        validationState={get(payment.errors, 'street_number.state')}
                        help={get(payment.errors, 'street_number.message')}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} md={6}>
                    <TextField
                        type="text"
                        name="province"
                        label={t('province') + ' *'}
                        onChange={(e) => handleChange('province', e)}
                        value={payment.form.province}
                        validationState={get(payment.errors, 'province.state')}
                        help={get(payment.errors, 'province.message')}
                    />
                </Col>
                <Col xs={24} md={18}>
                    <TextField
                        type="text"
                        name="district"
                        label={t('district') + ' *'}
                        onChange={(e) => handleChange('district', e)}
                        value={payment.form.district}
                        validationState={get(payment.errors, 'district.state')}
                        help={get(payment.errors, 'district.message')}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="gx-mt-3">
                <Col xs={24} md={8}>
                    <TextField
                        type="text"
                        name="cap"
                        label={t('cap') + ' *'}
                        onChange={(e) => handleChange('cap', e)}
                        value={payment.form.cap}
                        validationState={get(payment.errors, 'cap.state')}
                        help={get(payment.errors, 'cap.message')}
                    />
                </Col>
                <Col xs={24} md={16}>
                    <TextField
                        type="text"
                        name="fiscal_code"
                        label={t('fiscal_code') + ' *'}
                        onChange={(e) => handleChange('fiscal_code', e)}
                        value={payment.form.fiscal_code}
                        validationState={get(payment.errors, 'fiscal_code.state')}
                        help={get(payment.errors, 'fiscal_code.message')}
                    />
                </Col>
            </Row>

            <Modal
                title={t('what_is_cvc')}
                visible={formState.showCvcDialog}
                onCancel={() => hideDialog()}
                onOk={() => hideDialog()}
            >
                <img className="img-responsive" src={require('assets/images/cvc.png')} alt="CVC helper" />
            </Modal>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        payment: state.tickets.payment,
    };
};

export default connect(mapStateToProps)(withTranslation(['common'])(CardGerencianet));
