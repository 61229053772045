import { Card, Col, Divider, Row } from 'antd';
import { fetchTicket } from 'appRedux/actions/Tickets';
import AdSense from 'components/AdSense';
import CircularProgress from 'components/CircularProgress';
import { ADSENSE_SLOTS } from 'config';
import { find, get, keys, map } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { url } from 'util/index';
import InformationBox from '../../Main/InformationBox';
import { AthleteCardPlayerAdvice, CertificatePlayerAdvice, CertificateRaceAdvice } from './CertficateRaceAdvice';
import PaymentCompleted from './Payment';
import Assistance from '../Assistence';
import { getTicketsPrivateKey } from '../../../../util/Tickets';
import { FileDoneOutlined, UnorderedListOutlined } from '@ant-design/icons';

const getPlayerCard = ({ race, player }) => {
    if (!player.athlete_card) return null;

    const cardOptions = get(race, 'standardFields.athlete_card.options', null);
    if (!cardOptions) return null;

    const card = find(cardOptions, (o) => o.value === player.athlete_card);
    if (!card) return null;
    return card;
};

const TicketPostActions = ({ race, player, tickets, t }) => {
    const card = getPlayerCard({ race, player });

    let shouldUploadCertificate = player.should_upload_certificate;
    let shouldUploadAthleteCard = player.should_upload_card;
    if (card) {
        if (!shouldUploadCertificate) shouldUploadCertificate = !!card.certificate_required;
        if (!shouldUploadAthleteCard) shouldUploadAthleteCard = !!card.card_upload;
    } else {
        if (!shouldUploadCertificate) {
            shouldUploadCertificate = get(race, 'certificate.type', 0) > 1;
        }
    }

    const hasAdvice = shouldUploadAthleteCard || shouldUploadCertificate;
    if (!hasAdvice) return null;
    return (
        <Card className="gx-card card-warning" title={`${player.first_name} ${player.last_name} | ${race.name}`}>
            {shouldUploadCertificate && <CertificatePlayerAdvice race={race} player={player} tickets={tickets} t={t} />}
            {shouldUploadCertificate && shouldUploadAthleteCard && <hr />}
            {shouldUploadAthleteCard && (
                <AthleteCardPlayerAdvice race={race} player={player} card={card} tickets={tickets} t={t} />
            )}
        </Card>
    );
};

const PostSubscriptionAdvices = ({ competition, tickets, t }) => {    

    return (
        <div>
            {keys(tickets.players).map((key) => {
                const player = tickets.players[key];
                const subEventId = player.raceId || player.sub_event_id;
                const race = find(competition.racesSummary, (r) => r.id === subEventId);
                if (!race || !player) return null;
                return <TicketPostActions key={'tpa_' + key} race={race} player={player} tickets={tickets} t={t} />;
            })}
        </div>
    );
};

class TicketsOrderCompleted extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    encode(s) {
        return encodeURIComponent(s);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const { tickets, match, location } = this.props;

        if (tickets.step !== 'completed') {
            //we don't know the step or we have a prev step
            //could be payment with callback or direct entry

            const pk = getTicketsPrivateKey({ id: match.params.id }, location);

            this.props.dispatch(fetchTicket(match.params.id, pk));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.tickets.step !== nextProps.tickets.step && nextProps.tickets.step !== 'completed') {
            this.props.router.replace(url.eventUrl(this.props.competition) + '/subscribe');
        }
    }

    getFbQuote() {
        const { tickets, competition, t } = this.props;
        const firstNames = map(tickets.players, (pl) => pl.first_name);
        let desc = '',
            count = firstNames.length;
        if (count >= 2) {
            let playerNames = firstNames.slice(0, count - 1).join(',');
            desc = t('joined_the_event_2', { 1: playerNames, 2: firstNames[count - 1], 3: competition.name });
        } else {
            desc = t('joined_the_event_1', { 1: firstNames[0], 2: competition.name });
        }

        return desc;
    }

    renderLinks(tickets, competition, t, location) {
        let _url = url.absUrl(location.basename);
        const fbQuote = this.getFbQuote();

        return (
            <div className="gx-text-center">
                <Link
                    to={`/tickets/${tickets.id}?pk=${getTicketsPrivateKey(tickets)}`}
                    className="gx-btn gx-btn-outline-primary btn-subscribe gx-btn-lg"
                >
                    <FileDoneOutlined />
                    &nbsp;{t('order_summary')}
                </Link>
                <Divider />
                <a
                    rel="noreferrer"
                    href={`https://www.facebook.com/sharer.php?u=${this.encode(_url)}&title=${this.encode(
                        competition.name
                    )}&quote=${this.encode(fbQuote)}`}
                    target={'_blank'}
                    className={'btn btn-ks btn-lg'}
                >
                    <i className="icon-facebook" /> {t('share_subscription_facebook')}
                </a>
                <Divider />
                <Link to="/tickets" className="btn btn-lg ks-btn-outline">
                    <UnorderedListOutlined /> {t('your_subscriptions')}
                </Link>
                <Divider />
                <Link to={url.eventUrl(competition)} className="light" style={{ fontSize: 18, marginBottom: 10 }}>
                    {t('back_to_competition')}
                </Link>

                <div style={{ margin: 20 }}></div>
            </div>
        );
    }

    /**
     * @deprecated
     * @param {*} competition
     * @param {*} tickets
     * @param {*} t
     */
    _renderCertificateAdvice(competition, tickets, t) {
        const { racesSummary } = competition;
        const races = racesSummary.filter((race) => get(race, 'certificate.type') > 1);
        if (races.length) {
            return (
                <>
                    <div className="gx-alert gx-alert-warning">
                        <h3>{t('medical_certificate')}</h3>
                        {races.map((race, index) => {
                            return (
                                <>
                                    <CertificateRaceAdvice
                                        tickets={tickets}
                                        race={race}
                                        t={t}
                                        key={`cert_${race.id}`}
                                    />
                                    {races.length - 1 !== index && <hr />}
                                </>
                            );
                        })}
                    </div>
                    <hr />
                </>
            );
        }
    }

    /**
     * todo check renderLinks on server error
     * @param tickets
     * @param competition
     * @param t
     * @returns {XML}
     */
    renderMainContent(tickets, competition, t, location) {
        if (tickets.serverErrors) {
            return (
                <div className="gx-text-center">
                    <h4 dangerouslySetInnerHTML={{ __html: tickets.payer.errors }}></h4>
                    <hr />
                    {this.renderLinks(tickets, competition, t, location)}
                </div>
            );
        }

        return (
            <>
                <Card className="gx-card" title={t('subscription_successfully_completed')}>
                    <PaymentCompleted
                        gateway={tickets.payment.active}
                        tickets={tickets}
                        payment={tickets.payment}
                        competition={competition}
                        parent="TicketsOrderCompleted"
                    />
                </Card>
                <PostSubscriptionAdvices competition={competition} tickets={tickets} t={t} />
                {this.renderLinks(tickets, competition, t, location)}
            </>
        );
    }

    render() {
        const { t, tickets, competition, location } = this.props;

        const loaded = tickets.step === 'completed' || (!tickets.fetching && tickets.serverErrors);

        return (
            <Col xs={24} className="gx-p-0 gx-p-md-3">
                <Row>
                    <Col xs={24} md={16} className="gx-p-0 gx-pr-md-3" style={{ minHeight: 100 }}>
                        <CircularProgress loading={!loaded} parentClassName="order-form-spinner">
                            {loaded && this.renderMainContent(tickets, competition, t, location)}
                        </CircularProgress>
                    </Col>
                    <Col xs={24} md={8}>
                        <InformationBox competition={competition} />
                        <Divider />
                        <Row>
                            <Assistance
                                assistanceDealerOrganizationTextKey="assistance_buy_dealer_organization"
                                dealerOnly={false}
                                competition={competition}
                            />
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <AdSense
                                    label={t('ads')}
                                    client="ca-pub-2892314018985211"
                                    slot={ADSENSE_SLOTS.EVENT_SIDE_BLOCK}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="container-space gx-xs-pt-4"></div>
                <Row>
                    <Col xs={24} md={24}>
                        <AdSense
                            label={t('ads')}
                            format="autorelaxed"
                            client="ca-pub-2892314018985211"
                            slot={ADSENSE_SLOTS.EVENT_FOOTER_MC}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tickets: state.tickets,
        user: state.user,
    };
};

export default connect(mapStateToProps)(withTranslation(['common'])(withRouter(TicketsOrderCompleted)));
