import { get } from 'lodash'

export const validateGerencianetCard = (tickets, t, callback) => {
    const card = tickets.payment.form;

    window.$gn.checkout.getPaymentToken({
        brand: card.card_brand,
        number: card.number,
        cvv: card.cvc,
        expiration_month: card.exp_month,
        expiration_year: card.exp_year
    }, function(error, response) {
        if (error) {
            
            const { error_description } = error;
            const property = error_description.property ? error_description.property : error.error
            const keyError = gerencianetKeyToKsFormKey(property);

            console.log(error);

            if (card[ keyError ]) {
                callback({
                    [keyError]: { value: card[ keyError ], message: getGerencianetMessage(error_description), attribute: 'gerencianetServerError', state: 'error' }
                });

            } else {
                callback({
                    generic: { value: card[ error.key ], message: error_description, attribute: 'gerencianetServerError', state: 'error' }
                })
            }
        } else {
            const { data } = response;
            callback(null, data.payment_token)
        }
    });

};

const gerencianetKeysMap = {
    brand: 'card_brand',
    number: 'number',
    cvv: 'cvc',
    expiration_month: 'exp_month',
    expiration_year: 'exp_year'
};

const getGerencianetMessage = (description) => {
    return description.message || description;
}

const gerencianetKeyToKsFormKey = (key) => {
    if(key === 'invalid_card_number'){
        key = 'number';
    }
    return get(gerencianetKeysMap,key, null);
};
