import { date } from "util/index";

const reduceRaceSummaries = (summaries) => {    
    var newState = {};
    
    var minExpiration = null;

    if (summaries && summaries.length > 0) {
        summaries.forEach(r => {
            newState[r.id] = {
                ...r.state,
                count: r.subscriptionsCount,
                left: r.subscriptionsLeft,
                limit: r.subscriptionsLimit
            }                        

            if(r.reservationExpireAt){
                const expireDate = date.fromMongoDateToMoment(r.reservationExpireAt);
                
                if(!minExpiration){
                    minExpiration = expireDate.toDate();
                } else if(minExpiration.isAfter(expireDate)){
                    minExpiration = expireDate.toDate();
                }
            }
        });
        if(minExpiration){
            newState.orderExpiration = minExpiration;
        }
    }
    return newState;
}

const INIT_STATE = {};

export default function ticketsLimit(state = INIT_STATE, action) {
    switch (action.type) {
    case 'RECEIVE_COMPETITION':{
        const summaries = action.data.racesSummary;
        return reduceRaceSummaries(summaries);
    }
    case "RECEIVE_TICKET_LIMITS":
        return reduceRaceSummaries(action.data)

    default:
        return state;
    }
}