import { UserOutlined, CalendarOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Popover, Divider } from "antd";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { url } from 'util/index';
import { unsetMorphUser, userSignOutSuccess } from '../../appRedux/actions';
import { unsetMorphUserId } from '../../util/persistence';
import { empAccessUrl } from '../../util/url';

const UserInfo = ({ user, morph }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li><Link className="gx-d-block" to="/profile"><UserOutlined /> {t('profile')}</Link></li>
            <li>
                <a href={empAccessUrl(morph.id)} target="_blank" rel="noreferrer">
                    <CalendarOutlined />&nbsp;{t('manage_events')}
                </a>
            </li>
            <li><Link className="gx-d-block" to="/settings"><SettingOutlined />  {t('settings')}</Link></li>
            <li><Divider style={{ margin: "12px 0" }} /></li>
            <li>
                <Link className="gx-d-block" to="/" onClick={(e) => {
                    e.preventDefault();
                    dispatch(unsetMorphUser())
                    unsetMorphUserId();
                }}>
                    <Avatar src={url.getProfileImage(user.id, '40x40', true) || <UserOutlined />} />
                    <span className="gx-ml-2">{user.first_name}</span>
                </Link>
            </li>
            <li>
                <Link className="gx-d-block" to="/" onClick={(e) => {
                    e.preventDefault();
                    dispatch(userSignOutSuccess())
                    history.push('/')

                }}>
                    <LogoutOutlined />&nbsp;{t('logout')}
                </Link>
            </li>
        </ul>
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
            trigger="click">
            <span className="gx-organization-header-name gx-position-relative gx-d-inline-block gx-pointer" style={{ top: 6 }}>
                {morph.complete_name} <br /><small>{user.first_name}</small>
            </span>
            <Avatar src={url.getProfileImage(morph.id, '200x200', true) || <UserOutlined />}
                className="gx-avatar gx-pointer" alt="" />

        </Popover>
    )
};


export default UserInfo;
