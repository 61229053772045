import React, { useEffect, useState } from 'react'
import { Card, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postActivate } from '../../client/auth';
import { useDispatch } from 'react-redux';
import FormServerErrors from '../../components/Form/FormServerErrors';
import { showFormErrors } from '../../appRedux/actions';
import { Link } from 'react-router-dom';

const VerifyAccountCode = ({ match, t }) => {

    const user = useSelector(({ user }) => user);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [activationState, setActivationState] = useState({});

    const { id, code } = match.params;

    useEffect(() => {    

        setLoading(true);

        postActivate({ id, code })
            .then(res => res.data)
            .then(data => {
                setActivationState(data);
                setLoading(false);
            })
            .catch(err => {
                dispatch(showFormErrors(err.response));
                setLoading(false);
            })
    }, [dispatch, user])    

    return (
        <Card title={t('verify_email')} loading={loading}>
            <FormServerErrors />
            {activationState.success && activationState.active === 1 &&
                <Result
                    status="success"
                    title={t('account_activated')}
                    extra={[
                        <Link key="1" className="ant-btn btn-primary  gx-mt-3" to="/login">
                            {t('goto_login_page')}
                        </Link>
                    ]}
                />}
            {activationState.success && activationState.active === 2 &&
                <Result
                    status="success"
                    title={t('account_activated')}
                    extra={[
                        <Link key="1" className="ant-btn btn-primary  gx-mt-3" to={`/register/${id}`}>
                            {t('complete_profile')}
                        </Link>
                    ]}
                />
            }
        </Card>
    )
}

export default withTranslation()(VerifyAccountCode);