import { setPaymentMethod } from 'appRedux/actions/Tickets';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Cash extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { competition } = this.props;
        this.props.dispatch(setPaymentMethod({ active: "cash", payments: competition.payments }));
    }

    render() {
        const { t } = this.props;

        return (
            <div style={{ padding: 15 }}>
                {t('cash_payment_description')}
            </div>
        )
    }
}


export default connect()(withTranslation(['common'])(Cash))
