/* eslint-disable react/no-string-refs */

import React from 'react';
import IntlMessages from '../../util/IntlMessages';
import { getWiclaxUrl } from '../../util/url';
import { isArray } from 'lodash';

class ContentButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showChangeProfileModal: false,
        };
    }

    handleStandingButtonClick(competition, e) {
        if (competition.hasStandings === false) {
            e.preventDefault();

            this.setState({ showChangeProfileModal: true });
        } else {
            let scroll = 850;

            if (this.refs && this.refs.titleBlock) {
                const rect = this.refs.titleBlock.getBoundingClientRect();
                if (rect) {
                    scroll = rect.top + window.scrollY;
                }
            }

            if (this.state.width < 768) {
                window.scrollTo(0, scroll);
            }
        }
    }

    getPhotosUrl(competiton) {
        if (isArray(competiton) && competiton.length > 0) {
            return competiton.fotop[0];
        } else if (competiton.fotop) {
            return competiton.fotop;
        } else {
            return '#';
        }
    }

    render() {
        const { competition } = this.props;

        const isDisabled = competition.hasStandings === false;

        const hasPhotos = competition.fotop && competition.fotop.length > 0;

        return (
            <div className="">
                {/* <Link disabled={isDisabled} onClick={this.handleStandingButtonClick.bind(this, competition)}
                    className={`ant-btn gx-minw-25 gx-btn-lg btn-subscribe ${!isDisabled ? 'gx-btn-outline-primary' : ''}`}
                    to={`${eventUrl(competition)}/standings`}><IntlMessages id="ranking" />
                </Link> */}
                <a
                    rel="noreferrer"
                    disabled={isDisabled}
                    className={`ant-btn gx-minw-25 gx-btn-lg btn-subscribe ${
                        !isDisabled ? 'gx-btn-outline-primary' : ''
                    }`}
                    target="_blank"
                    href={getWiclaxUrl(competition)}
                >
                    <IntlMessages id="ranking" />
                </a>
                <a
                    rel="noreferrer"
                    disabled={!hasPhotos}
                    href={this.getPhotosUrl(competition)}
                    className={`ant-btn gx-minw-25 gx-btn-lg btn-subscribe ${
                        hasPhotos ? 'gx-btn-outline-primary' : ''
                    }`}
                    target="_blank"
                >
                    <IntlMessages id="photos" />
                </a>
            </div>
        );
    }
}

ContentButtons.defaultProps = {
    fullWidth: false,
};

export default ContentButtons;
