import { refreshCompetitionState } from "appRedux/actions/Competition";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTicketLimits } from '../../../../appRedux/actions';
import { SUBSCRIPTION } from '../../../../constants/Tickets';
import RacesSubscriptionList from "./RacesSubscriptionList";
import './styles/race_list.css';

class TicketsSelector extends Component {

    constructor(props) {
        super(props);
        this.timer = null;
    }

    componentWillUnmount() {        
        clearTimeout(this.timer);
    }

    componentDidMount() {        
        this.timer = setInterval(() => {
            this.props.fetchTicketLimits(this.props.competition._id);
        }, SUBSCRIPTION.REFETCH_TICKETS_LIMIT_INTERVAL)

        this.props.refreshCompetitionState(this.props.competition._id);        
    }

    render() {        
        return (
            <div className="col-sm-12">
                <div className="row" style={{ marginTop: 20 }}>
                    <RacesSubscriptionList {...this.props}/>
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        fetchTicketLimits: compId => dispatch(fetchTicketLimits(compId)),
        refreshCompetitionState: compId => dispatch(refreshCompetitionState(compId))
    }
}


export default connect(null, mapDispatchToProps)(TicketsSelector);
