import React from 'react';
import { APP_NAME } from '../../../constants/App';

export const Claim = ({ t }) => {

    return (
        <div className="ant-col ant-col-xs-24 claim-wrapper background-white">
            <img src={require(`../../../assets/images/logo/keepsporting_16.png`)} style={{ height: 40 }} alt={APP_NAME} />
            <p className="v1 size16">Your sports hub</p>
            <h1 className="h4 v1" style={{ fontWeight: 'bold' }}>
                {t('ks_claim')}
            </h1>
        </div>
    )
};