import { formDataEncode } from '../util/form'
import { buildUrl, getClient } from './base'

export const signInWithEmailAndPassword = (email, password, social) => {
    return getClient().post(buildUrl('/api/access_token'), formDataEncode({
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: email,
        password: password,
        social: social
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const createUserWithEmailAndPassword = ({ email, repeatEmail, password, language, userAgent }) => {
    return getClient().post(buildUrl('api/sign'), formDataEncode({
        email: email,
        email_repeat: repeatEmail,
        password: password,
        language: language,
        registration_agent: userAgent
    }))
}

export const userStatusByRuid = ruid => getClient().get(buildUrl('api/registering/' + ruid));

export const completeUserRegistration = (user) => {
    return getClient().post(buildUrl('api/registration'), formDataEncode(user));
}

export const completeOrganizationRegistration = (organization) => {
    return getClient().post(buildUrl('api/morph'), formDataEncode(organization));
}

export const postResendActivation = (user) => {
    return getClient().post(buildUrl('api/resend-activation'), formDataEncode({ email: user.username }))
}

export const postActivate = ({ id, code }) => {
    return getClient().post(buildUrl('api/activation'), formDataEncode({ id, code }));
}

export const postUpdatePassword = ({ old_password, new_password, confirm_password }) => {
    return getClient().post(buildUrl('api/user/update-password'), formDataEncode({ old_password, new_password, confirm_password }));
}

export const postResetPasswordLink = ({ email }) => {
    return getClient().post(buildUrl('api/reset-password-link'), formDataEncode({ email }));
}

export const postResetPassword = ({ new_password, confirm_password, reset_code }) => {
    return getClient().post(buildUrl('api/reset-password'), formDataEncode({ new_password, confirm_password, reset_code }));
}
