import axios from 'axios';
import each from 'lodash';
import { CLIENT_TIMEOUT } from '../constants/App';
import { formDataEncode } from '../util/form';
import { getAuthToken } from '../util/persistence';
import { getSafeISOLanguage } from '../lngProvider/i18n';

export const getClient = ({ headers = {}, timeout = CLIENT_TIMEOUT * 1000 } = {}) => {

    const token = getAuthToken();
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: timeout,
        headers: headers
    });
    return instance;
}

export const getUploaderConfig = (morphId = null) => {

    const language = getSafeISOLanguage(localStorage.getItem('i18nextLng'));

    const qs = morphId ? `${formDataEncode({ morph: morphId, locale: language })}` : formDataEncode({ locale: language });

    return {
        action: `${process.env.REACT_APP_API_BASE_URL}/api/image/profile/upload?${qs}`,
        headers: {
            Authorization: `Bearer ${getAuthToken()}`,
        }
    }
}

export const reduceFormData = (form) => {
    each(form, (field, k) => {
        if (field.value) {
            form[k] = field.value;
        } else {
            delete form[k];
        }
    });

    return form;
};

/**
 * build uri for api
 * todo add timezone
 * @param {*} uri 
 * @param {*} relations 
 * @param {*} queryParams 
 */
export const buildUrl = (uri, queryParams = {}, relations = [], time = true) => {
    if (relations.length > 0) {
        queryParams['with'] = relations.join(',');
    }

    const language = localStorage.getItem('i18nextLng');

    if (language) {
        queryParams['locale'] = getSafeISOLanguage(language);
    }

    if (time) {
        queryParams['t'] = Date.now();
    }

    return `${uri}?${formDataEncode(queryParams)}`;
}