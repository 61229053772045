import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PurchasableEvent from './Purchasable';
import { } from './styles/details.css';

const Details = ({ t }) => {

    const competition = useSelector(s => s.competition.data);    

    if (competition.scraped === 1) {
        //return <CompetitionScrapedDetails {...this.props} />
    } else {
        return <PurchasableEvent competition={competition} t={t} />
    }
}

export default withTranslation(['common', 'competitions'])(Details);