/**
 * Created by ruzz on 25/02/16.
 */


import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '../TextField';

class CustomInput extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            value : ""
        }
    }

    componentDidMount() {}

    handleChange(e){
        var v = e.target.value;

        this.setState({
            value:v
        });
        this.props.cb(v, this.props.field.name);
    }

    render() {
        
        const { field, value, validationState } = this.props;        

        var label = field.label + ((field.required === true) ? ' *' : '');
        return (
            <TextField 
                name={field.name} 
                type="text" 
                label={label} 
                value={value} 
                onChange={this.handleChange.bind(this)} 
                validationState={validationState} />
        )
    }
}

export default withTranslation()(CustomInput)
