import React, { useState } from 'react'
import { Card, Button, Form, Input, message, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { postResetPassword } from '../../client/auth';
import { useDispatch } from 'react-redux';
import FormServerErrors from '../../components/Form/FormServerErrors';
import { showFormErrors } from '../../appRedux/actions';
import { Link } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { push } from 'react-router-redux';


const FormItem = Form.Item;

const VerifyAccountCode = ({ match, t }) => {

    const dispatch = useDispatch();

    const resetCode = match.params.code;

    const [loading, setLoading] = useState(false);

    const handleSubmitSuccess = (values) => {
        setLoading(true)

        postResetPassword({ ...values, reset_code: resetCode })
            .then(r => r.data)
            .then(() => {
                setLoading(false);
                message.success(t('default_form_success'));
                dispatch(push('/login'));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(showFormErrors(err.response))
            })
    }

    const handleSubmitError = () => { }

    return (
        <Card title={t('verify_email')}>
            <FormServerErrors className="gx-mb-3" timeout={5000}/>
            <Row>
                <Col xs={24} lg={12}>
                    <Form
                        layout="vertical"
                        onFinish={handleSubmitSuccess}
                        onFinishFailed={handleSubmitError}
                        className="gx-signup-form gx-form-row0">
                        <FormItem
                            label={t('profile:new_password')}
                            name="new_password"
                            rules={[
                                { required: true, message: t('common:form_error_required') },
                                { min: 5, message: '' }
                            ]}
                        >
                            <Input.Password placeholder={t('common:password')} />
                        </FormItem>
                        <FormItem
                            label={t('profile:confirm_password')}
                            name="confirm_password"
                            rules={[
                                { required: true, message: t('common:form_error_required') },
                                { min: 5, message: '' },
                            ]}
                        >
                            <Input.Password placeholder={t('common:password')} />
                        </FormItem>
                        <FormItem >
                            <Button loading={loading} type="primary" className="gx-mb-0" htmlType="submit">
                                <SendOutlined /> {t('send')}
                            </Button>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
            <Link className="gx-d-block gx-mt-3" to="/login">{t('common:login')}</Link>
        </Card>
    )
}

export default withTranslation(['common', 'profile'])(VerifyAccountCode);