import { Card } from "antd";
import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CardDescriptions, { CardDescriptionsItem } from "../../../../../components/Descriptions/CardDescription";

class StripeData extends Component {


    renderHeader(payment, t) {
        return <span>{t('pay_with_' + payment.active)}</span>
    }

    renderItem(title, value) {
        return (
            <div className="col-sm-6 xs-mt-10 panel-item">
                <div className="title">{title}</div>
                <div className="value">{value}</div>
            </div>
        )
    }

    getHiddenNumber(number) {
        if (!number) return '**** **** **** ****';
        return '**** **** **** ' + number;
    }

    render() {

        const { t,  payment } = this.props;
        const { form } = payment;

        if (!form) {
            return <div>{t('not_provided')}</div>
        }

        return (

            <Card className="gx-card" title={this.renderHeader(payment, t)}>
                <CardDescriptions column={1} className="ks-descriptions">
                    <CardDescriptionsItem label={t('card_holder')} >{form.card_holder}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('card_number')} >{this.getHiddenNumber(form.number)}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('card_brand')} >{get(form, 'brand', '-').toUpperCase()}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('card_expiration')} >{`${form.exp_month}/${form.exp_year}`}</CardDescriptionsItem>
                </CardDescriptions>
            </Card>

        )
    }
}

export default withTranslation(['common'])(StripeData)
