import { Select } from 'antd';
import axios from 'axios';
import config from 'config';
import { extend, map, uniq, debounce } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class SelectLocation extends React.Component {
    constructor(props) {
        super(props);        

        this.state = {
            value: { value: props.value, label: props.label },
            options: [],
        };
    }

    static getLabel(response) {
        var label = response.name;

        if (response.parents) {
            var parentsName = map(response.parents, 'name');

            //add
            parentsName.unshift(label);
            parentsName = uniq(parentsName);

            label = parentsName.join(', ');
        }

        return label;
    }

    getLocationById(value, callback = null) {
        const request = {
            method: 'GET',
            url: config.apiUrl + '/location/' + value,
            headers: {
                Authorization: 'Basic 426388932ea16be098b1ebd7d4bd5ddb',
            },
        };

        axios(request)
            .then((res) => res.data)
            .then((json) => {
                let selectedOption = {
                    value: json.woeid,
                    label: SelectLocation.getLabel(json),
                    country: json.country,
                    coords: json.coords,
                    placetype: json.placetype,
                };

                if (callback) {
                    selectedOption = callback(selectedOption);
                }

                this.setState({ value: { value: selectedOption.value, label: selectedOption.label } });
            })
            .catch((err) => {
                console.log('getLocationByIdError', err);
            });
    }

    componentDidMount() {
        const { forceValue, value, onInit } = this.props;

        if (forceValue && value) {
            this.getLocationById(value, onInit);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value === '' && prevProps.value !== '') {
            this.setState({ value: { value: this.props.value, label: this.props.label } });
        }
    }

    onSearch = debounce((value) => {
        this.debouncedOnSearch(value);
    }, 200);

    debouncedOnSearch = (value) => {
        const { i18n } = this.props;
        let searchParam;
        switch (this.props.searchScale) {
        case 'town': //town,city only
            searchParam = 0;
            break;
        case 'all':
            searchParam = 1;
            break;
        case 'country':
            searchParam = 12;
            break;
        default:
            break;
        }

        var request = extend({}, this.options, {
            method: 'post',
            url: config.apiUrl + '/location?locale=' + i18n.language, // + value + '/1/40/' + searchParam,
            data: {
                term: value,
                page: 1,
                size: 40,
                place: searchParam,
                country: this.props.countryIp,
            },
            headers: {
                Authorization: 'Basic 426388932ea16be098b1ebd7d4bd5ddb',
            },
        });

        axios(request)
            .then((res) => res.data)
            .then((json) => {
                const opts = map(json.results, (r) => {
                    return {
                        value: r.woeid,
                        label: SelectLocation.getLabel(r),
                        country: r.country,
                        coords: r.coords,
                    };
                });
                this.setState({ options: opts });
            })
            .catch(function (err, msg) {
                console.log(err, msg);
            });
    };

    onSelect = (value, selected) => {
        this.setState({ value: { value: value, label: selected.children } });
        this.props.onSelect(selected.option);
    };

    render() {
        let { t, validationState, className, hideLabel, disabled, allowClear, onChange, fieldLabel, autoComplete } =
            this.props;

        const { options, value } = this.state;

        return (
            <div
                className={`${className} ${validationState === 'error' && 'has-error'} ${
                    validationState === 'success' && 'has-success'
                }`}
            >
                {!hideLabel && (
                    <label htmlFor={this.props.name} className={`control-label ${this.props.labelClass}`}>
                        {t(fieldLabel) + (this.props.mandatory === false ? '' : ' *')}
                    </label>
                )}
                <Select
                    className="gx-d-block gx-w-100"
                    filterOption={false}
                    labelInValue={true}
                    showSearch={true}
                    placeholder={t('search') + `...`}
                    value={value}
                    onSelect={this.onSelect}
                    onSearch={this.onSearch}
                    onChange={onChange}
                    notFoundContent={null}
                    disabled={disabled}
                    allowClear={allowClear}
                    autoComplete={autoComplete}
                >
                    {options.map((o) => {
                        return (
                            <Option key={o.value} value={o.value} option={o}>
                                <div>
                                    <i
                                        className={`${o.country ? `list-flag flag-${o.country.toLowerCase()}` : ''}`}
                                    ></i>
                                    {o.label}
                                </div>
                            </Option>
                        );
                    })}
                </Select>

                <span className="help-block">{this.props.help}</span>
            </div>
        );
    }
}

SelectLocation.defaultProps = {
    className: '',
    forceValue: false,
    hideLabel: false,
    searchScale: 'town',
    fieldLabel: 'location',
    countryIp: '',
    onSelect: () => {},
    disabled: false,
    autoComplete: 'off',
};

export default withTranslation()(SelectLocation);
