import { keys, first, get } from 'lodash';

export const getFirstCatchedErrorMessage = (e) => {
    const errors = get(e, 'response.data.errors', null);
    if (errors && keys(errors).length > 0) {
        const firstMessage = errors[first(keys(errors))];
        if (firstMessage && firstMessage.length > 0) {
            return firstMessage[0];
        }
    }
    return null
}