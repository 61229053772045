// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_FORM_ERRORS = 'SHOW_FORM_ERRORS';
export const HIDE_FORM_ERRORS = 'HIDE_FORM_ERRORS';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';

//User
export const SET_REGISTERING_USER = "SET_REGISTERING_USER";
export const UPDATE_PROFILE_TIME = "UPDATE_PROFILE_TIME";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

//Morph
export const SET_MORPH_USER = 'SET_MORPH_USER';
export const SET_NEW_MORPH_USER = 'SET_NEW_MORPH_USER';
export const UNSET_MORPH_USER = "UNSET_MORPH_USER";
export const UPDATE_MORPH_USER = "UPDATE_MORPH_USER";

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';