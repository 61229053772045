import { get, includes } from 'lodash';
import Promise from 'promise-polyfill';
import { push } from 'react-router-redux';
import { getCompetitionPaymentsFeesForCalculation, getProviderBySlugFromCompetitionPayments } from 'util/Competition';
import {
    restoreTicketsStore,
    deleteReservation as processDeleteReservation,
    getTicket,
    processCompetitionOrder,
    processRenewBoleto,
    processTicketsReservation,
    validateDiscountCode,
} from '../../client/tickets';
import { getCompetitionUrl } from '../../util/url';
import { storeTicketsPrivateKey } from '../../util/Tickets';

/**
 *
 * @param {object} data
 * @param {object} data.order
 * @param {object} data.user
 * @returns {{type: string, order: (number|*|Array|tickets|{$apply}|initialState.tickets), user}}
 */
export function cleanTickets() {
    return { type: 'CLEAN_TICKETS' };
}

export function setLocalTicketsOrder(data) {
    return {
        type: 'SET_TICKETS_ORDER',
        ...data,
    };
}

function receiveTicketsOrderResponse() {
    return {
        type: 'RECEIVE_TICKETS_ORDER_RESPONSE',
    };
}

export function setTicketsOrder(data) {
    return (dispatch) => {
        dispatch(setLocalTicketsOrder(data));

        const order = {};

        data.order.map((t) => {
            if (!order[t.raceId]) order[t.raceId] = { quantity: 0, raceId: t.raceId };
            order[t.raceId].quantity += t.quantity;
        });

        const reservation = { id: data.id, order: order };
        return new Promise((res, rej) => {
            processTicketsReservation(reservation)
                .then((response) => {
                    dispatch(receiveTicketsOrderResponse());
                    res(response.data);
                })
                .catch((xhr) => {
                    if (xhr.response) {
                        try {
                            //TODO FIX
                            rej(JSON.parse(xhr.response));
                        } catch (e) {
                            rej({ message: 'generic_error' });
                        }
                    }
                    rej({ message: 'generic_error' });
                });
        });
    };
}

export function deleteReservation(id) {
    return () => {
        return processDeleteReservation(id).then(console.log).catch(console.log);
    };
}

export function updateTicketsOrder(data) {
    return {
        type: 'UPDATE_TICKETS_ORDER',
        ...data,
    };
}

export function updatePayerInitState(user) {
    return {
        type: 'UPDATE_PAYER_INIT_STATE',
        user: user,
    };
}

export const setPaymentMethod = ({ active, user, payments }) => {
    //console.log("ACTION@setPaymentMethod", payments);

    const provider = getProviderBySlugFromCompetitionPayments(payments, active);

    const fees = getCompetitionPaymentsFeesForCalculation(payments, provider);

    return {
        type: 'SET_PAYMENT_METHOD',
        slug: active,
        user: user || null,
        sellingPoint: get(provider, 'sellingPoint', false),
        resetKsFee: get(provider, 'resetKsFee', false),
        fees: fees,
    };
};

/**
 *
 * @param {object} data
 * @param {string} data.field
 * @param {string} data.value
 * @returns {{type: string}}
 */
export const updatePaymentForm = (data) => {
    return {
        type: 'UPDATE_PAYMENT_FORM',
        ...data,
    };
};

export const setStripePaymentMethodSuccess = ({ card }) => {
    return {
        type: 'SET_STRIPE_PAYMENT_METHOD_SUCCESS',
        card: card,
    };
};

export const processStripeScaError = (error) => {
    return {
        type: 'SET_STRIPE_SCA_ERROR',
        error: error,
    };
};

export function updatePayerForm(data) {
    return {
        type: 'UPDATE_PAYER_FORM',
        ...data,
    };
}

export function updateTeamsForm(data) {
    return {
        type: 'UPDATE_TEAMS_FORM',
        ...data,
    };
}

/***
 * private function
 * @param data
 * @returns {{type: string}}
 */
function updatePlayerFormField(data) {
    return {
        type: 'UPDATE_PLAYER_FORM',
        ...data,
    };
}

/***
 *
 * @param data
 * @returns {function(*)}
 */
export function updatePlayerForm(data) {
    return (dispatch) => {
        dispatch(updatePlayerFormField(data));
        if (data.addition) {
            dispatch(updateTicketsOrder(data));
        }
    };
}

export function payerFormError(error) {
    return {
        type: 'PAYER_FORM_ERROR',
        error: error,
    };
}

/**
 *
 * @param {object} errors
 * @param {object} errors[race_id + index] form errors
 * @returns {function(*)}
 */
export function teamsFormErrors(errors) {
    return (dispatch) => {
        dispatch({
            type: 'TEAMS_FORM_ERRORS',
            errors: errors,
        });
        return Promise.resolve();
    };
}

/**
 *
 * @param {object} errors
 * @param {object} errors[race_id + index] form errors
 * @returns {function(*)}
 */
export function playersFormErrors(errors) {
    return (dispatch) => {
        dispatch({
            type: 'PLAYERS_FORM_ERRORS',
            errors: errors,
        });
        return Promise.resolve();
    };
}

export function paymentFormError(error) {
    return (dispatch) => {
        dispatch({
            type: 'PAYMENT_FORM_ERROR',
            error: error,
        });
        return Promise.resolve();
    };
}

export function formServerErrors(errors) {
    return {
        type: 'FORM_SERVER_ERRORS',
        ...errors,
    };
}

/**
 *
 * @param {object} data
 * @param {string} data.from
 * @param {string} data.to
 * @returns {{type: string}}
 */
export function copyOrderForm(data) {
    return {
        type: 'COPY_ORDER_FORM',
        ...data,
    };
}

export function cleanPlayerErrors(key) {
    return {
        type: 'CLEAN_PLAYER_ERRORS',
        key,
    };
}

export function cleanTeamErrors(key) {
    return {
        type: 'CLEAN_TEAM_ERRORS',
        key,
    };
}

export function cleanPayerErrors() {
    return { type: 'CLEAN_PAYER_ERRORS' };
}

export function cleanErrors() {
    return { type: 'CLEAN_ERRORS' };
}

function receiveProcessedOrder(response) {
    return {
        type: 'RECEIVE_PROCESSED_ORDER',
        data: response,
        receivedAt: Date.now(),
    };
}

export function receivePayUForm(form) {
    return {
        type: 'RECEIVE_PAYU_FORM',
        form: form,
    };
}

export function receiveStripeRequiredAction(payment) {
    return {
        type: 'RECEIVE_STRIPE_REQUIRED_ACTION',
        data: payment,
    };
}

export function receiveGnInstallments(data) {
    return {
        type: 'RECEIVE_GN_INSTALLMENTS',
        data: data.installments || [],
    };
}

export function fetchingGnInstallments() {
    return {
        type: 'FETCHING_GN_INSTALLMENTS',
    };
}

export function processGnInstallments(checkout, total, cardBrand) {
    return (dispatch) => {
        if (!checkout || !checkout.getInstallments) {
            return null;
        }

        dispatch(fetchingGnInstallments());
        checkout.getInstallments(total, cardBrand, (err, res) => {
            if (err) {
                console.log('processGnInstallments@error', err);
            }

            const data = !err ? res.data : [];
            dispatch(receiveGnInstallments(data));
        });
    };
}

export function processOrder(tickets) {
    return (dispatch) => {
        dispatch(fetchingTicketsStore());
        return processCompetitionOrder(tickets)
            .then((res) => res.data)
            .then((data) => {
                
                const paymentKey = get(data, 'payment.key', '');
                storeTicketsPrivateKey(tickets.id, paymentKey);
                if (data.payment && includes(['paypal', 'pagadito'], data.payment.active)) {
                    window.location = data.payment.redirectUrl;
                } else if (data.payment && data.payment.active === 'payu') {
                    dispatch(receivePayUForm(data.payment.form));
                } else if (data.payment && data.payment.active === 'stripe' && data.payment.action) {
                    dispatch(receiveStripeRequiredAction(data.payment));
                } else {
                    dispatch(receiveProcessedOrder(data));
                    dispatch(
                        push(
                            `${getCompetitionUrl({ _id: tickets.compId }, false)}/subscribe/order/${
                                tickets.id
                            }/completed?pk=${paymentKey}`
                        )
                    );
                }
            })
            .catch((xhr) => {
                let errors = null;
                if (xhr.response) {
                    errors = get(xhr, 'response.data.errors', null);
                }

                if (!errors) {
                    errors = {
                        //error key absent
                        payment: { '': ['generic_error'] },
                    };
                }

                dispatch(formServerErrors(errors));
                dispatch(push(`${getCompetitionUrl({ _id: tickets.compId }, false)}/subscribe/order/${tickets.id}`));
            });
    };
}

export function receiveTicketsStore(response) {
    return {
        type: 'RESTORE_TICKETS_STORE',
        data: response,
    };
}

/**
 * generic fetching data boolean variable
 * @returns {{type: string}}
 */
export function fetchingTicketsStore() {
    return {
        type: 'FETCHING_TICKETS_STORE',
    };
}

/**
 *
 * @param id
 * @returns {function(*)}
 */
export function restoreTickets(id) {
    return (dispatch) => {
        dispatch(fetchingTicketsStore());
        return restoreTicketsStore(id)
            .then((response) => {
                dispatch(receiveTicketsStore(response.data));
            })
            .catch((err) => {
                console.log('cannot restore tickets', err);
            });
    };
}

/***
 *
 * @param response
 * @returns {{type: string}}
 */
export function receiveDiscountValidation(response) {
    return {
        type: 'RECEIVE_DISCOUNT_VALIDATION',
        ...response,
    };
}

export function processDiscount(compId, code) {
    return (dispatch) => {
        dispatch(fetchingTicketsStore());
        return validateDiscountCode(compId, code)
            .then((response) => {
                dispatch(receiveDiscountValidation(response.data));
            })
            .catch((err) => {
                console.log('processDiscount', err);
            });
    };
}

export function resetDiscount() {
    return {
        type: 'RESET_DISCOUNT',
    };
}

export function requestTicket(params) {
    return {
        type: 'REQUEST_TICKET',
        data: params,
    };
}

function receiveTicket(data) {
    return {
        type: 'RECEIVE_TICKET',
        data: data,
        receivedAt: Date.now(),
    };
}

function requestTicketError(params) {
    return {
        type: 'REQUEST_TICKET_ERROR',
        ...params, //msg, status_code
    };
}

export function fetchTicket(id, pk = null) {
    return (dispatch) => {
        dispatch(requestTicket(id));
        return getTicket(id, [], { pk: pk })
            .then((res) => res.data)
            .then((data) => {
                dispatch(receiveTicket(data));
            })
            .catch((err) => {
                if (err.response) {
                    try {
                        const serverErr = JSON.parse(err.response);
                        dispatch(requestTicketError(serverErr));
                    } catch (e) {
                        window.location.href = '/tickets';
                    }
                } else {
                    window.location.href = '/tickets';
                }
            });
    };
}

function updateBoletoPayoff(response) {
    return {
        type: 'UPDATE_BOLETO_PAYOFF',
        payoff: response.payment,
    };
}

/**
 * @deprecated
 * @param {*} params
 * @returns
 */
export function renewBoleto(params) {
    return (dispatch) => {
        return processRenewBoleto(params)
            .then((res) => res.data)
            .then((response) => {
                dispatch(updateBoletoPayoff(response));
                return response.payment;
            })
            .catch((err, msg) => {
                console.log(err, msg);
            });
    };
}
