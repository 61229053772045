import { DatePicker } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

const KsDatePicker = ({ onDateChange, className, validationState, day, month, year, name, label, required, help, disabledDate, t }) => {

    const [pickerMode, setPickerMode] = useState('year');

    label = t(label) || t(name);

    if (required) {
        label += ' *';
    }

    const selectedDate = day && month && year ? moment(`${year}-${month}-${day}`, 'YYYY-MM-DD') : null;   

    return (
        <div className={`${className} ${validationState ? `has-${validationState}` : ''}`} >
            <label>{label}</label>

            <DatePicker
                disabledDate={disabledDate}
                value={selectedDate}
                defaultPickerValue={moment().add(-20, 'years')}
                format="DD-MM-YYYY"
                style={{ borderRadius: 0 }}
                className={'gx-w-100'}
                onChange={(value) => {
                    if (value === null) {
                        setPickerMode('year');
                    }
                    onDateChange(value);
                }}                
                mode={pickerMode}
                onPanelChange={(value, mode) => {
                    setPickerMode(mode)
                }}
                showToday={false}
            />


            {help && <div className="help-block">{help}</div>}
        </div>
    )
}

KsDatePicker.defaultProps = {
    disabledDate: null
};

export default withTranslation()(KsDatePicker)
