import config from 'config';
import { get, keys } from 'lodash';
import moment from 'moment';
import { string } from 'util/index';
import { GN_DEFAULT_CARD_BRAND, GN_DEFAULT_INSTALLMENTS } from '../../../constants/Tickets';
import { roundNumber } from '../../../util/price';

export const birthdayDefault = moment().subtract(18, 'years');

const DEBUG_PAYER_DEFAULT_STATE = {
    email: '',
    first_name: '',
    last_name: '',
    citizenship: '',
    //gender: 'm',
    //birthday: birthdayDefault.format('YYYY-MM-DD'),
    //phone: "",//null,
    //location: null,
    //location_label: '',
    errors: {},
};

// const DEBUG_PLAYERS_INIT_STATE = {
//     mpwxmxTnJfKuFXiAd_1: {
//         email: 'roberto.ruzzini@gmail.com',
//         first_name: 'Roberto',
//         last_name: 'Ruzzini',
//         gender: 'm',
//         birthday: birthdayDefault.format('YYYY-MM-DD')
//     }
// };

/**
 * @set very initial state
 */
export function ticketsInitState() {
    return {
        steps: {
            current: 1,
            status: ['process', 'wait', 'wait', 'wait'],
        },
        discount: {
            valid: null,
        },
    };
}

const PAYER_DEFAULT_STATE = {
    email: '',
    repeat_email: '',
    first_name: '',
    last_name: '',
    citizenship: '',
    errors: {},
};

function boletoInitState(user) {
    let state = {
        full_name: '',
        city: '', //location, //set when data is already store, not when payment is resume via ajax call
        street: '',
        street_number: '',
        phone_number: '', //5144916523
        district: '',
        province: '', //SP
        cap: '',
        fiscal_code: '', //94271564656
    };

    if (user) {
        state.full_name = user.first_name && user.last_name ? `${user.first_name}  ${user.last_name}` : '';
        state.location = user.location ? user.location.name : ''; //location, //set when data is already store, not when payment is resume via ajax call
    }

    return state;
}

function getPlayerDefaultState(order) {
    return {
        email: '', //'roberto.ruzzini@gmail.com',
        first_name: '', //'roberto',
        last_name: '',
        gender: '',
        birthday: null, //birthdayDefault.format('YYYY-MM-DD'),
        errors: {},
        _orderId: order.id,
        raceId: order.raceId,
        //custom fields
        // custom_field_4YYF6W57kqAfKBdDB: "1",
        // custom_field_CZhNnppahDG3a7vuG: "2",
        // custom_field_PAKucLo6o2GzmdCQT: "ghkvkgfvj"
    };
}

export function payerInitState(user) {
    const test = false;

    if (user) {
        return {
            email: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            citizenship: user.citizenship,
            gender: user.gender, //to copy on player form
            birthday: user.birthdate, //to copy on player form
            errors: {},
        };
    }

    return config.debug && test ? DEBUG_PAYER_DEFAULT_STATE : PAYER_DEFAULT_STATE;
}

export function playersInitState(orders) {
    const players = {};

    orders.forEach((raceOrder) => {
        players[raceOrder.id] = getPlayerDefaultState(raceOrder);
    });

    return players;
}

export function paymentInitState(slug, sellingPoint = false, resetKsFee = false, user = null) {
    switch (slug) {
    case 'stripe':
        return {
            active: slug,
            sellingPoint: sellingPoint,
            resetKsFee: resetKsFee,
            form: {
                card_holder: '',
                /*number: '',
                            cvc: '',
                            exp_month: '',
                            exp_year: ''*/
            },
            errors: {},
        };

    case 'card-gerencianet':
        return {
            active: slug,
            sellingPoint: sellingPoint,
            resetKsFee: resetKsFee,
            form: {
                card_brand: GN_DEFAULT_CARD_BRAND,
                number: '', //'4242424242424242',
                cvc: '',
                exp_month: '',
                exp_year: '',
                birthday: birthdayDefault.format('YYYY-MM-DD'),
                city: '', //"11",//location, //set when data is already store, not when payment is resume via ajax call
                street: '', //'11',
                street_number: '', ///'11',
                phone_number: '', //'5144916523',
                district: '', //'11',
                province: '', //'SP',
                cap: '', //'111111111',
                fiscal_code: '', //'94271564656',
                installments: GN_DEFAULT_INSTALLMENTS,
            },
            installments: [],
            installmentsLoading: false,
            errors: {},
        };

    case 'boleto':
        return {
            active: slug,
            sellingPoint: sellingPoint,
            resetKsFee: resetKsFee,
            form: boletoInitState(user),
            errors: {},
        };
    case 'paypal':
    case 'bank':
    case 'payu':
        return { active: slug, sellingPoint: sellingPoint, resetKsFee: resetKsFee };

    default:
        return {
            active: slug || null,
            sellingPoint: sellingPoint || false,
            resetKsFee: resetKsFee || false,
        };
    }
}

export function generateOrderId(ksOrgId) {
    const dateRef = moment().format('YYMMDD');

    const rs = string.randomString(10);

    return rs + '-' + ksOrgId + '-' + dateRef;
}

/**
 *
 * @param order
 * @param ksFee object
 * @param ksFee.percent number ex 600 for 6 %
 * @param ksFee.fixed number ex 60 for 0.6 €
 * @param ksFee.min number (optional) ex 500 for 5.0 €
 * @param ksFee.feeToOrg boolean
 */
export function recalcOrderTotal(order, ksFee) {
    let values = {
        additionsTotal: 0,
        fee: 0,
        total: 0,
    };

    const basePrice = order.price;

    keys(order.additions).forEach((k) => {
        values.additionsTotal += parseInt(order.additions[k].value);
    });

    const price = basePrice + parseInt(values.additionsTotal);

    values.fee = price * (ksFee.percent / 10000) + ksFee.fixed;

    const minFee = get(ksFee, 'min', 0);

    if (minFee > values.fee) {
        values.fee = minFee;
    }

    values.total = price + (!ksFee.feeToOrg ? values.fee : 0);

    return values;
}

export function getTicketsTotal(tickets) {
    let total = 0,
        discount = 0;
    tickets.order.forEach((order) => {
        total += order.total;
    });

    if (tickets.discount && tickets.discount.valid) {
        discount = Math.round((total * tickets.discount.percent) / 10000) + tickets.discount.fixed;
    }

    return roundNumber(total - discount, 0);
}
