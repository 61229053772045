import { SET_MORPH_USER, UNSET_MORPH_USER, UPDATE_MORPH_USER, SET_NEW_MORPH_USER } from '../../constants/ActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_NEW_MORPH_USER:
    case SET_MORPH_USER:
        return { 
            ...action.payload,
            managers: action.payload.managers ? action.payload.managers.map(m => {
                delete m.morphs;//fix api
                return m;
            }) : []
        };

    case UNSET_MORPH_USER:
        return INIT_STATE;

    case UPDATE_MORPH_USER:
        return {
            ...state,
            ...action.payload,
            updated_at: Date.now()
        }

    default:
        return state;
    }
}
