export const GN_DEFAULT_CARD_BRAND = 'mastercard';
export const GN_DEFAULT_INSTALLMENTS = '1';

export const SUBSCRIPTION = {
    MAX_TICKETS: 15,
    SHOULD_SHOW_LEFT_TICKETS: 30,
    REFETCH_TICKETS_LIMIT_INTERVAL: 10 * 1000, //millis
    PURCHASE_EXPIRE_TIME: 10 * 60 * 1000, //millis same as server
    PURCHASE_EXPIRE_TIMER_INTERVAL: 1000, //millis
    ATHLETE_CARDS: { FIDAL: 'fidal', RUNCARD: 'runcard', FITRI: 'fitri' }
}

