import { ShareAltOutlined } from '@ant-design/icons';
import { Affix, Col, Divider, Row, Popover } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdSense from '../../../components/AdSense';
import SubscriptionButton from '../../../components/Buttons/SubscriptionButton';
import Attachments from '../../../components/Competition/Attachments';
import EventDescription from '../../../components/Competition/EventDescription';
import LocationMap from '../../../components/Location/LocationMap';
import { ADSENSE_SLOTS } from '../../../config';
import IntlMessages from '../../../util/IntlMessages';
import Gallery from './Gallery';
import EventHeader, { Cover } from './Header';
import InformationBox from './InformationBox';
import RacesList from './RacesList';
import SocialShareLinks from './SocialShareLinks';
import SocialUsers from './SocialUsers';
import Sponsor from './Sponsor';

const PurchasableEvent = ({ competition, t }) => {
    
    const [isHeaderAffixed, setIsHeaderAffixed] = useState(false);

    const history = useHistory();

    const currentURL = `${process.env.REACT_APP_URL}${history.location.pathname}`;    

    return (
        <div className="">
            <Row style={{ boxShadow: 'none' }} className="gx-card card-top-radius gx-pb-3 gx-mb-0">
                <Col className="event-cover" xs={24} lg={17}>
                    <Cover competition={competition} />
                </Col>
                <Col className="event-header" xs={24} lg={7} >
                    <EventHeader competition={competition} t={t} />
                </Col>
            </Row>

            <Affix offsetTop={0} onChange={affixed => setIsHeaderAffixed(affixed)}>
                <Row className={`gx-pt-5 competition-details-header ${isHeaderAffixed ? `gx-card event-header-affixed` : `gx-bg-white`}`}>
                    {isHeaderAffixed && <Col xs={24} className="gx-text-center"> <div className="gx-mb-3 gx-ml-2 gx-fs-lg gx-text-primary">{competition.name}</div></Col>}
                    <Col xs={8} sm={12} md={16} lg={17} >
                        <ul className="actions gx-d-inline-block gx-ml-4 gx-mt-2">
                            <li className="inlineblock" >
                                <Popover trigger="click" placement="right" content={<SocialShareLinks competition={competition} url={currentURL} />} title={null}>
                                    <ShareAltOutlined />
                                </Popover>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={16} sm={12} md={8} lg={7} className="gx-text-right  gx-pr-3">
                        <SubscriptionButton competition={competition} className="gx-w-75" />
                    </Col>
                    <Divider className={isHeaderAffixed ? `gx-d-none` : `gx-d-flex`} />
                </Row>
            </Affix>

            <Row className="gx-p-3 gx-card-effect-bottom">
                <RacesList competition={competition} t={t} />
            </Row>

            <Row className="gx-mt-3">
                <Col xs={24} md={16} className="gx-pr-0 gx-pr-md-3">
                    {competition.sponsors && competition.sponsors.length > 0 &&
                        <Sponsor sponsors={competition.sponsors} t={t} />
                    }
                    <EventDescription description={competition.description} />
                    {competition.attachments && competition.attachments.length > 0 &&
                        <Attachments attachments={competition.attachments} />
                    }
                </Col>
                <Col xs={24} md={8}>
                    <InformationBox competition={competition} />
                    <AdSense
                        label={<IntlMessages id={'ads'} />}
                        client="ca-pub-2892314018985211"
                        className="min-height-100"
                        slot={ADSENSE_SLOTS.EVENT_SIDE_BLOCK} />
                </Col>
                <Col xs={24}>
                    {/* {competition.routes && competition.routes.map(r => {
                        //return <Route key={"race_" + r.id} route={r} />                       
                    })} */}
                </Col>
                <Col xs={24}>
                    <SocialShareLinks competition={competition} url={currentURL} />
                </Col>

                <Col xs={24}>
                    <Gallery competition={competition} key="competition_gallery" />
                </Col>

                <Col xs={24}>
                    <SocialUsers competition={competition} />
                </Col>

                <Col xs={24} className="gx-text-center">
                    <LocationMap event={competition} width="100%" height={250} showMap={false} />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <AdSense
                        label={<IntlMessages id="ads" />}
                        format="autorelaxed"
                        client="ca-pub-2892314018985211"
                        slot={ADSENSE_SLOTS.EVENT_FOOTER_MC} />
                </Col>
            </Row>
        </div>
    )
}


export default PurchasableEvent;