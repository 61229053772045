import { getCompetition, getCompetitionState } from "../../client/event";
import { fetchError } from "./Common";

function requestCompetition(compId) {
    return {
        type: 'REQUEST_COMPETITION',
        compId
    }
}

function receiveCompetition(compId, json) {
    return {
        type: "RECEIVE_COMPETITION",
        compId,
        data: json,
        receivedAt: Date.now()
    }
}


export function fetchCompetition(eventId, { scraped, orderId }) {

    return (dispatch) => {
        dispatch(requestCompetition(eventId));
        return getCompetition(eventId, { scraped, orderId }).then((response) => {
            dispatch(receiveCompetition(eventId, response.data));
        }).catch(err => {
            console.log('competition not found???', err);
            dispatch(fetchError({ message: err.message }));
        });
    }
}



function receiveCompetitionState(compId, data) {
    
    return {
        type: "RECEIVE_COMPETITION_STATE",
        compId: compId,
        competitionState: data.state,
    }
}
/**
 *
 * @param compId
 * @returns {function(*)}
 */
export function refreshCompetitionState(compId) {
    return (dispatch) => {
        return getCompetitionState(compId)
            .then(response => {
                dispatch(receiveCompetitionState(compId, response.data));
            }).catch(function (err) {
                //Canadarm.error(err);
                console.log("refreshCompetitionState@err", err)
                //window.location.href = '/r/competitions';
            });
    }
}
