import { message } from 'antd'
import qs from 'qs';
import { get } from 'lodash';

export const _formDataEncode = (obj, prefix) => {
    var str = [],
        p;
    for (p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                formDataEncode(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export const _formDataDecode = (str) => {
    if (!str) return {};

    function paramsToObject(entries) {
        let result = {}
        for (let entry of entries) { // each 'entry' is a [key, value] tupple
            const [key, value] = entry;
            result[key] = value;
        }
        return result;
    }

    const urlParams = new URLSearchParams(str);
    const entries = urlParams.entries();
    return paramsToObject(entries);
}

export const formDataEncode = obj => qs.stringify(obj, { skipNulls: true })
export const formDataDecode = str => qs.parse(str, { ignoreQueryPrefix: true })


export const genericFormErrorHandler = (xhrError = {}, t = null) => {

    const status = get(xhrError, 'response.status', 500);

    switch (status) {
    case 500:
        return message.error(t('generic_error'))
    case 422:
        return message.error(t('generic_error'))
    }
}