import { Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from '../../constants/ThemeSetting';
import AppsNavigation from './AppsNavigation';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { RocketOutlined } from '@ant-design/icons';
import { isAdminUser } from '../../util/user';
import { includes } from 'lodash';

/**
 * mobile menu
 * @param {*} param0
 */
const SidebarContent = ({ user, t }) => {
    const { i18n } = useTranslation();

    let { navStyle, pathname } = useSelector(({ settings }) => settings);

    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return 'gx-no-header-notifications';
        }
        return '';
    };

    const getBlogUrl = () => {
        const baseBlogUrl = `${config.baseUrl}/blog`;

        if (includes(['pt', 'it'], i18n.language)) {
            return `${baseBlogUrl}/${i18n.language}`;
        }

        return `${baseBlogUrl}/it`;
    };

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>
            <SidebarLogo />
            <div className="gx-sidebar-content">
                <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
                    <UserProfile />
                    <AppsNavigation />
                </div>
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}
                        theme="light"
                        className="gx-pt-3 gx-pb-3"
                        mode="inline"
                    >
                        <Menu.Item key="events">
                            <Link to="/events">
                                <i className="icon icon-calendar" />
                                {t('contests')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="organizers">
                            <Link to="/organizers">
                                <i className="icon icon-alert" />
                                {t('organizators')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/rankings">
                                <i className="icon icon-listing-dbrd" />
                                <span>{t('rankings')}</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/photos">
                                <i className="icon icon-image" />
                                <span>{t('photos')}</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="blog">
                            <Menu.Item>
                                <a href={getBlogUrl()} target="_blank" rel="noreferrer">
                                    <i className="icon icon-chat" />
                                    Blog
                                </a>
                            </Menu.Item>
                        </Menu.Item>
                        {isAdminUser(user) && (
                            <Menu.Item>
                                <Link to="/admin">
                                    <RocketOutlined style={{ fontSize: 20 }} />
                                    {t('admin')}
                                </Link>
                            </Menu.Item>
                        )}
                    </Menu>
                </CustomScrollbars>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};

export default SidebarContent;
