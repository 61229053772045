import { Card } from "antd";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CardDescriptions, { CardDescriptionsItem } from "../../../../../components/Descriptions/CardDescription";


class PaymentData extends Component {

    constructor(props) {
        super(props);

        this.state = { expandPanel: true };
    }


    renderHeader(payment, t) {
        return (
            <a href="#" onClick={e => {
                e.preventDefault();
                this.setState({ expandPanel: !this.state.expandPanel });
            }}>
                {t('pay_with_' + payment.active)}
            </a>
        )
    }

    renderItem(title, value) {
        return <CardDescriptionsItem label={title}>{value}</CardDescriptionsItem>
    }

    render() {

        const { t, payment } = this.props;
        const { form } = payment;

        if (!form) {
            return <div>{t('not_provided')}</div>
        }

        return (

            <Card title={this.renderHeader(payment, t)}>
                <CardDescriptions column={2} >
                    <CardDescriptionsItem column={{ xs: 1, md: 2 }} label={t('full_name')}>{form.full_name}</CardDescriptionsItem>
                    <CardDescriptionsItem column={{ xs: 1, md: 2 }} label={t('phone_number')}>{form.phone_number}</CardDescriptionsItem>
                    <CardDescriptionsItem column={{ xs: 1, md: 2 }} label={t('residence_location')}>{form.city}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('province')}>{form.province}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('street')}>{form.street}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('street_number')}>{form.street_number}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('district')}>{form.district}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('cap')}>{form.cap}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('fiscal_code')}>{form.fiscal_code}</CardDescriptionsItem>
                </CardDescriptions>
            </Card>

        )
    }
}

export default withTranslation(['common'])(PaymentData)
