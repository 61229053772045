import { SIGNIN_USER } from "constants/ActionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getAuthToken } from 'util/persistence';
import { showAuthMessage, userFetchError, userFetchRequest, userFetchSuccess, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "../../appRedux/actions/Auth";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "../../client/auth";
import { FETCH_CURRENT_USER, SIGNUP_USER } from "../../constants/ActionTypes";
import { fetchError, showFormErrors, fetchSuccess } from "../actions/Common";
import { getCurrentUser, getUnknownUser } from "../../client/user";
import { push } from "react-router-redux";
import { get } from 'lodash';

const createUserWithEmailPasswordRequest = (user) =>
    createUserWithEmailAndPassword(user)
        .then(res => res.data)
//.catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password, social) =>
    signInWithEmailAndPassword(email, password, social).then(res => res);

const getUserData = async () =>
    getCurrentUser(['morphs', 'location', 'sports']).then(res => res.data)

const getUserUnknownData = async () => getUnknownUser().then(res => res.data)

// const signOutRequest = async () =>
//   await auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);



// const signInUserWithFacebookRequest = async () =>
//   await auth.signInWithPopup(facebookAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);



function* createUserWithEmailPassword({ payload }) {
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);

        localStorage.setItem('user_id', signUpUser.uid);
    
        yield put(userSignUpSuccess(signUpUser.uid));
        yield put(fetchSuccess());
        yield put(push(`/register/${signUpUser.ruid}`))

    } catch (error) {
        yield put(showFormErrors(error.response));
    }
}



/*function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}*/

function* signInUserWithEmailPassword({ payload }) {

    const { email, password, social } = payload;

    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password, social);

        const { data } = signInUser;    

        if (data.message) {
            yield put(showAuthMessage(data.message));
        } else {

            localStorage.setItem('auth_token', data.access_token);

            yield put(userSignInSuccess(data.access_token));
            yield put(fetchSuccess());
        }
    } catch (error) {
    
        const message = get(error, 'response.data.message') ? get(error, 'response.data.message') : 'login_failed';             

        yield put(fetchError(message));

        const errors = get(error, 'response.data.errors');

        if(errors && errors.redirect){
            yield put(push(errors.redirect[0]))
        }
    
    }
}

function* fetchCurrentUser() {
    try {
        yield put(userFetchRequest());
        let currentUser;

        if (getAuthToken()) {
            currentUser = yield call(getUserData);
        } else {
            currentUser = yield call(getUserUnknownData);
        }

        yield put(userFetchSuccess(currentUser))
    } catch (error) {    
        yield put(userSignOutSuccess())
        yield put(userFetchError('unauthorized'));
    }
}

/*function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}*/

export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

/*
export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}*/

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
    yield takeEvery(FETCH_CURRENT_USER, fetchCurrentUser)
}

/*export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}*/

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(createUserAccount),
    //fork(signInWithFacebook),
    //fork(signOutUser)
    ]);
}
