import config from 'config';
import React, { Component } from 'react';

class AdSense extends Component {

    componentDidMount() {
        if (config && config.environment === 'production' && !this.props.disabled) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.log("ads error", e)
            }
        }
    }

    render() {

        const { client, slot, responsive, label, className, format, disabled } = this.props;

        if ((config && config.environment !== 'production') || disabled) {
            return null;
        }

        return (
            <div style={{ marginTop: '5rem', marginBottom: '5rem', position: 'relative', width: '100%', maxWidth: '100%' }}>
                <span className="size11" style={{ color: "#999", textAlign: 'left' }}>{label}</span>
                <ins className={`adsbygoogle ${className}`}
                    style={{ display: 'block', background: 'transparent', position: 'relative', width: 'inherit', maxWidth: 'inherit' }}
                    data-ad-client={client}
                    data-ad-slot={slot}
                    data-ad-format={format}
                    data-full-width-responsive={responsive}>
                </ins>
            </div>
        )
    }

}

AdSense.defaultProps = {
    client: '',
    slot: '',
    label: '',
    className: '',
    responsive: 'true',
    format: 'auto',
    disabled: true
}

export default AdSense;