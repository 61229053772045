/* eslint-disable react/no-string-refs */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import { eventUrl } from '../../util/url';

let styles = {};

class SubscriptionButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showChangeProfileModal: false,
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    onConfirmChangeProfile() {
        this.setState({ showModal: false }, () => {
            const url = '/morph/transform/' + this.props.user.loggedUser.id + '?u=' + encodeURIComponent(window.location.pathname);
            window.location.href = url;
        });
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /**
     * TODO reintroduce modal profile switch?
     * @param {*} user 
     * @param {*} e 
     */
    handleSubscribeButtonClick() {

        let scroll = 850;

        if (this.refs && this.refs.titleBlock) {
            const rect = this.refs.titleBlock.getBoundingClientRect();
            if (rect) {
                scroll = rect.top + window.scrollY;
            }
        }

        if (this.state.width < 768) {
            window.scrollTo(0, scroll);
        }
    }

    render() {

        const { competition, user, className } = this.props;

        styles.subscribeButton = {};

        const subscriptionButtonTextKey = competition.subscriptionState.isOpen ? 'subscribe' : 'details';

        return (

            <Link onClick={this.handleSubscribeButtonClick.bind(this, user)} style={styles.subscribeButton}
                className={`ant-btn gx-btn-outline-primary gx-btn-lg btn-subscribe ${className}`}
                to={eventUrl(competition) + '/subscribe'}>
                <IntlMessages id={subscriptionButtonTextKey} />
            </Link>

        );

    }
}

SubscriptionButton.defaultProps = {
    fullWidth: false,
    className: ''
};

export default SubscriptionButton