import React from 'react'
import { withTranslation } from 'react-i18next'
import { url } from 'util/index';
import classNames from 'classnames'
import { Card, Col, Divider } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
/**
 * opt props dealerOnly boolean
 * used for photo cart too
 */
class Assistance extends React.Component {

    renderDealer(dealer, t) {
        if (dealer) {

            const wrapperClasses = classNames({
                "col-md-12": true,
            });

            return (
                <div className="row">
                    <div className={wrapperClasses}>
                        <div className="floatleft">
                            <a href={`/profile/${dealer.id}/${url.slugify(dealer.complete_name)}`} rel="noreferrer" target="_blank">
                                <img className="radius shadowdark"
                                    src={url.addSizeToFilename(dealer.photo, '40x40')}
                                    alt={dealer.complete_name} />
                            </a>
                        </div>
                        <p style={{ marginLeft: 50, minHeight: 60 }}>{t(this.props.assistanceDealerOrganizationTextKey, { name: dealer.complete_name })}</p>
                        <div className="gx-text-center">
                            <Link className="btn btn-ks btn-lg" target="_blank" rel="noreferrer" to={`/chat?with=${dealer.id}`}>{t('contact')}</Link>
                        </div>
                        <ul className="ant-list" style={{ marginTop: 10 }}>
                            {dealer.email &&
                                <li className="ant-list-item">
                                    <a href={`mailto:${dealer.email}`}><MailOutlined />&nbsp;{dealer.email}</a>
                                </li>
                            }
                            {dealer.phone &&
                                <li className="ant-list-item">
                                    <a href={`tel:${dealer.phone.replace('-', '')}`}><PhoneOutlined />&nbsp;{dealer.phone}</a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            )
        }
    }

    renderOrganizer(competition, t) {
        const organizer = competition.organization;
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="floatleft">
                        <a href={`/profile/${organizer.ksId}/${url.slugify(organizer.name)}`} rel="noreferrer" target="_blank">
                            <img className="radius shadowdark"
                                src={url.addSizeToFilename(organizer.extra.photo, '40x40')}
                                alt={organizer.name} />
                        </a>
                    </div>
                    <p style={{ marginLeft: 50, minHeight: 60 }}>{t(`assistance_organizer_type_${competition.type == 1 ? 1 : 'other'}`, { name: organizer.name })}</p>
                    <div className="gx-text-center">
                        <Link className="btn btn-ks btn-lg" target="_blank" rel="noreferrer" to={`/chat?with=${organizer.ksId}`}>{t('contact')}</Link>
                    </div>
                    <ul className="ant-list" style={{ marginTop: 10 }}>
                        {organizer.extra.email &&
                            <li className="ant-list-item">
                                <a href={`mailto:${organizer.extra.email}`}><MailOutlined />&nbsp;{organizer.extra.email}</a>
                            </li>
                        }
                        {organizer.extra.phone &&
                            <li className="list-group-item">
                                <a href={`tel:${organizer.extra.phone.replace('-', '')}`}><PhoneOutlined />&nbsp;{organizer.extra.phone}</a>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        )
    }

    render() {
        const { t, competition } = this.props;

        return (
            <Col xs={24}  >
                <Card title={t('assistance')} className="gx-card">

                    {this.renderDealer(competition.dealer, t)}
                    <Divider className="_visible-xs _visible-sm" />
                    {!this.props.dealerOnly && this.renderOrganizer(competition, t)}
                </Card>
            </Col>
        )
    }

}

Assistance.defaultProps = {
    dealerOnly: false,
    assistanceDealerOrganizationTextKey: 'assistance_dealer_organization'
};

export default withTranslation(['common'])(Assistance)
