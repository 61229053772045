import { Card } from "antd";
import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CardDescriptions, { CardDescriptionsItem } from "../../../../../components/Descriptions/CardDescription";

class CardGerencianetVerificationData extends Component {

    renderHeader(t) {
        //use stripe too
        return <span>{t('pay_with_stripe')}</span>
    }    

    getHiddenNumber(number) {
        if (number) {
            const l = number.length;
            return new Array(l - 4).join('*') + number.slice(- 4);
        }
        return "";
    }

    render() {

        const { t, payment } = this.props;
        const { form } = payment;

        if (!form) {
            return <div>{t('not_provided')}</div>
        }

        let cvc = "";
        if (get(form, 'cvc')) {
            cvc = new Array(form.cvc.length + 1).join('*');
        }

        return (

            <Card className="gx-card" title={this.renderHeader(t)}>
                <CardDescriptions column={2} className="ks-descriptions">                                       
                    <CardDescriptionsItem label={t('card_number')} >{this.getHiddenNumber(form.number)}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('cvc')} >{cvc}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('card_expiration')} >{`${form.exp_month}/${form.exp_year}`}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('installments')} >{form.installments}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('phone_number')} >{form.phone_number}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('residence_location')} >{form.city}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('province')} >{form.province}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('street')} >{form.street}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('street_number')} >{form.street_number}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('district')} >{form.district}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('cap')} >{form.cap}</CardDescriptionsItem>
                    <CardDescriptionsItem label={t('fiscal_code')} >{form.fiscal_code}</CardDescriptionsItem>
                </CardDescriptions>
            </Card>

        )
    }
}

export default withTranslation(['common'])(CardGerencianetVerificationData)