import { formDataEncode } from "../util/form";
import { buildUrl, getClient } from "./base";

export const getCompetition = (id, { scraped, orderId }) => {

    let includes = '';
    if (scraped === 1) {
        includes = 'cover,racesSummary,attachment';
    } else {
        includes = 'meta,cover,racesSummary,dealer'
    }

    let params = { t: new Date().getTime(), 'with': includes }

    if (orderId) {
        params['tickets'] = orderId
    }

    return getClient().get(buildUrl(`api/competition/manager/competition/${id}`, params))
};


export const getCompetitionState = id => getClient().get(`api/competition/manager/competition-state/${id}?${formDataEncode({ t: new Date().getTime() })}`)


export const getCompetitionGallery = (comp_id, per_page, page_id = 1) => {

    const params = { perPage: per_page, page: page_id };
    const url = `api/competition/manager/competition-photos/${comp_id}?${formDataEncode(params)}`

    return getClient().get(url);
};

export const getCompetitionStandings = compId => getClient().get(buildUrl(`api/competition/manager/standings/${compId}`, { t: new Date().getTime() }, ['standings']))

export const getContestPlayers = contestId => getClient().get(buildUrl(`api/competition/manager/starting-list/${contestId}`, { t: new Date().getTime() }));

