


const layout = {
    labelCol: {
        xs: {
            span: 24
        },
        sm: {
            span: 8,
        }
    },
    wrapperCol: {
        xs: {
            span: 24
        },
        sm: {
            span: 16
        }
    },
};
const tailLayout = {
    wrapperCol: {
        xs: {
            span: 24
        },
        sm: {
            offset: 8,
            span: 24
        }
    },
};




export { layout, tailLayout };
