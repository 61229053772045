import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { LockOutlined } from '@ant-design/icons';

const Error403 = ({ t }) => (
    <div className="gx-page-error-container">
        <div className="gx-page-error-content">
            <div className="gx-error-code gx-mb-4"><LockOutlined /></div>
            <h2 className="gx-text-center">
                {t('login_to_see_details')}
            </h2>

            <p className="gx-text-center">
                <Link className="gx-btn gx-btn-primary" to="/login">{t('login')}</Link>
            </p>
        </div>
    </div>
);

export default withTranslation()(Error403);
