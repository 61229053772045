import { get } from 'lodash';
import React from 'react';
import { withTranslation } from "react-i18next";
import { FormatPrice } from '../../../../../util/price';



const CardGerencianetInstallments = ({ paymentData, competition, t }) => {
    return (
        paymentData.installments > 1 &&
        <p dangerouslySetInnerHTML={{
            __html: t('payment_installments_information', [
                `<strong>${paymentData.installments}</strong>`,
                `<strong>${FormatPrice({ price: paymentData.installment_value, currency: get(competition, 'payments.currency.iso', '€') })}</strong>`
            ])
        }} />
    )
};

class CardGerencianetCompletedInformation extends React.Component {

    /**
     * payment could have full transaction (re-fetch case) or
     * publishable response with keys 'paid, active, link, expire_at, charge_id'
     */
    constructor(props) {
        super(props);

        this.paymentData = {};

        if (props.payment.transaction) {
            this.paymentData.installments = get(props.payment.transaction, 'payoff.installments');
            this.paymentData.installment_value = get(props.payment.transaction, 'payoff.installment_value');
        } else {
            this.paymentData.installments = get(props.payment, 'payoff.installments');
            this.paymentData.installment_value = get(props.payment, 'payoff.installment_value');
        }
    }

    render() {
        const { t, payment, competition } = this.props;

        return (
            <div>
                {payment.paid ? <p>{t('payment_successfully_completed')}</p> : <p>{t('payment_will_complete_receive_email')}</p>}
                <CardGerencianetInstallments paymentData={this.paymentData} competition={competition} t={t} />
            </div>
        )
    }
}

export default withTranslation(['common', 'payment'])(CardGerencianetCompletedInformation);