import { SET_REGISTERING_USER, UPDATE_PROFILE_TIME } from "../../constants/ActionTypes";

export const setRegisteringUser = (data) => {
    return {
        type: SET_REGISTERING_USER,
        payload: data
    }
};


export const updateProfileTime = () => {
    return {
        type: UPDATE_PROFILE_TIME,
        payload: null
    }
}

//export const userNavigateToChat