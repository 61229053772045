import { processGnInstallments } from "appRedux/actions/Tickets";
import { getTicketsTotal } from "appRedux/reducers/helpers/tickets";
import config from "config";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getProviderBySlug } from "util/payments";
import { getCurrency } from "util/price";
import Bank from "./Bank";
import BoletoGerencianet from "./BoletoGerencianet";
import CardGerencianet, { GN_DEFAULT_CARD_BRAND } from "./CardGerencianet";
import Cash from "./Cash";
import Paypal from "./Paypal";
import Payu from "./Payu";
import StripeElements from "./StripeElements";
import Pagadito from "./Pagadito";

//bind to window
window.$gn = {
    validForm: true,
    processed: false,
    done: {},
    ready: function (fn) {
        window.$gn.done = fn;
    },
};

class Payment extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            gnCheckoutReady: null,
            gnCheckout: null,
        };
    }

    UNSAFE_componentWillMount() {
    //todo improve script loading changing state on load and pass on props, load stripe only if provider is active
    //todo clean
        const self = this;
        const scriptjs = require("scriptjs");
        const { competition } = this.props;
        const { payments } = competition;

        const gnProvider = getProviderBySlug(payments, "card-gerencianet");
        if (gnProvider) {
            //async load
            //console.log("config.gnCheckoutURL", config.gnCheckoutUrl);

            scriptjs(
                `${config.gnCheckoutUrl}/${Math.random() * 1000000}`,
                function () {
                    window.$gn.ready(function (checkout) {
                        self.setState({ gnCheckoutReady: true, gnCheckout: checkout });

                        self.props.dispatch(
                            processGnInstallments(
                                checkout,
                                getTicketsTotal(self.props.tickets),
                                GN_DEFAULT_CARD_BRAND
                            )
                        );
                    });
                }
            );
        }
    }

    componentDidMount() {
        if (!!window.$gn.checkout && !this.state.gnCheckoutReady) {
            this.setState({ gnCheckoutReady: true });
        }
    }

    getComponentBySlug(activeSlug, competition) {
        switch (activeSlug) {
        case "paypal":
            return <Paypal {...this.props} />;

        case "pagadito":
            return <Pagadito {...this.props} />;

        case "stripe":
            return <StripeElements {...this.props} />;

        case "payu":
            return <Payu {...this.props} />;

        case "bank":
            return <Bank {...this.props} hidePaymentReference={true} />;

        case "boleto":
            return <BoletoGerencianet {...this.props} />;

        case "card-gerencianet":
            return (
                <CardGerencianet
                    {...this.props}
                    checkoutReady={this.state.gnCheckoutReady}
                    gnCheckout={this.state.gnCheckout}
                    currency={getCurrency(competition)}
                />
            );

        case "cash":
            return <Cash {...this.props} />;
        }
    }

    render() {
        const { competition, active } = this.props;

        return <>{this.getComponentBySlug(active, competition)}</>;
    }
}

export default connect()(withTranslation(["common"])(Payment));
