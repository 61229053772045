import { CalendarOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { date, location as locationUtil, url } from 'util/index';

export const CoverOLD = ({ competition }) => {
    let coverStyle = {
        margin: '0 auto',
        height: 'auto',
        maxHeight: 600,
        display: 'block',
        width: '100%',
    };
    if (competition.imageId) {
        const size = get(competition.cover, 'copies.big', false) ? 'big' : 'original';

        if (competition.scraped === 1 && get(competition.cover, 'original.width') < 400) {
            coverStyle = { width: '100%', height: 'auto', maxHeight: 600 };
        }

        return (
            <img style={coverStyle} src={url.getS3Image(competition.cover, 'images', size)} alt={competition.name} />
        );
    } else {
        //todo test
        const img = require('assets/images/page/competition.jpg');
        //coverStyle.maxHeight = 450;
        const wrapperStyle = { maxHeight: 450, overflow: 'hidden' };
        return (
            <div style={wrapperStyle}>
                <img style={coverStyle} className="gx-img-responsive" src={img} />
            </div>
        );
    }
};

export const Cover = ({ competition }) => {
    let coverStyle = {
        margin: '0 auto',
        height: 'auto',
        maxHeight: 600,
        display: 'block',
        width: '100%',
    };
    if (competition.cover) {
        return <img style={coverStyle} src={url.getS3Asset(competition.cover)} alt={competition.name} />;
    } else {
        const img = require('assets/images/page/competition.jpg');
        const wrapperStyle = { maxHeight: 450, overflow: 'hidden' };
        return (
            <div style={wrapperStyle}>
                <img style={coverStyle} className="gx-img-responsive" src={img} />
            </div>
        );
    }
};

const EventHeader = ({ competition, style = 'horizontal' }) => {
    const organizer = competition.organization;

    const { location } = competition;

    const organizerLink = organizer.ksId ? `/profile/${organizer.ksId}/${url.slugify(organizer.name)}` : '#';

    const eventDate = competition.startDate
        ? date.fromTimestamp(competition.startDate.$date.$numberLong / 1000, 'dddd, DD MMMM', 'UTC', false)
        : ' ';

    return (
        <div className={`gx-ml-3 ${style === 'vertical' && 'gx-mb-3'}`}>
            <div className={style === 'horizontal' ? 'gx-d-block' : 'gx-d-inline-block'}>
                {!competition.hide_date && (
                    <div className="h4 gx-text-primary gx-mt-3">
                        <CalendarOutlined /> {eventDate}
                    </div>
                )}

                <h3 className="title gx-mt-3" style={{ lineHeight: '32px', fontSize: '26px' }}>
                    <Link to={`/cr/${competition._id}/${url.slugify(competition.name)}`} className="c1">
                        {competition.name}
                    </Link>
                </h3>

                <div className="gx-mt-2" style={{ fontSize: 16 }}>
                    <span>
                        {location.state && (
                            <i
                                style={{ marginTop: 2 }}
                                className={`list-flag flag-${location.state.toLowerCase()}`}
                            ></i>
                        )}
                        {locationUtil.formatLocationName(location)}
                    </span>
                </div>
            </div>
            <div
                className={`${
                    style === 'horizontal'
                        ? 'gx-mt-4  gx-d-block'
                        : 'gx-d-block gx-d-md-inline-block gx-float-md-right gx-mt-3 gx-mt-md-0'
                }`}
            >
                <a href={organizerLink} target="_blank" rel="noreferrer">
                    <img
                        style={{ margin: '10px 10px 10px 0' }}
                        className="radius shadowdark"
                        src={url.getProfileImage(organizer.ksId)}
                        alt={organizer.name}
                    />
                </a>
                <span className="v4 size14">
                    <a href={organizerLink}>{organizer.name}</a>
                </span>
            </div>
            <div style={{ clear: 'both' }}></div>
        </div>
    );
};

export default EventHeader;
