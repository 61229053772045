import { formDataEncode } from "../util/form";
import { getClient, buildUrl } from "./base";

export const getCurrentUser = (relations = []) => {
    return getClient().get(buildUrl('api/me', {}, relations))
}

export const getUnknownUser = () => getClient().get(buildUrl('api/user/unknown', {
    timezone: Intl.DateTimeFormat().resolvedOptions()?.timeZone
}));

export const updateUserProfile = (user, morph = null) => {
    return getClient().post(buildUrl(`api/user/update`, { morph: morph }, ['location']), formDataEncode(user));
}

export const prepareEMPTransition = id => getClient().get(buildUrl(`api/competition/manager/transition`, { morph: id }, []));

export const getMorphOrganization = id => getClient().get(buildUrl('api/me', { morph: id }, ['location', 'managers', 'sports']))

export const createMorphPermission = ({ user_id, permission }, morph) => getClient().post(buildUrl('api/morph/permission', { morph }), formDataEncode({ user_id, permission }))

export const deleteMorphPermission = (user_id, morph) => getClient().delete(buildUrl(`api/morph/${user_id}`, { morph }))

export const updateMorphPermission = (userId, morphId, { permission }) => getClient({ 'Content-Type': 'application/x-www-form-urlencoded' })
    .put(buildUrl('api/morph/' + userId, { morph: morphId }), formDataEncode({ permission }))


export const processFacebookLogin = ({ id, token }) => getClient().post(buildUrl('api/access_token_social'), formDataEncode({
    social_type: 'facebook',
    social_id: id,
    social_token: token
}))

export const getProfile = id => getClient().get(buildUrl(`api/${id}`, {}, ['location']));

export const getPublicProfile = id => getClient().get(buildUrl(`api/user/public/${id}`, {}, ['location']));

export const getContacts = (currentUserId, morphId) => getClient().get(buildUrl(`api/message/contacts`, { current: currentUserId, morph: morphId }));

export const getMessagesFrom = (id, morph, page) => getClient({ headers: { 'X-Serializer': 'data' } }).get(buildUrl(`api/message/from/${id}`, { morph: morph, page: page }));

export const getNewMessagesFrom = (id, morph, last) => getClient().get(buildUrl(`api/message/new-from/${id}/${last}`, { morph, page: 1 }));

export const sendMessage = params => getClient().post(buildUrl(`api/message/send`), formDataEncode(params))

export const blockChat = (params, morph) => getClient().post(buildUrl(`api/message/block`, { morph }), formDataEncode(params))

export const unblockChat = (params, morph) => getClient().post(buildUrl(`api/message/unblock`, { morph }), formDataEncode(params))

export const reportChat = (params, morph) => getClient().post(buildUrl(`api/message/report`, { morph }), formDataEncode(params))

export const noSpamChat = (params, morph) => getClient().post(buildUrl(`api/message/no-spam`, { morph }), formDataEncode(params))

export const getUsers = (params) => getClient({ headers: { 'X-Serializer': 'data' } }).get(buildUrl(`api/users`, params, ['managers']))

export const getUsersExcel = (params) => getClient({ timeout: 100 * 60 * 1000 }).get(buildUrl(`api/users/users-xls`, params))

export const getEventPayersExcel = (params) => getClient({ timeout: 10 * 60 * 1000 }).get(buildUrl(`api/competition/tickets/payers-excel`, params))

export const sendContactForm = (params) => getClient().post(buildUrl(`api/contact-form`), formDataEncode(params))