
const INIT_STATE = {
    data: {},
    isFetching: true,
    lastUpdate: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case "RECEIVE_COMPETITION":
        return {
            isFetching: false,
            data: action.data,
            lastUpdate: action.receivedAt
        }

    case "REQUEST_COMPETITION":
        return {
            ...state,
            isFetching: true
        }                

    case "RECEIVE_COMPETITION_STATE": 
        return {
            ...state,
            data: {
                ...state.data,
                subscriptionState: action.competitionState
            }
        }
           

    default:
        return state;
    }
}