import { Col, Row } from 'antd';
import { find, get, includes, values } from 'lodash';
import React, { Component } from 'react';
import SelectField from '../../../../components/Form/Legacy/SelectField';
import TextField from '../../../../components/Form/Legacy/TextField';
import { SUBSCRIPTION } from '../../../../constants/Tickets';


class SelectAthleteCard extends Component {

    constructor(props) {
        super(props);
        const currentOption = find(props.field.options, o => o.value === props.value);
        this.state = {
            currentOption: currentOption
        }
    }

    hasCardNumberField(currentOption) {
        return currentOption && currentOption.card_number === true;
    }

    handleCardChange(value) {
        value = value ? value : "";

        if (value) {
            const currentOption = find(this.props.field.options, o => o.value === value);
            const hasCardNumberField = this.hasCardNumberField(currentOption);            

            this.setState({ currentOption: currentOption }, () => {
                this.props.onAthleteCardChange('athlete_card', value);
                if (hasCardNumberField) {
                    this.props.onAthleteCardChange('athlete_card_number_field', 1);//for valdation only
                } else {
                    this.props.onAthleteCardChange('athlete_card_number_field', 0);//for valdation only
                }
            })
        } else {
            this.setState({ currentOption: value }, () => {
                this.props.onAthleteCardChange('athlete_card', "");
                this.props.onAthleteCardChange('athlete_card_number_field', 0);//for valdation only                
            })
        }
    }

    handleCardNumberChange(e) {
        this.props.onAthleteCardChange('athlete_card_number', e.target.value);
    }

    getCardNumberProps(currentOption, t) {
        if (!currentOption || !currentOption.type || includes(currentOption.type, values(SUBSCRIPTION.ATHLETE_CARDS))) return null;
        let maxLength = null;
        let placeholder = '';
        switch (currentOption.type) {
        case SUBSCRIPTION.ATHLETE_CARDS.FIDAL:
        case SUBSCRIPTION.ATHLETE_CARDS.RUNCARD:
            maxLength = 8;
            placeholder = new Array(9).join('_');
            break;
        case SUBSCRIPTION.ATHLETE_CARDS.FITRI:
            maxLength = 6;
            placeholder = new Array(7).join('_');
            break;

        }

        return {
            placeholder: placeholder,
            maxLength: maxLength,
            help: t(`athlete_card_${currentOption.type}_validation`)
        }
    }

    render() {
        const { form, field, validationState, t } = this.props;
        const { currentOption } = this.state;
        const showCardNumber = this.hasCardNumberField(currentOption);

        //could be null
        const cardNumberProps = this.getCardNumberProps(currentOption, t);

        return (
            <Row>
                <Col xs={24} className={`${showCardNumber ? 'gx-mb-3' : ''}`}>
                    <SelectField
                        name="athlete_card"
                        label={`${t('athlete_card')}  ${field.required ? '*' : ''}`}
                        onChange={this.handleCardChange.bind(this)}
                        options={[{ value: '', text: t('empty_value') }].concat(field.options.map(o => ({ value: o.value, text: o.label })))}
                        value={get(form, 'athlete_card', "")}
                        validationState={validationState}
                    />
                </Col>
                {showCardNumber &&
                    <Col xs={24}>

                        <TextField
                            name="athlete_card_number" type="text"
                            label={t('athlete_card_number') + ' *'}
                            onChange={this.handleCardNumberChange.bind(this)}
                            placeholder={cardNumberProps ? cardNumberProps.placeholder : null}
                            maxLength={cardNumberProps ? cardNumberProps.maxLength : null}
                            value={form.athlete_card_number}
                            validationState={get(form.errors, 'athlete_card_number.state', null)}
                            help={get(form.errors, 'athlete_card_number.message', cardNumberProps ? cardNumberProps.help : '')}
                        />
                    </Col>
                }
            </Row>
        )
    }
}

export default SelectAthleteCard
