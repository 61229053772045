/**
 * Created by ruzz on 25/02/16.
 */
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CustomCheckbox from './CustomFields/CustomCheckbox'
import CustomInput from './CustomFields/CustomInput'
import CustomNote from './CustomFields/CustomNote'
import CustomSelect from './CustomFields/CustomSelect'

class CustomFields extends Component {

    constructor(props, context) {
        super(props, context)
    }

    componentDidMount() {
    }

    cb(v, name, payoff = null) {
        return this.props.cb(v, name, payoff);
    }

    render() {

        const { field, className } = this.props;

        let jsxField;        

        switch (field.type) {
        case 'note':
            jsxField = <CustomNote cb={this.cb.bind(this, field.name)} {...this.props} />;
            break;
        case 'free_text':
            jsxField = <CustomInput cb={this.cb.bind(this, field.name)} {...this.props} />;
            break;
        case 'select_choice':
            jsxField = <CustomSelect cb={this.cb.bind(this, field.name)} {...this.props} />;
            break;
        case 'check_box':
            jsxField = <CustomCheckbox cb={this.cb.bind(this, field.name)} {...this.props} />;
            break;
        default:
            jsxField = <CustomInput cb={this.cb.bind(this, field.name)} {...this.props} />;
            break;

        }

        return <div className={className}>{jsxField}</div>

    }
}

export default withTranslation()(CustomFields)
