import { Checkbox } from "antd";
import { truncate } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class CustomCheckbox extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: false,
            expand: false
        }
    }

    onClickExpand(e) {
        e.preventDefault();

        this.setState({ expand: !this.state.expand });
    }

    /**
     *  does nothing!
     * @param e
     */
    handleChange(e) {
        let value = e.target.checked;

        this.setState({
            value: value
        });

        if (value) {
            value = 'yes';
        }

        this.props.cb(value, this.props.field.name);
    }

    renderLabel(label, maxLength) {
        const { expand } = this.state;
        if (label && label.length > maxLength) {
            return (
                <div>
                    {!expand ? truncate(label, { length: maxLength }) : label}
                    <a href="#" onClick={this.onClickExpand.bind(this)}>
                        {` `}<i className={`glyphicon glyphicon-${expand ? 'minus' : 'plus'}`}></i>
                    </a>
                </div>
            )
        } else {
            return <span>{label}</span>
        }
    }

    render() {
        const { value, field, maxLength, validationState } = this.props;

        const label = field.label + ((field.required === true) ? ' *' : '');

        return (
            <Checkbox 
                className={`payment-checkbox ${validationState ? `has-${validationState}` : ''}`}
                type="checkbox"
                value={value}
                checked={value === "yes"}
                onChange={this.handleChange.bind(this)}
                name={field.name}>
                {this.renderLabel(label, maxLength)}
            </Checkbox>
        )
    }

}

CustomCheckbox.defaultProps = {
    maxLength: 150
};

export default withTranslation()(CustomCheckbox)
