import { PictureOutlined } from '@ant-design/icons';
import { Col, Row } from "antd";
import React from 'react';
import { Link } from "react-router-dom";
import { date, string, url } from 'util/index';
import Price from "../../../components/Competition/Price";
import { contestUrl } from '../../../util/url';

class RaceCard extends React.Component {

    constructor(props) {
        super(props);
    }

    renderImage(item) {
        let src = "";

        if (item.cover) {
            src = url.getS3Asset(item.cover)
        } else {
            src = require('../../../assets/images/events-platform.jpg');
        }

        return (
            <img src={src} alt={item.name} />
        )
    }

    render() {

        const { race, competition, t } = this.props;
        const { timezone, sport } = competition;        

        return (
            <Row>
                <Col xs={24} sm={24} className={`gx-mt-4`} key={race.id}>
                    <div className="list-card linkable-list-card">
                        <Link to={contestUrl(competition, race)} className="list-card-container">
                            <div className="list-card-header">
                                <div className="list-card-image">
                                    {this.renderImage(race)}
                                </div>
                                <span className="list-card-label price">
                                    <Price price={race.price} competition={competition} team={race.hasTeams}/>
                                </span>
                            </div>
                            <div className="list-card-content">
                                <div className="v4 list-card-date">
                                    {!race.timetables.hide_event_date ? date.fromTimestamp(race.timetables.raceStart, 'ddd, DD MMM YYYY', timezone) : null}
                                </div>
                                <div className="v1 list-card-title">{race.name}</div>
                                {race.location && (
                                    <div className="v4 list-card-location">
                                        {race.location.state && <i className={`list-flag flag-${race.location.state.toLowerCase()}`}></i>}
                                        {string.leftOf(race.location.name, ',')}
                                    </div>
                                )}
                            </div>
                        </Link>
                        <div className="list-card-footer">
                            <div className="list-card-sports">
                                {t(`sports:sport_${sport}`)}
                            </div>
                            {
                                race.hasPublishedAlbums && <div className="list-card-actions">
                                    <Link to={`contest/${race.id}/albums`}>
                                        <PictureOutlined />
                                    </Link>
                                </div>
                            }
                        </div>

                    </div>
                </Col>
            </Row>
        )
    }
}

export default RaceCard;