import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import rootSaga from "../sagas/index";
import ReactGA from 'react-ga';

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

history.listen((location) => {    
    ReactGA.pageview(location.pathname + location.search);    
});

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
    
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions        
                ...middlewares
            ),
        ),
    );
    sagaMiddleware.run(rootSaga);       
    return store;
}
