import { assign, get, includes, isEmpty, map, omit, pick, pickBy, keyBy } from 'lodash';
import { default as update } from "react-addons-update";
import { getPersistJSONKey, persistJSON } from "../../util/persistence";
import { payerInitState, paymentInitState, playersInitState, recalcOrderTotal, ticketsInitState } from "./helpers/tickets";


const getInitState = () => {

    const storedState = getPersistJSONKey('competition.tickets');

    if (!storedState || isEmpty(storedState)) {
        return ticketsInitState()
    }
    return storedState;
}

const tickets = (state = getInitState(), action) => {

    /**
     * generate $raceId_$index associative array for player state for every ticket
     * @returns {{}}
     */
    var obj = {};

    switch (action.type) {
    /**
                 * @param {string} action.type
                 */
    case "SET_PAYMENT_METHOD": {

        if (state.payment && state.payment.active !== action.slug) {

            const modifier = {
                payment: {
                    $set: paymentInitState(action.slug, action.sellingPoint, action.resetKsFee, action.user)
                },
                serverErrors: { $set: false }//reset server errors
            };

            //change payment from sp to standard
            if (state.payment.sellingPoint !== action.sellingPoint || state.payment.resetKsFee !== action.resetKsFee) {

                console.log("change payment from sp to standard");
                modifier.order = {
                    $apply: (orders) => {

                        return orders.map(order => {
                            const { fee, total } = recalcOrderTotal(order, action.fees);
                            order.fee = fee;
                            order.total = total;
                            order.feeToOrg = action.fees.feeToOrg;
                            return order;
                        })

                    }
                }
            }

            return update(state, modifier);
        }
        return state;
    }
    case "SET_TICKETS_ORDER": {

        const newState = update(state, {
            id: { $set: action.id },
            compId: { $set: action.compId },
            order: { $set: action.order },
            payer: {
                $set: payerInitState(action.user)
            },
            teams: { $set: keyBy(action.teams, 'raceId') },
            players: { $set: playersInitState(action.order) },
            payment: { $set: paymentInitState() },
            fetching: { $set: false },
            step: { $set: "compile_form" } //['compile_form','data_verification','completed']
        });

        persistJSON('competition.tickets', newState);

        return newState;
    }
    case "RECEIVE_TICKETS_ORDER_RESPONSE":

        return {
            ...state,
            steps: {
                current: 2,
                status: ['finish', 'process', 'wait', 'wait']
            }
        }

    case "@@router/LOCATION_CHANGE": {

        const pathname = get(action, 'payload.location.pathname', '');

        const isFirstTicketStep = pathname.match(/\/subscribe$/);

        const isSecondTicketStep = pathname.match(/\/subscribe\/order\/[A-z0-9-]+$/);

        if (isSecondTicketStep) {
            return {
                ...state,
                steps: {
                    current: 2,
                    status: ['finish', 'process', 'wait', 'wait']
                }
            }
        } else if (isFirstTicketStep) {
            // return {
            //     ...state,
            //     steps: {
            //         current: 1,
            //         status: ['process', 'wait', 'wait', 'wait']
            //     }
            // }
            return persistJSON('competition.tickets', ticketsInitState());

        } else if (pathname.match(/\/subscribe\/order\/[A-z0-9-]+\/confirm$/)) {
            return {
                ...state,
                steps: {
                    current: 3,
                    status: ['finish', 'finish', 'process', 'wait']
                }
            }
        }
        else if (pathname.match(/\/subscribe\/order\/[A-z0-9-]+\/completed$/)) {
            return {
                ...state,
                steps: {
                    current: 4,
                    status: ['finish', 'finish', 'finish', 'process']
                }
            }
        }
        return state;
    }
    /***
                 * update payer state when user log in on tickets forms page
                 */
    case "UPDATE_PAYER_INIT_STATE":
        return update(state, {
            payer: { $set: payerInitState(action.user) }
        });

        /**
         * in order form when user trigger a select option with addition
         * recalculate fees if feeToOrg is false!
         */
    case "UPDATE_TICKETS_ORDER":

        return update(state, {
            order: {
                $apply: function (orders) {

                    const order = orders.find(o => o.id === action.key);

                    if (action.addition.value !== 0) {

                        order.additions[action.field] = pick(action.addition, ['value', 'field_value', 'label']);
                        //change total?
                    } else {
                        //remove option
                        order.additions = omit(order.additions, [action.field]);
                    }

                    //migrationfix
                    console.log(
                        "CHECK MIGRATION FIX", {
                            action
                        })
                    const { additionsTotal, fee, total } = recalcOrderTotal(order, action.addition.ksFee);
                    order.additionsTotal = additionsTotal;
                    order.fee = fee;
                    order.total = total;

                    return orders;
                }
            }
        });


    case "UPDATE_PAYER_FORM": {

        obj = {
            [action.field]: action.value
        };

        if (state.payer.errors[action.field] && state.payer.errors[action.field].state === 'error' && action.valid) {
            obj.errors = update(state.payer.errors, { $merge: { [action.field]: { state: 'success' } } })
        }

        if (action.payoff) {
            obj = assign(obj, action.payoff);
        }

        return update(state, {
            payer: {
                $merge: obj
            }
        });
    }

    case "UPDATE_TEAMS_FORM": {

        obj = {
            [action.field]: action.value
        };

        if (get(state.teams, `${action.key}.errors.${action.field}.state`) === 'error' && action.valid) {
            obj.errors = update(state.teams[action.key].errors, {
                $merge: { [action.field]: { state: 'success' } }
            })
        }

        return update(state, {
            teams: {
                [action.key]: { $merge: obj }
            }
        });
    }

    case "UPDATE_PLAYER_FORM": {

        obj = {
            [action.field]: action.value
        };

        if (get(state.players, `${action.key}.errors.${action.field}.state`) === 'error' && action.valid) {
            obj.errors = update(state.players[action.key].errors, {
                $merge: { [action.field]: { state: 'success' } }
            })
        }

        return update(state, {
            players: {
                [action.key]: { $merge: obj }
            }
        });
    }
    case "SET_STRIPE_PAYMENT_METHOD_SUCCESS": {

        if (state.payment.active !== 'stripe') {
            return state;
        }

        return update(state, {
            payment: {
                form: { $set: action.card },
                errors: { $set: {} }
            }
        });
    }
    case "UPDATE_PAYMENT_FORM": {

        let errors = state.payment.errors;

        if (get(state.payment, `errors.${action.field}.state`) === 'error' && action.valid) {
            errors = update(state.payment.errors, {
                $merge: { [action.field]: { state: 'success' } }
            })
        }

        switch (state.payment.active) {
        case 'stripe':
        case 'boleto':
        case 'card-gerencianet':
            return update(state, {
                payment: {
                    form: {
                        [action.field]: { $set: action.value }
                    },
                    errors: { $set: errors }
                }
            });
        default:
            return state;
        }
    }
    case "PAYER_FORM_ERROR":
        return update(state, { payer: { errors: { $set: action.error } } });

    case "PAYMENT_FORM_ERROR":
        return update(state, { payment: { errors: { $set: action.error } } });

        /**
         * @param {object} action.payer
         * @param {object} action.players
         * @param {object} action.players[orderId]
         * @param {object} action.payment
         */
    case "FORM_SERVER_ERRORS": {

        let payerErrors = {};
        if (action.payer) {
            map(action.payer, (error, field) => {
                payerErrors[field] = { attribute: 'serverError', message: error[0], state: "error", value: "" }
            })
        }

        let players = state.players;
        if (action.players) {
            map(action.players, (error, key) => {
                if (error.key) {
                    //ticket limit error
                    players[key].errors = { [error.key]: { attribute: 'serverError', message: error.message, state: "error", value: "" } }
                } else {
                    //players validated by server 
                    const playersErrors = {}
                    map(error, (fieldError, field) => {
                        playersErrors[field] = { attribute: 'serverError', message: fieldError[0], state: 'error', value: "" };
                    })
                    players[key].errors = playersErrors;
                }
            })
        }

        let paymentErrors = {};
        if (action.payment) {
            map(action.payment, (error, field) => {
                paymentErrors[field] = { attribute: 'serverError', message: error[0], state: "error", value: "" }
            })
        }

        return update(state, {
            payer: { errors: { $set: payerErrors } },
            players: { $set: players },
            payment: { errors: { $set: paymentErrors } },
            serverErrors: { $set: true },
            fetching: { $set: false }
        });
    }
    case "PLAYERS_FORM_ERRORS":

        return update(state, {
            players: {
                $apply: function (form) {

                    map(action.errors, (err, key) => {
                        form[key].errors = err;
                    });

                    return form;
                }
            }
        });

    case "TEAMS_FORM_ERRORS":

        return update(state, {
            teams: {
                $apply: function (form) {
                    map(action.errors, (err, key) => {
                        form[key].errors = err;
                    });

                    return form;
                }
            }
        });

    case "CLEAN_PLAYER_ERRORS":
        return update(state, {
            players: {
                $apply: form => {
                    form[action.key].errors = {};
                    return form;
                }
            }
        });

    case "TEAMS_PLAYER_ERRORS":
        return update(state, {
            teams: {
                $apply: form => {
                    form[action.key].errors = {};
                    return form;
                }
            }
        });

    case "CLEAN_PAYER_ERRORS":
        return update(state, {
            payer: { errors: { $set: {} } }
        });

    case "CLEAN_ERRORS":
        return update(state, {
            payer: { errors: { $set: {} } },
            payment: { errors: { $set: {} } },
            players: {
                $apply: function (form) {
                    map(form, (pl, key) => {
                        form[key].errors = {};
                    });

                    return form;
                }
            },
            serverErrors: { $set: false }
        });


    case "COPY_ORDER_FORM": {
        const fromObj = get(state, action.from);

        const copyObj = pickBy(fromObj, (value, key) => {

            if (includes(['first_name', 'last_name', 'email', 'gender'], key)) return true;
            if (key === 'birthday' && value) { //sovrascrivi solo se c'è il valore
                return true;
            }

            return false;
        });

        return update(state, {
            players: {
                [action.to]: { $merge: copyObj }
            }
        });
    }
    case "FETCHING_TICKETS_STORE":
        return update(state, { fetching: { $set: true } });

    case "RECEIVE_PROCESSED_ORDER": {

        let updateObj = { fetching: { $set: false } };
        if (!action.data.errors) {
            updateObj.step = { $set: 'completed' };
            updateObj.payment = { $set: action.data.payment };            
            // updateObj.discount = { $set: {} }; //do not clean here, we need to calc discount on order completed
        }

        persistJSON('competition.tickets', {}); //reset localStorage

        return update(state, updateObj);

    }
    case "RESTORE_TICKETS_STORE":
        if (action.data) {
            const tickets = action.data;
            tickets.fetching = false;
            return update(state, { $set: tickets });
        }
        return state;

    case "RECEIVE_PAYU_FORM":
        return update(state, {
            payment: { $merge: { form: action.form } }
        });

    case "RECEIVE_STRIPE_REQUIRED_ACTION":
        return update(state, {
            payment: {
                $merge: {
                    action: true,
                    client_key: action.data.client_key
                }
            }
        });
    case "SET_STRIPE_SCA_ERROR": {
        return update(state, {
            fetching: { $set: false },
            serverErrors: { $set: true },
            payment: {
                $merge: {
                    action: false,
                    client_key: null,
                    errors: {
                        '': {
                            attribute: 'serverError',
                            type: action.error.type,
                            message: action.error.message,
                            state: 'error',
                            value: '',
                            code: action.error.code
                        }
                    }
                }
            }
        })
    }

    case "RECEIVE_DISCOUNT_VALIDATION":
        return update(state, {
            fetching: { $set: false },
            discount: {
                $set: {
                    valid: action.valid,
                    percent: get(action, 'discount.percent', 0),
                    fixed: get(action, 'discount.fixed', 0),
                    code: get(action, 'discount.code', ""),
                }
            }
        });

    case "RESET_DISCOUNT":
        return persistJSON('competition.tickets', update(state, {
            discount: { $set: { valid: null, code: "" } }
        }));

    case "RECEIVE_TICKET":

        state = update(state, {
            $set: action.data
        });
        state.fetching = false;
        return state;

    case "REQUEST_TICKET_ERROR":

        return update(state, {
            fetching: { $set: false },
            payer: { $set: { errors: action.msg } },
            payment: { $set: {} },
            serverErrors: { $set: true },
        });

    case "REQUEST_TICKET":
        return update(state, {
            fetching: { $set: true }
        });

    case "CLEAN_TICKETS":
        return persistJSON('competition.tickets', ticketsInitState());


    case "FETCHING_GN_INSTALLMENTS":
        return update(state, {
            payment: { installmentsLoading: { $set: true } }
        })

    case "RECEIVE_GN_INSTALLMENTS":
        return {
            ...state,
            payment: {
                ...state.payment,
                installments: action.data,
                installmentsLoading: false
            }
        }

    case 'UPDATE_BOLETO_PAYOFF':

        return update(state, {
            payment: {
                transaction: {
                    payoff: {
                        banking_billet: { $merge: { expire_at: action.payoff.expire_at } },
                        charge: {
                            $merge: {
                                charge_id: action.payoff.charge_id,
                                link: action.payoff.link,
                                expire_at: action.payoff.expire_at
                            }
                        }
                    }
                }
            }
        });

    default:
        //console.log("return default state");
        return state;
    }
}

export default tickets;