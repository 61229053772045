import { connectRouter } from 'connected-react-router';
import { combineReducers } from "redux";
import Auth from './Auth';
import Common from "./Common";
import Competition from './Competition';
import CompetitionSearch from './CompetitionSearch';
import CompetitionsWithStandingSearch from './CompetitionsWithStandingSearch';
import UserSearch from './UserSearch';
import Race from './Race';
import Settings from "./Settings";
import Tickets from './Tickets';
import TicketsLimit from './TicketsLimit';
import User from './User';
import Morph from './Morph';
import CompetitionsWithFotopSearch from './CompetitionsWithFotopSearch';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth: Auth,
    settings: Settings,
    commonData: Common,
    competition: Competition,
    competitionSearch: CompetitionSearch,
    competitionsWithStandingSearch: CompetitionsWithStandingSearch,
    competitionsWithFotopSearch: CompetitionsWithFotopSearch,
    userSearch: UserSearch,
    morph: Morph,
    race: Race,
    tickets: Tickets,
    ticketsLimit: TicketsLimit,
    user:User
});
