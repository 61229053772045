import { Row, Col } from 'antd'
import { setPaymentMethod, updatePaymentForm } from 'appRedux/actions/Tickets'
import { each, get as _get, includes, isString, keys } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import revalidator from 'revalidator'
import { processBoletoGerencianetPayment } from '../../../../../client/tickets'
import TextField from '../../../../../components/Form/Legacy/TextField'
import PaymentValidator from '../validators/PaymentValidator'


const formSchema = {
    properties: {
        full_name: {
            type: 'string',
            required: true,
            allowEmpty: false
        },
        location: {
            type: 'integer',
            required: true,
            allowEmpty: false,
            message: "location required"
        },
        street: {
            type: 'string',
            required: true,
            allowEmpty: false
        },
        street_number: {
            type: 'string',
            required: true,
            allowEmpty: false
        },
        phone_number: {
            type: 'string',
            required: true,
            allowEmpty: false,
            pattern: /^[\d]+$/, //gc validation /^[1-9]{2}9?[0-9]{8}$/,
            minLength: 10,
            maxLength: 11,
            messages: {
                pattern: "only_digit_field_error"
            }
        },
        district: {
            type: 'string',
            required: true,
            allowEmpty: false
        },
        province: { //gc validation ^(?:A[CLPM]|BA|CE|DF|ES|GO|M[ATSG]|P[RBAEI]|R[JNSOR]|S[CEP]|TO)$
            type: 'string',
            required: true,
            minLength: 2,
            maxLength: 2,
            allowEmpty: false
        },
        cap: {
            type: 'string',
            required: true,
            allowEmpty: false,
            pattern: /^[\d]+$/, //gc validation ^[0-9]{8}$.,
            minLength: 8,
            maxLength: 8,
            messages: {
                pattern: "only_digit_field_error"
            }
        },
        fiscal_code: {
            type: 'string',
            required: true,
            pattern: /^[\d]+$/,
            minLength: 11,
            maxLength: 11,
            allowEmpty: false,
            messages: {
                pattern: "only_digit_field_error"
            }
        }
    }
};

class BoletoGerencianet extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        const { competition, user } = this.props;
        this.props.dispatch(setPaymentMethod({ active: "boleto", payments: competition.payments, user: user }));
    }

    parseGerencianetError(error) {
        var { t } = this.props;
        error.parsed_message = t("generic_error");
        if (error.description && error.description.property) {
            let field = error.description.property.slice(error.description.property.lastIndexOf('/') - error.description.property.length + 1);

            switch (field) {
            case 'zipcode':
                field = 'cap';
                break;
            case 'state':
                field = 'province';
                break;
            }

            error.parsed_message = 'invalid_' + field;
        } else if (isString(error.description)) {
            error.parsed_message = '___' + error.description;
        }

        return error;
    }

    handleSubmit(e) {
        e.preventDefault();
        var self = this;
        var { form } = this.state;

        var validationObj = {};
        each(this.state.form, function (f, k) {
            validationObj[k] = f.value;
        });

        var result = revalidator.validate(validationObj, formSchema);

        if (result.valid === false) {
            var properties = [];
            each(result.errors, function (e) {

                if (!includes(properties, e.property)) {
                    //get only first error => allowEmpty before pattern
                    form[e.property].validationState = 'error';

                    form[e.property].help = (e.attribute == 'pattern') ? self.props.t(e.message) : self.props.t('form_error_' + e.attribute, { value: e.expected });

                    //console.log(form);
                    properties.push(e.property);
                }
            });
            this.setState({ form: form });
        } else {

            this.setState({ loading: true });

            processBoletoGerencianetPayment({
                paymentToken: self.props.subscription.token,
                form: form
            }).then(function (response) {
                self.cleanCardErrors();

                if (response.error) {

                    const message = (response.data && response.data.is_gerencianet_error === true) ?
                        self.parseGerencianetError(response.data).parsed_message :
                        response.message;

                    self.setState({
                        paymentError: true,
                        paymentMessage: message,
                        loading: false
                    });

                } else {
                    //console.log(response);
                    window.open(
                        response.url,
                        '_blank'
                    );
                    //todo check it RR!!
                    //self.props.dispatch(setPaymentStatus('paid', response.url))
                }
            }).catch(() => {

                self.setState({
                    paymentError: true,
                    paymentMessage: self.props.t('generic_error'),
                    loading: false
                });
            })
        }


    }

    cleanCardErrors() {

        var { form } = this.state;

        each(keys(form), (k) => {
            delete form[k].validationState;
            form[k].help = '';
        });

        this.setState(form);
    }

    handleInputChange(field, event) {
        let value;
        if (field === 'location') {
            value = event;
        } else if (field === 'province') {
            value = _get(event.target, 'value', '').toUpperCase();
        } else {
            value = event.target.value;
        }

        const isValid = new PaymentValidator({ active: 'boleto', form: { [field]: value } }).validateField(field).isValid();

        this.props.dispatch(updatePaymentForm({
            value: value, field: field, valid: isValid
        }));
    }

    onLocationChange = (location) => {
        this.handleInputChange('location', location ? location.value : "");
    }

    render() {
        const { t, payment } = this.props;

        if (!payment.form) {
            return <h3 className="col-md-12 xs-m-10">{t('loading')}</h3>;
        }

        return (
            <div className="gx-text-left" style={{ padding: 15 }}>

                <Row>
                    <Col xs={24} md={24} className="gx-mb-4">
                        <TextField
                            type="text"
                            name="full_name"
                            label={t('full_name') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'full_name')}
                            value={payment.form.full_name}
                            validationState={_get(payment.errors, 'full_name.state')}
                            help={_get(payment.errors, 'full_name.message')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={18} className="gx-mb-4 gx-pr-0 gx-pr-md-3">
                        <TextField
                            type="text"
                            name="city"
                            label={t('residence_location') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'city')}
                            value={payment.form.city}
                            validationState={_get(payment.errors, 'city.state')}
                            help={_get(payment.errors, 'city.message', " ")}
                        />
                    </Col>
                    <Col xs={24} md={6} className="gx-mb-4" >
                        <TextField
                            type="text"
                            name="phone_number"
                            label={t('phone') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'phone_number')}
                            value={payment.form.phone_number}
                            validationState={_get(payment.errors, 'phone_number.state')}
                            help={_get(payment.errors, 'phone_number.message')} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={18} className="gx-mb-4 gx-pr-0 gx-pr-md-3">
                        <TextField
                            type="text"
                            name="street"
                            label={t('street') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'street')}
                            value={payment.form.street}
                            validationState={_get(payment.errors, 'street.state')}
                            help={_get(payment.errors, 'street.message')} />
                    </Col>
                    <Col xs={24} md={6} className="gx-mb-4 ">
                        <TextField
                            type="text"
                            name="street_number"
                            label={t('street_number') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'street_number')}
                            value={payment.form.street_number}
                            validationState={_get(payment.errors, 'street_number.state')}
                            help={_get(payment.errors, 'street_number.message')} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={6} className="gx-mb-4 gx-pr-0 gx-pr-md-3">
                        <TextField type="text"
                            name="province"
                            label={t('province') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'province')}
                            value={payment.form.province}
                            validationState={_get(payment.errors, 'province.state')}
                            help="" />
                    </Col>
                    <Col xs={24} md={18} className="gx-mb-4">
                        <TextField
                            type="text"
                            name="district"
                            label={t('district') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'district')}
                            value={payment.form.district}
                            validationState={_get(payment.errors, 'district.state')}
                            help="" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={8} className="gx-mb-4 gx-pr-0 gx-pr-md-3" >
                        <TextField
                            type="text"
                            name="cap"
                            label={t('cap') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'cap')}
                            value={payment.form.cap}
                            validationState={_get(payment.errors, 'cap.state')}
                            help={_get(payment.errors, 'cap.message')} />
                    </Col>
                    <Col xs={24} md={16} className="gx-mb-4 ">
                        <TextField
                            type="text"
                            name="fiscal_code"
                            label={t('fiscal_code') + ' *'}
                            onChange={this.handleInputChange.bind(this, 'fiscal_code')}
                            value={payment.form.fiscal_code}
                            validationState={_get(payment.errors, 'fiscal_code.state')}
                            help={_get(payment.errors, 'fiscal_code.message')} />
                    </Col>
                </Row>
            </div>
        )
    }

}

const mapStateToProps = function (state) {
    return {
        payment: state.tickets.payment
    };
};

export default connect(mapStateToProps)(withTranslation(['common'])(BoletoGerencianet))
