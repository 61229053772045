import revalidator from 'revalidator';
import BaseValidator from './BaseValidator';

const schemas = {
    stripe: {
        properties: {
            card_holder: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            /*cvc: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            exp_month: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            exp_year: {
                type: 'string',
                required: true,
                allowEmpty: false
            }*/
        }
    },
    'card-gerencianet': {
        properties: {
            cvc: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            exp_month: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            exp_year: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            city: {
                type: 'string',
                required: true,
                allowEmpty: false,
            },
            street: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            street_number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            phone_number: {
                type: 'string',
                required: true,
                allowEmpty: false,
                pattern: /^[\d]+$/, //gc validation /^[1-9]{2}9?[0-9]{8}$/,
                minLength: 10,
                maxLength: 11,
                messages: {
                    pattern: "only_digit_field_error"
                }
            },
            district: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            province: { //gc validation ^(?:A[CLPM]|BA|CE|DF|ES|GO|M[ATSG]|P[RBAEI]|R[JNSOR]|S[CEP]|TO)$
                type: 'string',
                required: true,
                minLength: 2,
                maxLength: 2,
                allowEmpty: false
            },
            cap: {
                type: 'string',
                required: true,
                allowEmpty: false,
                pattern: /^[\d]+$/, //gc validation ^[0-9]{8}$.,
                minLength: 8,
                maxLength: 8,
                messages: {
                    pattern: "only_digit_field_error"
                }
            },
            fiscal_code: {
                type: 'string',
                required: true,
                pattern: /^[\d]+$/,
                minLength: 11,
                maxLength: 11,
                allowEmpty: false,
                messages: {
                    pattern: "only_digit_field_error"
                }
            }
        }
    },
    boleto: {
        properties: {
            full_name: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            city: {
                type: 'string',
                required: true,
                allowEmpty: false,
            },
            street: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            street_number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            phone_number: {
                type: 'string',
                required: true,
                allowEmpty: false,
                pattern: /^[\d]+$/, //gc validation /^[1-9]{2}9?[0-9]{8}$/,
                minLength: 10,
                maxLength: 11,
                messages: {
                    pattern: "only_digit_field_error"
                }
            },
            district: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            province: { //gc validation ^(?:A[CLPM]|BA|CE|DF|ES|GO|M[ATSG]|P[RBAEI]|R[JNSOR]|S[CEP]|TO)$
                type: 'string',
                required: true,
                minLength: 2,
                maxLength: 2,
                allowEmpty: false
            },
            cap: {
                type: 'string',
                required: true,
                allowEmpty: false,
                pattern: /^[\d]+$/, //gc validation ^[0-9]{8}$.,
                minLength: 8,
                maxLength: 8,
                messages: {
                    pattern: "only_digit_field_error"
                }
            },
            fiscal_code: {
                type: 'string',
                required: true,
                pattern: /^[\d]+$/,
                minLength: 11,
                maxLength: 11,
                allowEmpty: false,
                messages: {
                    pattern: "only_digit_field_error"
                }
            }
        }
    }
};

export default class PaymentValidator extends BaseValidator {

    constructor(payment) {
        super();
        this.slug = payment.active;
        this.values = payment.form;
        this.result = { errors: [], valid: true }
    }

    validate() {
        if (schemas[ this.slug ]) {
            this.result = revalidator.validate(this.values, schemas[ this.slug ]);
        }
        return this;
    }

    validateField(field) {
        if (schemas[ this.slug ].properties[ field ]) {
            this.result = revalidator.validate(this.values, { properties: { [field]: schemas[ this.slug ].properties[ field ] } });
        }
        return this;
    }
}

export function fieldKeyToTransKey(key) {
    const keyToTrans = {
        'number': 'card_number',
        'exp_month': 'expire_date_month',
        'exp_year': 'expire_date_year'
    };
    if (keyToTrans[ key ]) {
        return keyToTrans[ key ]
    }
    return key;
}

