import React from 'react';
import { withTranslation } from "react-i18next";

class GenericPaymentCompletedInformation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, payment } = this.props;

        if (payment.paid) {
            return (
                <div>
                    {t('payment_successfully_completed')}
                </div>
            )
        } else {
            return (
                <div>
                    {t('payment_not_executed')}
                </div>
            )
        }
    }
}

export default withTranslation([ 'common','payment' ])(GenericPaymentCompletedInformation);