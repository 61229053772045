import { Card, Col } from "antd";
import React from 'react';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDescriptions, { CardDescriptionsItem } from "../../../../components/Descriptions/CardDescription";
import { hasTeams } from "../../../../util/Tickets";


const TeamData = ({ tickets, competition, t }) => {
    
    if (!hasTeams(tickets, competition)) return null;
    
    return (
        <Col xs={24} >
            <Card className="gx-card" title={t('teams_information')}>
                {map(tickets.teams, (team) => {
                    return (
                        <CardDescriptions column={2} className="ks-descriptions" >
                            <CardDescriptionsItem label={t('team_name_for_contest', { value: team.raceName })}>{team.name}</CardDescriptionsItem>
                        </CardDescriptions>
                    )
                })}

            </Card>
        </Col>
    )
}

TeamData.defaultProps = {};

export default withTranslation(['common'])(TeamData)
