import { searchUsers, searchUsersPublic } from "../../client/search";


export function requestUserItems(params) {
    return {
        type: "REQUEST_USER_ITEMS",
        data: params
    }
}

function receiveUserItems(response) {
    return {
        type: "RECEIVE_USER_ITEMS",
        data: response,
        receivedAt: Date.now()
    }
}

export function performUserSearch(params) {

    return dispatch => {

        dispatch(requestUserItems(params));
        //force org
        params.usertype = 4;
        
        const call = params.public ? searchUsersPublic(params) : searchUsers(params)        

        return call
            .then(res => res.data)
            .then(response => {
                dispatch(receiveUserItems(response));
            }).catch((err, msg) => {
                console.log(err, msg)
            })
    }
}
