/**
 * Created by ruzz on 18/02/16.
 */
import { Card } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { string } from 'util/index';
import Empty from '../Widgets/Empty';
import { isString } from 'lodash';
import LexicalEditorViewer from '../LexicalEditor/Viewer';



const EventDescription = ({ i18n, description, t }) => {

    function getEditorState() {

        const lang = i18n.language;

        const json = string.getTranslation(description, lang);        

        if (!isString(json)) return null

        return string.safeParseJSON(json);

    }

    const editorState = getEditorState();    

    return (
        <Card className="gx-card" title={t('description')}>
            {editorState ? <LexicalEditorViewer editorState={editorState} /> : <Empty />}
        </Card>
    )

}

export default withTranslation()(EventDescription)
