import React, { useContext } from 'react';
import { isObject, keys } from 'lodash';

const DescriptionContext = React.createContext();

export const CardDescriptionsItem = ({ label, children, column = null }) => {    

    const context = useContext(DescriptionContext)

    column = column ? column : context.column;

    let colClasses = ""

    if (isObject(column)) {
        colClasses = keys(column).map(k => `ant-col-${k}-${24 / column[k]}`).join(' ');
    } else {
        colClasses = `ant-col-xs-${24 / column}`;
    }    

    return (
        <div className={`ks-descriptions-item ant-col ${colClasses}`}>
            <div className="label">{label}</div>
            <div className="value">{children || '-'}</div>
        </div>
    )
}

const CardDescriptions = ({ column = 2, children }) => {
    return (
        <div className="ks-card-descriptions ant-row">
            <DescriptionContext.Provider value={{ column: column }}>
                {children}
            </DescriptionContext.Provider>
        </div >
    )
}

export default CardDescriptions;

