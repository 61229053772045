import { Layout } from 'antd';
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { switchLanguage, toggleCollapsedSideNav } from "../../../appRedux/actions/Setting";
import { isLoggedUser } from '../../../util/user';
import HorizontalNav from "../HorizontalNav";
import PrivateHeader from './PrivateHeader';
import PublicHeader from './PublicHeader';

const { Header } = Layout;


const KsHeader = ({ user = null, auth, morph = null }) => {

    const dispatch = useDispatch();
  
    const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);    
  
    return (
        <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
            <Header
                className="gx-header-horizontal-main">
                <div className="gx-pt-4 gx-pb-4 gx-pt-lg-2 gx-pb-lg-2 gx-pr-2 gx-pl-2 gx-pr-lg-4 gx-pl-lg-4 __gx-container">
                    <div className="gx-header-horizontal-main-flex">
                        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                            <i className="gx-icon-btn icon icon-menu"
                                onClick={() => {
                                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                                }}
                            />
                        </div>
                        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
                            <img alt="" src={require("assets/images/logo/keepsporting_mobile.png")} /></Link>
                        <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
                            <img alt="" src={require("assets/images/logo/keepsporting-top.png")} /></Link>          

                        <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                            <HorizontalNav user={user}/>              
                        </div>
                        {isLoggedUser(user) ? <PrivateHeader user={user} morph={morph} auth={auth} /> : <PublicHeader />}
                    </div>
                </div>
            </Header>
        </div>
    );
};

const mapStateToProps = ({ settings }) => {
    const { locale, navCollapsed } = settings;
    return { locale, navCollapsed }
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(KsHeader);
