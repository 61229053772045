import { Select } from 'antd';
import React from 'react';
const { Option } = Select;

//value undefined lead to uncontrolled component

export const emptyOption = (text = null) => {
    return { value: '', text: text ? text : '-' };
};

const SelectField = ({
    name,
    value = undefined,
    label,
    labelClassName = '',
    className = '',
    onChange,
    options = [],
    validationState = null,
    help = '',
    disabled = false,
    required = false,
    ...rest
}) => {
    return (
        <div className={`${className} ${validationState ? `has-${validationState}` : ''}`}>
            <label className={labelClassName}>
                {label} {required ? '*' : ''}
            </label>
            <Select disabled={disabled} className="gx-d-block" onChange={onChange} value={value} name={name} {...rest}>
                {options.map((o) => {
                    return (
                        <Option key={`o_${o.value}`} value={o.value}>
                            {o.text}
                        </Option>
                    );
                })}
            </Select>

            {help && <div className="help-block gx-mt-2">{help}</div>}
        </div>
    );
};
export default SelectField;
