
import { get } from 'lodash';
import React from 'react';
import { EVENT } from '../../constants/App';
//import IntlMessages from "../../util/IntlMessages";
import { FormatPrice } from '../../util/price';

const Price = ({ price, competition, currency = null, showFree = true, team = false }) => {
    
    let text;

    const currentPrice = team ? price.teamPrice : price.price;

    if (currentPrice === 0 && showFree) {
        if (competition.type === EVENT.TYPE.TRAVEL) {
            text = '';
        } else {
            text = '-'; //<IntlMessages id="free" />;
        }
    } else if (!currentPrice) {
        text = '-';
    } else {
        const _currency = get(competition, 'payments.currency.iso', currency);
        text = <FormatPrice price={currentPrice} currency={_currency} />;
    }

    return <span >{text}</span>

}


export default Price
