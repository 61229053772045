import { Card, Col, Row } from "antd";
import { updatePayerForm } from "appRedux/actions/Tickets";
import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import TextField from "../../../../components/Form/Legacy/TextField";
import PayerValidator from "./validators/PayerValidator";

/**
 * user null if not logged in otherwise obj 
 */
class PayerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onChangeEmail(e) {
        this.onFormChange('email', e.target.value);
    }

    onRepeatChangeEmail(e) {
        this.onFormChange('repeat_email', e.target.value, null, {
            repeat_email: e.target.value,
            email: this.props.form.email
        });
    }

    onChangeFirstName(e) {
        this.onFormChange('first_name', e.target.value);
    }

    onChangeLastName(e) {
        this.onFormChange('last_name', e.target.value);
    }


    /**
     * @param {string} field
     * @param {string} value
     * @param {object} payoff //used for location name
     * @param validationObj //to validate repeat email and pass email value to match
     */
    onFormChange(field, value, payoff = null, validationObj = null) {
        let vObj;
        if (validationObj) {
            vObj = validationObj;
        } else {
            vObj = { [field]: value };
        }

        const isValid = new PayerValidator(vObj, this.props.user).validateField(field).isValid();

        this.props.dispatch(updatePayerForm({ field: field, value: value, valid: isValid, payoff }));
    }

    render() {
        const { form, user, t } = this.props;

        return (

            <Card title={t('tickets_payer')} className="gx-card">
                <Row>
                    <Col xs={24} sm={12} className="gx-mb-3" >
                        <TextField 
                            name="first_name" 
                            type="text"
                            onChange={this.onChangeFirstName.bind(this)}
                            label={t('first_name') + ' *'}
                            value={form.first_name}
                            validationState={get(form.errors, 'first_name.state', null)}
                            help={get(form.errors, 'first_name.message', " ")}
                            className="gx-mr-2"
                        />
                    </Col>
                    <Col xs={24} sm={12} className="gx-mb-3" >
                        <TextField name="last_name" 
                            type="text" 
                            className="gx-ml-0 gx-ml-sm-3"
                            label={t('last_name') + ' *'}
                            onChange={this.onChangeLastName.bind(this)}
                            value={form.last_name}
                            validationState={get(form.errors, 'last_name.state', null)}
                            help={get(form.errors, 'last_name.message', " ")}
                        />
                    </Col>
                    <div className="clearfix"></div>

                    <Col xs={24} className="gx-mb-3">
                        <TextField 
                            name="email" 
                            type="email" 
                            onChange={this.onChangeEmail.bind(this)}
                            label={t('email') + ' *'}
                            value={form.email}
                            validationState={get(form.errors, 'email.state', null)}
                            help={get(form.errors, 'email.message', " ")}
                        />
                    </Col>
                    {!user &&
                        <Col xs={24} className="gx-mb-3">
                            <TextField name="repeat_email"
                                type="email"
                                onChange={this.onRepeatChangeEmail.bind(this)}
                                label={t('repeat_email') + ' *'}
                                value={form.repeat_email}
                                validationState={get(form.errors, 'repeat_email.state', null)}
                                help={get(form.errors, 'repeat_email.message', " ")}
                                onPaste={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                            />
                        </Col>
                    }
                    <div className="clearfix"></div>

                </Row>
            </Card>

        )
    }

}

const mapStateToProps = function (state) {
    return {
        form: state.tickets.payer
    }
};

export default connect(mapStateToProps)(withTranslation()(PayerForm))
