import { FileOutlined, GlobalOutlined, HeartOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Descriptions } from 'antd';
import { truncate } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { date, string } from 'util/index';
import IntlMessages from '../../../util/IntlMessages';
import { eventUrl } from '../../../util/url';
import { Link } from 'react-router-dom';

class InformationBox extends Component {

    constructor(props, context) {
        super(props, context);
    }

    startDate(competition) {

        if(competition.hide_date){
            return null;
        }

        const { t } = this.props;
        const startDate = (competition.startDate) ? date.fromTimestamp(competition.startDate.$date.$numberLong / 1000, 'dddd, DD MMMM YYYY', 'UTC', false) : " ";
        return (
            <Descriptions.Item label={<><MailOutlined />&nbsp;{t('event_date')}</>}>
                {startDate}
            </Descriptions.Item>
        )
    }

    sport(competition) {
        return (
            <Descriptions.Item label={<><HeartOutlined />&nbsp;<IntlMessages id="sport" /></>}>
                {this.props.t(`sports:sport_${competition.sport}`)}
            </Descriptions.Item>
        )
    }

    contacts() {

        if (!this.props.competition.contacts) {
            return null;
        }

        const { i18n, competition } = this.props;

        const getValue = (type, value) => {
            switch (type) {
            case "contacts_8": //web url
                return <a style={{ display: 'inline-block' }} rel="noreferrer" target="_blank" href={value}>{truncate(value, { length: 32 })}</a>;
            case "contacts_2": //mail
                return <a style={{ display: 'inline-block' }} href={`mailto:${value}`}>{value}</a>
            default:
                return truncate(value, { length: 32 });
            }
        }

        const getIcon = (type) => {
            switch (type) {
            case "contacts_1":
                return <FileOutlined />
            case "contacts_2":
                return <MailOutlined />;
            case "contacts_3":
                return <PhoneOutlined />
            case "contacts_8":
                return <GlobalOutlined />;
            default:
                return <UserOutlined />
            }
        }

        return competition.contacts.map((c) => {
            let type = 'contacts_' + c.type;
            let key = c.type + '_' + c.value;
            let label;
            if (c.description) {
                label = string.getTranslation(c.description, i18n.language);
                if (!label) {
                    label = type;
                }
            }

            return (
                <Descriptions.Item key={key} label={<>{getIcon(type)}&nbsp;<IntlMessages id={label} /></>}>
                    {getValue(type, c.value)}
                </Descriptions.Item>
            )
        })
    }

    renderOrganizationLink(organization) {
        if (organization.ksId) {
            return (
                <Descriptions.Item label={<IntlMessages id="organizer" />} >
                    <a href={`/profile/${organization.ksId}/${organization.slug}`}>{organization.name}</a>
                </Descriptions.Item>
            )
        } else {
            return (
                <Descriptions.Item label={<IntlMessages id="organizer" />}>
                    {organization.name}
                </Descriptions.Item>
            )
        }
    }

    render() {
        let { competition, cover, className } = this.props;

        let { organization } = competition;

        let metaTitle = null, title = null;
        if (cover) {
            metaTitle = <Card.Meta className="gx-pb-3"
                title={<Link to={eventUrl(competition)}>{competition.name}</Link>}
            />
        } else {
            title = <h3 className="title nomargin">{<IntlMessages id="information_box" />}</h3>
        }

        return (
            <Card cover={cover} className={`gx-card ${className}`} title={title}>
                {metaTitle}
                <Descriptions className="gx-block-description" column={1} >
                    {this.renderOrganizationLink(organization)}
                    {this.sport(competition)}
                    {this.startDate(competition)}
                    {this.contacts()}
                </Descriptions>
            </Card>
        )
    }
}

InformationBox.defaultProps = {
    className: "",
    cover: null,
    competition: {}
}

export default withTranslation()(InformationBox)
