import { getTicketLimits } from "../../client/tickets";

function receiveTicketLimits(data) {
    return {
        type: "RECEIVE_TICKET_LIMITS",
        data: data
    }
}

export function fetchTicketLimits(compId) {
    return dispatch => {
        //dispatch(requestTicketLimits(params));
        return getTicketLimits(compId)
            .then(response => {
                dispatch(receiveTicketLimits(response.data));
            }).catch(() => {
            })
    }
}