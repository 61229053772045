/**
 * Created by ruzz on 25/02/16.
 */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '../TextField';

class CustomNote extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: ""
        }
    }

    componentDidMount() { }

    handleChange(e) {
        var v = e.target.value;
        this.setState({
            value: v
        });
        this.props.cb(v, this.props.field.name);
    }

    render() {
        var field = this.props.field;
        var label = field.label + ((field.required === true) ? ' *' : '');
        return (
            <TextField
                value={this.props.value}
                name={field.name}
                type="textarea"
                label={label}
                onChange={this.handleChange.bind(this)} validationState={this.props.validationState} />
        )
    }
}

export default withTranslation()(CustomNote)
