import { get, includes, upperFirst, find, keys, startsWith } from 'lodash';
import { SUBSCRIPTION } from '../constants/Tickets';

const getCustomFields = (player) => {
    return keys(player).filter(k => startsWith(k, 'custom_field_') || startsWith(k, 'cf_'));
}

export function customFields(race, player, t = () => { }) {
    let customFields = [];

    if (!race.customFields || race.customFields.length === 0) {
        return customFields;
    }

    race.customFields.forEach((cf) => {
        let customField = {
            label: '',
            value: ''
        };

        let _keys = getCustomFields(player);

        const found = includes(_keys, cf['name']);

        customField.label = upperFirst(cf.label);
      
        if (found) {
            let playerValue = player[cf.name];

            if (cf.type === 'select' || cf.type === 'select_choice') {
                const selectedOpt = find(cf.options, (opt) => opt.value == playerValue)
                if (selectedOpt) {
                    playerValue = selectedOpt['label'];
                }
            } else if (cf.type === 'checkbox' || cf.type === 'check_box') {
                playerValue = t('common:' + playerValue ? playerValue : 'no');
            }

            customField.value = playerValue;
        }

        if (!customField.value) {
            customField.value = '###';
        }

        customFields.push(customField);
    })

    return customFields;
}

export function getAthleteCard(race, player) {

    if (!player.athlete_card) {
        return null;
    }

    const options = get(race, 'standardFields.athlete_card.options', []);

    if (!options) return null;

    let card = null;

    for (let option of options) {
        if (player.athlete_card === option.value) {
            card = option;
            break;
        }
    }

    return card;
}

export const getAthleteCardOrganization = (player, card) => {
    if (!player.athlete_card_provider_organization_name) return '';
    if (!card) return player.athlete_card_provider_organization_name;

    if (card.type === SUBSCRIPTION.ATHLETE_CARDS.RUNCARD) {
        return card.type.toUpperCase();
    }

    return player.athlete_card_provider_organization_name;
}