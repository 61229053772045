import React from 'react';
import { withTranslation } from 'react-i18next';
import BankCompletedInformation from './BankCompletedInformation';
import BoletoCompletedInformation from './BoletoCompletedInformation';
import CardGerencianetCompletedInformation from './CardGerencianetCompletedInformation';
import CashPaymentCompletedInformation from './CashPaymentCompletedInformation';
import FreePaymentCompletedInformation from './FreePaymentCompletedInformation';
import GenericPaymentCompletedInformation from './GenericPaymentCompletedInformation';
import PaypalCompletedInformation from './PaypalCompletedInformation';
import PayuCompletedInformation from './PayuCompletedInformation';
import StripeCompletedInformation from './StripeCompletedInformation';
import PagaditoCompletedInformation from './PagaditoCompletedInformation';

class PaymentCompletedWrapper extends React.Component {
    render() {
        const { gateway, tickets } = this.props;

        switch (gateway) {
        case 'boleto':
            return <BoletoCompletedInformation ticketsId={tickets.id} payment={tickets.payment} {...this.props} />;
        case 'stripe':
            return <StripeCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'bank':
            return <BankCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'paypal':
            return <PaypalCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'payu':
            return <PayuCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'card-gerencianet':
            return <CardGerencianetCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'cash':
            return <CashPaymentCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'free':
            return <FreePaymentCompletedInformation payment={tickets.payment} {...this.props} />;
        case 'pagadito':
            return <PagaditoCompletedInformation payment={tickets.payment} {...this.props} />;
        default:
            return <GenericPaymentCompletedInformation payment={tickets.payment} {...this.props} />;
        }
    }
}

export default withTranslation(['common'])(PaymentCompletedWrapper);
