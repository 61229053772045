import React, { useEffect, useState } from 'react'
import { Card, Button, message } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setRegisteringUser } from '../../appRedux/actions/User';
import { replace } from 'react-router-redux';
import { userStatusByRuid, postResendActivation } from '../../client/auth';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { genericFormErrorHandler } from '../../util/form';

const VerifyAccount = ({ match, t }) => {

    const user = useSelector(({ user }) => user);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {

        if (user.id) return;

        const ruid = match.params.id;

        userStatusByRuid(ruid)
            .then(res => res.data)
            .then(data => {
                if (data.active === 1) {
                    message.success(t('account_already_activated'))
                    dispatch(replace(`/login`));
                }
                dispatch(setRegisteringUser(data))
            })
            .catch(err => {
                const errors = get(err, 'response.data.errors', null);
                if (errors && errors.code && errors.code.length > 0) {
                    if (errors.code[0] == 1) {
                        dispatch(replace('/login'));
                    }
                } else {
                    dispatch(replace('/'));
                }
            })
    }, [dispatch, user])

    const resendActivation = () => {

        postResendActivation(user)
            .then(r => r.data)
            .then(() => {
                setLoading(false);
                message.success(t('verification_email_sent'))
            })
            .catch((err) => {
                setLoading(false);

                const userError = get(err, 'response.data.errors.user');
                if (userError && userError.length > 0 && userError[0] === 'account_already_activated') {
                    message.success(t('account_already_activated'))
                    dispatch(replace(`/login`));
                } else {
                    genericFormErrorHandler(err, t)
                }
            })
    }

    return (
        <Card title={t('verify_email')}>
            <p>
                <Trans
                    i18nKey="s_check_your_mailbox"
                    values={[user.username]}
                    components={[<strong key="1">univers</strong>]}
                />
            </p>
            <p>{t('pre_register_complete')}</p>

            <Button loading={loading} type="primary" onClick={resendActivation}  >{t('resend_activation')}</Button>
        </Card>
    )
}

export default withTranslation()(VerifyAccount);