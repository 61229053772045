
import { find, get } from 'lodash';

export const getProviders = (payments) => {
    return get(payments, 'providers', []);
}

export const getProviderBySlug = (payments, slug) => {

    const providers = getProviders(payments);

    if (providers.length > 0) {
        return find(providers, function(provider) {
            return provider.slug === slug;
        });
    }
    return null;
}

export const getTypeByPaymentSlug = slug => {
    let id;
    switch (slug) {
    case 'bank':
        id = 1;
        break;
    case 'stripe':
        id = 2;
        break;
    case 'paypal':
        id = 3;
        break;
    case 'manual':
        id = 4;
        break;
    case 'boleto':
        id = 5;
        break;
    case 'payu':
        id = 7;
        break;
    case 'free':
        id = 6;
        break;
    case 'card-gerencianet':
        id = 8;
        break;
    case 'cash':
        id = 9;
        break;
    case 'pagadito':
        id = 10;
        break;
    default:
        id = 0;
        break;
    }
    return id;
};
