import { Col, notification, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { isEmpty, get } from 'lodash';
import { Route, Switch, Redirect } from "react-router-dom";
import { fetchCompetition } from "../../appRedux/actions/Competition";
import { FullPageLoader } from "../../components/CircularProgress";
import BackgroundCover from './BackgroundCover';
import './styles/app.css';
import Tickets from './Tickets';
import Main from './Main';
import { withTranslation } from 'react-i18next';
import { getOrderIdFromPathname } from "../../util/url";
import { Head } from "../../containers/App/Head";
import { lazyWithRetry } from "../../util/router";

const Event = ({ match, location, t }) => {

    const isBackgroundVisible = (match) => {
        return (
            !matchPath(location.pathname, { path: `${match.url}/subscribe`, strict: false }) &&
            !matchPath(location.pathname, { path: `${match.url}/standings`, strict: true })
        )
    }

    const dispatch = useDispatch();
    const eventId = match.params.id;

    useEffect(() => {

        const scraped = false;
        const orderId = getOrderIdFromPathname(location.pathname);

        dispatch(fetchCompetition(eventId, { scraped: scraped, orderId: orderId }));

    }, [])

    const competition = useSelector((state) => state.competition);
    const commonData = useSelector((state) => state.commonData);

    if (!commonData.loading && !isEmpty(commonData.error)) {
        notification.error({ message: t('page_not_found') })
        return <Redirect to="/events" />
    } else if (commonData.loading || isEmpty(competition.data)) { ///rimuovendo isEmpty(competition.data) al refresh in acquisto da errore (check)
        return <FullPageLoader />
    }

    return (
        <>
            {competition.data && competition.data.meta &&
                <Head
                    title={competition.data.meta.title}
                    description={competition.data.meta.description}
                    image={get(competition, 'data.meta.images.original')}
                    og={{
                        title: competition.data.meta.title,
                        description: competition.data.meta.description,
                        image: get(competition, 'data.meta.images.medium'),
                    }}
                />
            }
            <Row >
                {isBackgroundVisible(match) && <BackgroundCover match={match} competition={competition.data} />}
                <Col xs={24}>
                    <Switch>
                        <Route exact path={`${match.url}`} component={Main} />
                        <Route exact path={`${match.url}/standings`} component={lazyWithRetry(() => import('./Standings'))} />
                        <Route path={`${match.url}/subscribe`} component={Tickets} />
                        <Route path={`${match.url}/contest/:id`} component={lazyWithRetry(() => import('./Contest'))} />
                    </Switch>
                </Col>
            </Row>
        </>
    )
};

export default withTranslation(['common'])(Event);
