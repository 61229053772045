import { faBicycle, faBus, faCar, faMale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from 'config';
import GoogleMap from 'google-map-react';
import React, { Component } from "react";
import { location as locationUtil } from 'util/index';
import KsMarker from './KsMarker';

class LocationMap extends Component {

    constructor(props, context) {
        super(props, context);
    }
    
    onMapLoad() {}

    render() {
        var { event, width, height, showName, showMap, className } = this.props;

        var { location } = event;

        if (!location.coords) {
            return <span></span>
        }

        var coords = location.coords.split(',');
        const locationName = locationUtil.formatLocationName(location);
        let googleTravelUrl = `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${location.coords}&travelmode=`;
        const googlePlaceUrl = `https://www.google.com/maps/search/${encodeURIComponent(locationName)}`;//`http://www.google.com/maps/place/${location.coords}`
        return (
            <div className={className}>
                {showMap &&
                    <div style={{ width: width, height: height }}>
                        <GoogleMap
                            bootstrapURLKeys={{ key: [config.googleMapsKey] }}
                            onGoogleApiLoaded={this.onMapLoad.bind(this)}
                            zoom={this.props.zoom}
                            center={{ lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) }}>
                            <KsMarker lat={parseFloat(coords[0])} lng={parseFloat(coords[1])} />
                        </GoogleMap>
                    </div>
                }
                <div className={'map-directions'}>
                    {showName &&
                        <div className={'map-directions-cname'}>
                            <span>{event.name}</span>
                        </div>
                    }
                    <div className={'map-directions-location'}>
                        <a href={googlePlaceUrl} target="_blank" rel="noreferrer">
                            <span className="v4">
                                {location.state && <i style={{ marginTop: 2 }} className={`list-flag flag-${location.state.toLowerCase()}`}></i>}
                                {locationName}
                            </span>
                        </a>
                    </div>
                    <div className={'map-directions-icons'}>
                        <a href={googleTravelUrl + 'driving'} target={'_blank'} rel="noreferrer">
                            <i className={'icon-car'} />
                            <FontAwesomeIcon icon={faCar} />
                        </a>
                        <a href={googleTravelUrl + 'walking'} target={'_blank'} rel="noreferrer">
                            <FontAwesomeIcon icon={faMale} />
                        </a>
                        <a href={googleTravelUrl + 'transit'} target={'_blank'} rel="noreferrer">
                            <FontAwesomeIcon icon={faBus} />
                        </a>
                        <a href={googleTravelUrl + 'bicycling'} target={'_blank'} rel="noreferrer">
                            <FontAwesomeIcon icon={faBicycle} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

LocationMap.defaultProps = {
    zoom: 15,
    showName: true,
    showMap: true, 
    className: ''
};

export default LocationMap