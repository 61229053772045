import React from 'react';
import { Helmet } from "react-helmet";

import { HEAD, APP_NAME } from "../../constants/App";

const DefaultHead = ({ language, t }) => {
    return (
        <Helmet htmlAttributes={{
            lang: language,
        }}
        >
            <meta charSet="utf-8" />
            <title>{t('seo:default.title')}</title>
            <meta name="description" content={t('seo:default.description')} />
            <link rel="canonical" href={process.env.REACT_APP_URL} />
            <meta property="og:title" content={t('seo:default.title')} />
            <meta property="og:site_name" content={APP_NAME} />
            <meta property="og:description" content={t('seo:default.description')} />
            <meta property="og:url" content={process.env.REACT_APP_URL} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={`${process.env.REACT_APP_URL}/assets/img/ks_fb.jpg`} />
            <link rel="image_src" href={`${process.env.REACT_APP_URL}/assets/img/ks_fb.jpg`} />
            <meta property="fb:app_id" content="208081012094" />
        </Helmet>
    )
}

const Head = ({ title, description, image, canonical, og }) => {

    if (!og) {
        og = { title, description }
    }

    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {canonical && <link rel="canonical" href={canonical} />}
            {image && <link rel="image_src" href={image} />}
            {og &&
                [
                    <meta key="og:title" property="og:title" content={og.title} />,
                    <meta key="og:description" property="og:description" content={og.description} />,
                    og.url ? <meta key="og:url" property="og:url" content={og.url} /> : null,
                    <meta key="og:image" property="og:image" content={og.image} />,
                ]
            }
        </Helmet>
    )
}

Head.defaultProps = {
    title: HEAD.TITLE,
    image: null,
    description: HEAD.DESCRIPTION,
    canonical: null,
    og: null
}

export { DefaultHead, Head };



