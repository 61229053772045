/**
 * @deprecated
 * @param {*} tickets 
 * @param {*} t 
 * @param {*} callback 
 */
export const _validateStripe = (tickets, t, callback) => {
    const card = tickets.payment.form;

    window.Stripe.createToken({
        name: card.card_holder,
        number: card.number ? card.number.trim() : '',
        cvc: card.cvc ? card.cvc.trim() : '',
        exp_month: parseInt(card.exp_month.trim()),
        exp_year: parseInt(card.exp_year.trim())

    }, function (status, response) {
        
        if (response.error) {

            var error = readStripeError(response.error, t);

            if (card[error.key]) {

                callback({
                    [error.key]: { value: card[error.key], message: error.message, attribute: 'stripeServerError', state: 'error' }
                });

            } else {
                callback({
                    generic: { value: card[error.key], message: t('stripe_' + response.error.code), attribute: 'stripeGenericServerError', state: 'error' }
                })
            }
        } else {
            callback(null, response.id)
        }
    });
};

export const validateStripeElements = (tickets, stripeInstance, card, language, callback) => {

    stripeInstance
        .createPaymentMethod('card', card[0], { billing_details: { name: tickets.payment.form.card_holder } })
        .then(response => {
            console.log("createPaymentMethod", response);
            if (response.error) {

                const message = readStripeElementsError(response.error, language);

                callback({
                    credit_card: { value: null, message: message, attribute: 'stripeGenericServerError', state: 'error' }
                })

            } else {
                callback(null, response.paymentMethod)
            }
        });
};


//ref https://stackoverflow.com/questions/23437439/non-english-texts-in-stripe-possible
export const onlinePaymentErrorCodes = {
    account_already_exists: "O endereço de email fornecido para a criação de uma conta adiada já possui uma conta associada. Use o fluxo OAuth para conectar a conta existente à sua plataforma.",
    account_country_invalid_address: "O país do endereço comercial fornecido não corresponde ao país da conta. As empresas devem estar localizadas no mesmo país que a conta.",
    account_invalid: "O ID da conta fornecido como um valor para o cabeçalho Stripe-Account é inválido. Verifique se suas solicitações estão especificando um ID de conta válido.",
    account_number_invalid: "O número da conta bancária fornecido é inválido (por exemplo, dígitos ausentes). As informações da conta bancária variam de país para país. Recomendamos a criação de validações em seus formulários de entrada com base nos formatos de conta bancária que fornecemos.",
    alipay_upgrade_required: "Este método para criar pagamentos Alipay não é mais suportado. Atualize sua integração para usar Origens.",
    amount_too_large: "A quantidade especificada é maior que a quantidade máxima permitida. Use uma quantidade menor e tente novamente.",
    amount_too_small: "A quantidade especificada é menor que a quantidade mínima permitida. Use uma quantidade maior e tente novamente.",
    api_key_expired: "A chave da API fornecida expirou. Obtenha suas chaves da API atuais no painel e atualize sua integração para usá-las.",
    balance_insufficient: "Não foi possível concluir a transferência ou o pagamento porque a conta associada não possui saldo suficiente disponível. Crie uma nova transferência ou pagamento usando um valor menor ou igual ao saldo disponível da conta.",
    bank_account_exists: "A conta bancária fornecida já existe no objeto Cliente especificado. Se a conta bancária também deve ser anexada a um cliente diferente, inclua o ID do cliente correto ao fazer a solicitação novamente.",
    bank_account_unusable: "A conta bancária fornecida não pode ser usada para pagamentos. Uma conta bancária diferente deve ser usada.",
    bank_account_unverified: "Sua plataforma Connect está tentando compartilhar uma conta bancária não verificada com uma conta conectada.",
    bitcoin_upgrade_required: "Este método para criar pagamentos Bitcoin não é mais suportado. Atualize sua integração para usar Fontes em vez disso.",
    card_declined: "O cartão foi recusado. Quando um cartão é recusado, o erro retornado também inclui o atributo declínio_ código com a razão pela qual o cartão foi recusado. Consulte a documentação dos códigos de recusa para saber mais.",
    charge_already_captured: "A cobrança que você está tentando capturar já foi capturada. Atualize a solicitação com um ID de cobrança não capturada.",
    charge_already_refunded: "A cobrança que você está tentando reembolsar já foi reembolsada. Atualize a solicitação para usar o ID de uma cobrança que não foi reembolsada.",
    charge_disputed: "A cobrança que você está tentando reembolsar foi devolvida. Verifique a documentação das disputas para saber como responder à disputa.",
    charge_exceeds_source_limit: "Essa cobrança levaria você a exceder o limite de processamento de janelas rolantes para esse tipo de fonte. Repita a cobrança posteriormente ou entre em contato conosco para solicitar um limite de processamento mais alto.",
    charge_expired_for_capture: "A cobrança não pode ser capturada porque a autorização expirou. As cobranças de autenticação e captura devem ser capturadas dentro de sete dias.",
    country_unsupported: "Sua plataforma tentou criar uma conta personalizada em um país que ainda não é suportado. Verifique se os usuários podem se inscrever apenas em países suportados por contas personalizadas.",
    coupon_expired: "O cupom fornecido para uma assinatura ou pedido expirou. Crie um novo cupom ou use um já existente válido.",
    customer_max_subscriptions: "O número máximo de assinaturas de um cliente foi atingido. Entre em contato se estiver recebendo este erro.",
    email_invalid: "O endereço de email é inválido (por exemplo, não está formatado corretamente). Verifique se o endereço de email está formatado corretamente e inclui apenas caracteres permitidos.",
    expired_card: "O cartão expirou. Verifique a data de validade ou use outro cartão.",
    idempotency_key_in_use: "A chave de idempotência fornecida está sendo usada em outra solicitação no momento. Isso ocorre se a sua integração estiver fazendo solicitações duplicadas simultaneamente.",
    incorrect_address: "O endereço do cartão está incorreto. Verifique o endereço do cartão ou use outro cartão.",
    incorrect_cvc: "O código de segurança do cartão está incorreto. Verifique o código de segurança do cartão ou use outro cartão.",
    incorrect_number: "O número do cartão está incorreto. Verifique o número do cartão ou use outro cartão.",
    incorreto_zip: "O código postal do cartão está incorreto. Verifique o código postal do cartão ou use outro cartão.",
    instant_payouts_unsupported: "O cartão de débito fornecido como uma conta externa não suporta pagamentos instantâneos. Forneça outro cartão de débito ou use uma conta bancária.",
    invalid_card_type: "O cartão fornecido como uma conta externa não é um cartão de débito. Forneça um cartão de débito ou use uma conta bancária.",
    invalid_charge_amount: "O valor especificado é inválido. O valor da cobrança deve ser um número inteiro positivo na menor unidade monetária e não exceder o valor mínimo ou máximo.",
    invalid_cvc: "O código de segurança do cartão é inválido. Verifique o código de segurança do cartão ou use outro cartão.",
    invalid_expiry_month: "O mês de validade do cartão está incorreto. Verifique a data de validade ou use outro cartão.",
    invalid_expiry_year: "O ano de validade do cartão está incorreto. Verifique a data de validade ou use outro cartão.",
    invalid_number: "O número do cartão é inválido. Verifique os detalhes do cartão ou use outro cartão.",
    invalid_source_usage: "A fonte não pode ser usada porque não está no estado correto (por exemplo, uma solicitação de cobrança está tentando usar uma fonte com uma fonte pendente, com falha ou consumida). Verifique o status da fonte que você está tentando usar . ",
    invoice_no_customer_line_items: "Uma fatura não pode ser gerada para o cliente especificado, pois não há itens de fatura pendentes. Verifique se o cliente correto está sendo especificado ou crie primeiro os itens de fatura necessários.",
    invoice_no_subscription_line_items: "Uma fatura não pode ser gerada para a assinatura especificada, pois não há itens de fatura pendentes. Verifique se a assinatura correta está sendo especificada ou crie primeiro os itens de fatura necessários.",
    invoice_not_editable: "A fatura especificada não pode mais ser editada. Em vez disso, considere criar itens de fatura adicionais que serão aplicados à próxima fatura. Você pode gerar manualmente a próxima fatura ou esperar que ela seja gerada automaticamente no final do faturamento. ciclo.",
    invoice_upcoming_none: "Não há fatura futura no cliente especificado para visualização. Somente clientes com assinaturas ativas ou itens de fatura pendentes têm faturas que podem ser visualizadas.",
    livemode_mismatch: "As chaves, solicitações e objetos de API do modo de teste e ao vivo estão disponíveis apenas no modo em que estão.",
    missing: "O cliente e o ID da fonte foram fornecidos, mas a fonte não foi salva no cliente. Para criar uma cobrança para um cliente com uma fonte especificada, primeiro salve os detalhes do cartão.",
    not_allowed_on_standard_account: "Transferências e pagamentos em nome de uma conta conectada padrão não são permitidos.",
    order_creation_failed: "O pedido não pôde ser criado. Verifique os detalhes do pedido e tente novamente.",
    order_required_settings: "O pedido não pôde ser processado porque faltam as informações necessárias. Verifique as informações fornecidas e tente novamente.",
    order_status_invalid: "O pedido não pode ser atualizado porque o status fornecido é inválido ou não segue o ciclo de vida do pedido (por exemplo, um pedido não pode ser transferido de criado para cumprido sem a primeira transição para pago).",
    order_upstream_timeout: "A solicitação expirou. Tente novamente mais tarde.",
    out_of_inventory: "O SKU está fora de estoque. Se houver mais estoque disponível, atualize a quantidade de estoque do SKU e tente novamente.",
    parameter_invalid_empty: "Um ou mais valores necessários não foram fornecidos. Verifique se as solicitações incluem todos os parâmetros necessários.",
    parameter_invalid_integer: "Um ou mais parâmetros requerem um número inteiro, mas os valores fornecidos eram de um tipo diferente. Verifique se apenas os valores suportados são fornecidos para cada atributo. Consulte a documentação da API para procurar o tipo de dados que cada atributo suporta. ",
    parameter_invalid_string_blank: "Um ou mais valores fornecidos apenas incluíram espaço em branco. Verifique os valores em sua solicitação e atualize os que contenham apenas espaço em branco.",
    parameter_invalid_string_empty: "Um ou mais valores de string necessários estão vazios. Verifique se os valores de string contêm pelo menos um caractere.",
    parameter_missing: "Um ou mais valores necessários estão ausentes. Verifique nossa documentação da API para ver quais valores são necessários para criar ou modificar o recurso especificado.",
    parameter_unknown: "A solicitação contém um ou mais parâmetros inesperados. Remova-os e tente novamente.",
    parameters_exclusive: "Dois ou mais parâmetros mutuamente exclusivos foram fornecidos. Verifique nossa documentação da API ou a mensagem de erro retornada para ver quais valores são permitidos ao criar ou modificar o recurso especificado.",
    payment_intent_authentication_failure: "Não podemos autenticar sua forma de pagamento. Escolha um cartão de crédito diferente ou um novo código de autenticação e tente novamente.",
    payment_intent_incompatible_payment_method: "O pagamento esperava uma forma de pagamento com propriedades diferentes das fornecidas.",
    payment_intent_invalid_parameter: "Um ou mais parâmetros fornecidos não foram permitidos para a operação especificada no pagamento. Verifique nossa referência de API ou a mensagem de erro retornada para ver quais valores não estavam corretos para esse pagamento.",
    payment_intent_payment_attempt_failed: "A última tentativa de pagamento do pagamento falhou. Verifique a propriedade last_payment_error no pagamento para obter mais detalhes e forneça source_data ou uma nova fonte para tentar cumprir esse pagamento novamente.",
    payment_intent_unexpected_state: "O estado do pagamento era incompatível com a operação que você estava tentando executar.",
    payment_method_unactivated: "A cobrança não pode ser criada porque o método de pagamento usado não foi ativado. Ative o método de pagamento no Painel e tente novamente.",
    payment_method_unexpected_state: "O estado do método de pagamento fornecido era incompatível com a operação que você estava tentando executar. Confirme se o método de pagamento está em um estado permitido para a operação em questão antes de tentar executá-la.",
    payouts_not_allowed: "Os pagamentos foram desativados na conta conectada. Verifique o status da conta conectada para ver se alguma informação adicional precisa ser fornecida ou se os pagamentos foram desativados por outro motivo.",
    platform_api_key_expired: "A chave da API fornecida pela sua plataforma Connect expirou. Isso ocorre se a sua plataforma gerou uma nova chave ou a conta conectada foi desconectada da plataforma. Obtenha suas chaves atuais da API no painel e atualize sua integração, ou entrar em contato com o usuário e reconectar a conta. ",
    postal_code_invalid: "O código postal fornecido estava incorreto.",
    processing_error: "Ocorreu um erro ao processar o cartão. Verifique se os detalhes do cartão estão corretos ou use um cartão diferente.",
    product_inactive: "O produto ao qual este SKU pertence não está mais disponível para compra.",
    rate_limit: "Muitas solicitações atingem a API muito rapidamente. Recomendamos uma retirada exponencial de suas solicitações.",
    resource_already_exists: "Um recurso com um ID especificado pelo usuário (por exemplo, plano ou cupom) já existe. Use um valor diferente e exclusivo para o ID e tente novamente.",
    resource_missing: "O ID fornecido não é válido. O recurso não existe ou um ID para um recurso diferente foi fornecido.",
    routing_number_invalid: "O número de roteamento do banco fornecido é inválido.",
    secret_key_required: "A chave da API fornecida é uma chave publicável, mas é necessária uma chave secreta. Obtenha suas chaves da API atuais no Painel e atualize sua integração para usá-las.",
    sepa_unsupported_account: "Sua conta não suporta pagamentos SEPA.",
    shipping_calculation_failed: "O cálculo do envio falhou porque as informações fornecidas estavam incorretas ou não puderam ser verificadas.",
    sku_inactive: "O SKU está inativo e não está mais disponível para compra. Use um SKU diferente ou torne o SKU atual ativo novamente.",
    state_unsupported: "Ocorre ao fornecer as informações de identidade legal para uma conta personalizada dos EUA, se o estado fornecido não for suportado. (Principalmente, estados e territórios associados.)",
    tax_id_invalid: "O número de identificação fiscal fornecido é inválido (por exemplo, dígitos ausentes). As informações de identificação fiscal variam de país para país, mas devem ter pelo menos nove dígitos.",
    tax_calculation_failed: "Falha no cálculo do imposto para o pedido.",
    testmode_charges_only: "Sua conta não foi ativada e só pode fazer cobranças de teste. Ative sua conta no Painel para começar a processar cobranças ativas.",
    tls_version_unsupported: "Sua integração está usando uma versão mais antiga do TLS que não é suportada. Você deve estar usando o TLS 1.2 ou superior.",
    token_already_used: "O token fornecido já foi usado. Você deve criar um novo token antes de poder tentar novamente esta solicitação.",
    token_in_use: "O token fornecido está sendo usado em outra solicitação no momento. Isso ocorre se a sua integração estiver fazendo solicitações duplicadas simultaneamente.",
    transfers_not_allowed: "A transferência solicitada não pode ser criada. Entre em contato se estiver recebendo este erro.",
    upstream_order_creation_failed: "O pedido não pôde ser criado. Verifique os detalhes do pedido e tente novamente.",
    url_invalid: "O URL fornecido é inválido.",
    incomplete_number: "O número do seu cartão está incompleto."
}


const readStripeElementsError = (error, language) => {
    if (language === 'pt' && onlinePaymentErrorCodes[error.code]) {
        return onlinePaymentErrorCodes[error.code];
    } else {
        return error.message;
    }

}

const readStripeError = (error, t) => {
    let key, message;
    let param = error.param || "";
    let match = param.match(/^[a-z]+\[(.*)\]$/);
    if (match && match.length === 2) {
        key = match[1];
    } else {
        key = param;
    }

    if (error.code && key) {
        message = t('stripe_' + error.code)
    } else {
        message = t('invalid_field');
    }

    return { message, key };
};