import { Button, Card, Form, Input, message } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { replace, push } from 'react-router-redux';
import { showFormErrors } from '../../appRedux/actions';
import { setRegisteringUser } from '../../appRedux/actions/User';
import { completeUserRegistration, userStatusByRuid } from '../../client/auth';
import { layout, tailLayout } from '../../components/Form/formLayout';
import FormServerErrors from '../../components/Form/FormServerErrors';
import SelectLocation from '../../components/Form/Legacy/SelectLocation';
import SelectSport from '../../components/Form/Legacy/SelectSport';
import { USER } from '../../constants/App'; import { formDataDecode } from '../../util/form';


const FormItem = Form.Item;

const CompleteRegistration = ({ match, location, t, i18n }) => {

    const dispatch = useDispatch();

    const user = useSelector(s => s.user);

    const [loading, setLoading] = useState(false);

    const [isOrganizer, setOrganizer] = useState(false);

    useEffect(() => {

        const ruid = match.params.id;
        userStatusByRuid(ruid)
            .then(res => res.data)
            .then(data => {
                dispatch(setRegisteringUser(data))

                if (data.first_name && data.last_name && data.active === 2) {
                    //se ha già nome e cognome impostati vai alla verifica altrimenti rimani qui
                    dispatch(replace(`/register/${match.params.id}/verify`));
                }
            })
            .catch(err => {

                const errors = get(err, 'response.data.errors', null);
                if (errors && errors.code && errors.code.length > 0) {
                    if (errors.code[0] == 1) {
                        dispatch(replace('/login'));
                    }
                } else {
                    dispatch(replace('/'));
                }
            })
    }, [dispatch])

    const handleSubmitError = (error) => {
        console.log(error);
    }

    const handleSubmitSuccess = (values) => {

        setLoading(true);

        completeUserRegistration({
            'uid': user.id,
            'first_name': values.first_name,
            'last_name': values.last_name,
            'sports': [values.sport],
            'location_id': values.location.value,
            'gender': 'm',
            'type': USER.TYPE.ATHLETE,
            'language': i18n.language
        })
            .then(res => res.data)
            .then((data) => {
                setLoading(false);
                if (isOrganizer) {
                    dispatch(push(`/register/${match.params.id}/organization`));
                } else {
                    if (data.active === 1) {
                        message.success(t('default_form_success'));
                        dispatch(replace(`/login`));
                    } else {
                        dispatch(push(`/register/${match.params.id}/verify`));
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                dispatch(showFormErrors(error.response))
            })
    }

    const queryParams = formDataDecode(location.search);

    //se non ha completato il profilo dopo aver verificato l'account
    const hasProfileOnly = queryParams && queryParams.view === "1"

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <FormServerErrors className="gx-mb-2" />
                <Card className="gx-card"
                    title={
                        <>
                            <h3>{t('complete_profile')}</h3>
                            <small style={{ whiteSpace: "normal" }}>
                                <Trans
                                    i18nKey="hello_desc"
                                    components={[<br key="1" />, <span key="2"></span>]}
                                />
                            </small>
                        </>
                    }>
                    <Form
                        /*fields={[
                          { name: 'email', value: 'bla@bla.com' },
                          { name: 'repeat_email', value: '_bla@bla.com' },
                          { name: 'password', value: 'password' },
                          { name: 'agreement', value: 'checked' }
                        ]}*/
                        {...layout}

                        onFinish={handleSubmitSuccess}
                        onFinishFailed={handleSubmitError}
                        className="gx-signup-form gx-form-row0">
                        <FormItem
                            label={t('first_name')}
                            name="first_name"
                            rules={[
                                { required: true, message: t('form_error_required') },
                            ]}
                        >
                            <Input placeholder={t('first_name')} />
                        </FormItem>
                        <FormItem
                            label={t('last_name')}
                            name="last_name"
                            rules={[
                                { required: true, message: t('form_error_required') }
                            ]}
                        >
                            <Input placeholder={t('last_name')} />
                        </FormItem>
                        <FormItem
                            label={t('main_sport')}
                            name="sport"
                            rules={[{ required: true, message: t('form_error_required') }]}
                            trigger={"onChange"}
                        >
                            <SelectSport allSports={false} />
                        </FormItem>

                        <FormItem
                            label={t('residence')}
                            name="location"
                            trigger={'onChange'}
                            rules={[{ required: true, message: t('form_error_required') }]}
                        >
                            <SelectLocation countryIp={get(user, 'location.country', null)} placeholder={t('search')} hideLabel={true} />
                        </FormItem>

                        <FormItem className="gx-mt-4" {...tailLayout}>
                            <Button loading={loading} type="primary" onClick={() => setOrganizer(false)} className="gx-mb-0" htmlType="submit">
                                {t('complete')}
                            </Button>

                            {!hasProfileOnly && false &&
                                <Button loading={loading} type="secondary" onClick={() => setOrganizer(true)} className="gx-float-right gx-mb-0" htmlType="submit">
                                    {t('im_organizer')}
                                </Button>
                            }
                        </FormItem>
                    </Form>

                </Card>
            </div>
        </div>
    )
}

export default withTranslation()(CompleteRegistration);