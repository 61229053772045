import { Alert, Col } from "antd";
import { find } from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { SUBSCRIPTION } from "../../../constants/Tickets";

class TicketsTimer extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            duration: 0,
            orderId: null,
            show: false,
        };
    }

    getEventTime(ticketsLimit) {
        if (!ticketsLimit || !ticketsLimit.orderExpiration) {
            return new Date().getTime() + SUBSCRIPTION.PURCHASE_EXPIRE_TIME;
        }

        return ticketsLimit.orderExpiration;
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    formatDuration(duration) {
        if (duration < 0) return "00:00";

        duration /= 1000;
        let min = parseInt(duration / 60, 10);
        let sec = parseInt(duration % 60, 10);

        if (sec < 10) {
            sec = "0" + sec;
        }

        if (min < 10) {
            min = "0" + min;
        }

        return min + ":" + sec;
    }

    startTimer() {
        const show = this.show();

        if (show) {
            var eventTime = this.getEventTime(this.props.ticketsLimit);
            var currentTime = new Date().getTime();
            this.setState({
                show: true,
                orderId: this.props.tickets.id,
                duration: eventTime - currentTime,
            });

            this.timer = setInterval(() => {
                const { duration } = this.state;
                const newDuration =
          duration - SUBSCRIPTION.PURCHASE_EXPIRE_TIMER_INTERVAL;

                if (newDuration < -1) {
                    //remove reservation
                    clearTimeout(this.timer);
                } else {
                    this.setState({
                        duration: newDuration,
                    });
                }
            }, SUBSCRIPTION.PURCHASE_EXPIRE_TIMER_INTERVAL);
        }
    }

    componentDidMount() {
        this.startTimer();
    }

    /**
   * andando dallo step 2 all'1 per il reset del timer va atteso la chiamata di update della competizione
   * @param {*} prevProps
   * @param {*} prevState
   */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.tickets.id && this.props.tickets.id !== prevState.orderId) {
            if (this.show()) {
                this.forceUpdate();
            }
        }
    }

    show() {
        const { tickets, ticketsLimit } = this.props;

        if (!tickets || !tickets.id) return false;

        //todo check limits
        //console.log({ tickets, ticketsLimit });

        const limits = tickets.order.map((t) => ticketsLimit[t.raceId]);

        const hasLimit = !!find(limits, (l) => l && l.limit > 0);

        return hasLimit;
    }

    render() {
        const { duration, show } = this.state;
        const { t, tickets } = this.props;
        if (!show || !tickets || tickets.step === "completed") return null;
        return (
            <Col xs={24} className="gx-mt-4 background-white">
                <Alert
                    type="warning"
                    className="gx-alert"
                    message={
                        duration > 0 ? (
                            <span>
                                {t("tickets_reserved_for")}
                                <span className="ml-15">
                  &nbsp;{this.formatDuration(duration)}
                                </span>
                            </span>
                        ) : (
                            <span>{t("tickets_reserved_expired")}</span>
                        )
                    }
                />
            </Col>
        );
    }
}

export default withTranslation(["common"])(TicketsTimer);
