import { omit } from "lodash";
import revalidator from 'revalidator';
import BaseValidator from './BaseValidator';

let schema = {
    properties: {
        email: {
            description: '',
            type: 'string',
            format: 'email',
            required: true,
            allowEmpty: false
        },
        first_name: {
            type: 'string',
            required: true,
            allowEmpty: false
        },
        last_name: {
            type: 'string',
            required: true,
            allowEmpty: false
        }       
    }
};

const emailRepeatValidation = {
    type: 'string',
    format: 'email',
    required: true,
    allowEmpty: false,
    conform(value,obj){
        //console.log(value, obj);
        if(value !== obj.email){
            return false;
        }
        return true;
    }
};

class PayerValidator extends BaseValidator {

    constructor(values,user) {
        super();
        this.values = values;

        if(!user){
            schema.properties['repeat_email'] = emailRepeatValidation;
        } else {
            if(user && user.id && schema.properties['repeat_email']){
                schema.properties = omit(schema.properties,['repeat_email']);
            }
        }

        this.result = { errors: [], valid: true }
    }

    validate() {
        this.result = revalidator.validate(this.values, schema);
        return this;
    }

    validateField(field){
        if(schema.properties[field]) {
            this.result = revalidator.validate(this.values, { properties: { [field]: schema.properties[ field ] } });            
        }
        return this;
    }


}

export default PayerValidator;