import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
//import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/pt';
import { LANGUAGES } from '../constants/App';
import { includes } from 'lodash'
//get translation string, and invert quotes
//egrep -ior "\{t\(([\'A-z_]+)\)\}" src/  | egrep -o "'(.*)'" | sed "s/'//g"

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng:language,
        whitelist: ['en', 'it', 'it-IT', 'pt', 'pt-BR', 'es', 'de', 'de-DE'],
        fallbackLng: {
            'pt-BR': ['pt'],
            'it-IT': ['it'],
            'de-DE': ['de'],
            'default': ['en']
        },
        // have a common namespace used around the full app
        ns: ['common', 'seo', 'sports', 'competitions'],
        defaultNS: 'common',
        debug: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        }
    });

// const it = moment.locale('it', {
//     months: 'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split('_'),
//     monthsShort: 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
//     weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split('_'),
//     weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
//     weekdaysMin: 'D_L_Ma_Me_G_V_S'.split('_'),
//     longDateFormat: {
//         LT: 'HH:mm',
//         LTS: 'LT:ss',
//         L: 'DD/MM/YYYY',
//         LL: 'D MMMM YYYY',
//         LLL: 'D MMMM YYYY LT',
//         LLLL: 'dddd, D MMMM YYYY LT'
//     },
//     calendar: {
//         sameDay: '[Oggi alle] LT',
//         nextDay: '[Domani alle] LT',
//         nextWeek: 'dddd [alle] LT',
//         lastDay: '[Ieri alle] LT',
//         lastWeek: function () {
//             switch (this.day()) {
//             case 0:
//                 return '[la scorsa] dddd [alle] LT';
//             default:
//                 return '[lo scorso] dddd [alle] LT';
//             }
//         },
//         sameElse: 'L'
//     },
//     relativeTime: {
//         future: function (s) {
//             return ((/^[0-9].+$/).test(s) ? 'tra' : 'in') + ' ' + s;
//         },
//         past: '%s fa',
//         s: 'alcuni secondi',
//         m: 'un minuto',
//         mm: '%d minuti',
//         h: 'un\'ora',
//         hh: '%d ore',
//         d: 'un giorno',
//         dd: '%d giorni',
//         M: 'un mese',
//         MM: '%d mesi',
//         y: 'un anno',
//         yy: '%d anni'
//     },
//     ordinalParse: /\d{1,2}º/,
//     ordinal: '%dº',
//     week: {
//         dow: 1, // Monday is the first day of the week.
//         doy: 4  // The week that contains Jan 4th is the first week of the year.
//     }
// });
export default i18n;


export const getSafeISOLanguage = (language) => {

    if (!language) return LANGUAGES.DEFAULT

    language = language.split('-')[0];

    if (includes(LANGUAGES.SUPPORTED, language)) {
        return language;
    }

    return LANGUAGES.DEFAULT;
}
