import { Card, Col, Row, Tag } from 'antd';
import { copyOrderForm, processGnInstallments, updatePlayerForm } from 'appRedux/actions/Tickets';
import { getTicketsTotal } from 'appRedux/reducers/helpers/tickets';
import { get, startsWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getCompetitionFeesForCalculation, guessPhonePrefixByCountry } from 'util/Competition';
import CustomField from '../../../../components/Form/Legacy/CustomField';
import KsDatePicker from '../../../../components/Form/Legacy/KsDatePicker';
import SelectField from '../../../../components/Form/Legacy/SelectField';
import SelectLocation from '../../../../components/Form/Legacy/SelectLocation';
import SelectPhone from '../../../../components/Form/Legacy/SelectPhone';
import TextField from '../../../../components/Form/Legacy/TextField';
import { EVENT, LAYOUT } from '../../../../constants/App';
import SelectAthleteCard from './SelectAthleteCard';
import PlayerValidator from './validators/PlayerValidator';
import moment from 'moment';
import SelectCountry from '../../../../components/Form/Legacy/SelectCountry';
import ProductFields from './ProductFields';

class RaceTicket extends Component {
    constructor(props) {
        super(props);

        this.raceTicketKey = props.ticket.id; //`${props.race.id}_${props.index}`;

        this.state = {
            expandPanel: true,
        };
    }

    /**
     * format => prefix - number
     * @param number
     */
    onPhoneChange(number) {
        if (number === '-') number = null;
        this.onFormChange('phone', number);
    }

    onCitizenshipChange(value, option) {
        if (value === '-') value = null;

        this.onFormChange('citizenship', value);
        this.onFormChange('citizenship_label', option ? option.children : null);
    }

    onAthleteCardChange(field, cardValue) {
        this.onFormChange(field, cardValue);
    }

    onLocationChange(location) {
        this.onFormChange('location', location ? location.value : '');
        this.onFormChange('location_label', location ? location.label : '');
    }

    onBirthdayChange(date) {
        const value = date ? date.format('YYYY-MM-DD') : null;
        this.onFormChange('birthday', value);
    }

    onChangeEmail(e) {
        this.onFormChange('email', e.target.value);
    }

    onChangeFirstName(e) {
        this.onFormChange('first_name', e.target.value);
    }

    onChangeLastName(e) {
        this.onFormChange('last_name', e.target.value);
    }

    onChangeGender(value) {
        const { competition, tickets, race, t } = this.props;

        const additionKey = `gender_${value}`;
        const priceAddition = get(race, `price.additions.${additionKey}`, 0);
        let payoff = null;
        if (priceAddition !== null) {
            payoff = {
                field_value: value,
                label: value === 'm' ? t(`male`) : t('female'),
                value: priceAddition,
                ksFee: getCompetitionFeesForCalculation(competition, tickets.payment),
            };
        }

        this.onFormChange('gender', value, payoff);
    }

    onChangeProductVariant(value, option) {

        const { competition, tickets } = this.props;

        const { variant, product } = option;

        const field = `product_${product.id}`;

        let payoff = null;

        if (variant) {
            payoff = {
                field_value: value,
                label: `${product.name} - ${variant.attributes.map(a => a.attribute_name + ': ' + a.name).join(', ')}`,
                value: variant.price,
                ksFee: getCompetitionFeesForCalculation(competition, tickets.payment),
            }
        } else {
            payoff = {
                field_value: '',
                label: '',
                value: 0,
                ksFee: getCompetitionFeesForCalculation(competition, tickets.payment),
            }
        }

        this.onFormChange(field, value, payoff);
    }

    onFormChange(field, value, payoff = null) {
        const isValid = new PlayerValidator({ [field]: value }).validateField(field).isValid();

        this.props.dispatch(
            updatePlayerForm({
                field: field,
                value: value,
                valid: isValid,
                key: this.raceTicketKey,
                addition: payoff,
                //orderId:this.raceTicketKey
            })
        );

        if (
            window.$gn &&
            !!window.$gn.checkout &&
            this.shouldBePriceAdditionField(field) &&
            payoff &&
            payoff.field_value
        ) {
            //pass customfield type on payoff
            //ricalcolo rate
            this.props.dispatch(
                processGnInstallments(
                    window.$gn.checkout,
                    getTicketsTotal(this.props.tickets),
                    get(this.props.tickets, 'payment.form.card_brand')
                )
            );
        }
    }

    shouldBePriceAdditionField(fieldName) {
        return startsWith(fieldName, 'custom_field') ||
            startsWith(fieldName, 'cf_') ||
            startsWith(fieldName, 'product_') ||
            fieldName === 'gender';
    }

    /***
     * add price additions
     * @param value
     * @param field
     * @param {{}} payoff
     * @param {string} payoff.label
     * @param {number} payoff.addition
     * @param {number} payoff.ksFee
     */
    customFieldCallback(value, field, payoff) {
        const { competition, tickets } = this.props;

        //attach ksFee to update order fee on price addition
        if (payoff) {
            payoff.ksFee = getCompetitionFeesForCalculation(competition, tickets.payment);
        }

        this.onFormChange(field, value, payoff);
    }

    onCopySelectChange(value) {
        if (value === 'payer') {
            const { tickets } = this.props;
            this.props.dispatch(copyOrderForm({ from: value, to: this.raceTicketKey }));

            if (tickets.payer && tickets.payer.gender) {
                this.onChangeGender(tickets.payer.gender);
            }
        }
    }

    getBirthdayFromState() {
        const { form } = this.props;

        if (form && form.birthday) {
            const [year, month, day] = form.birthday.split('-');
            return {
                day: parseInt(day),
                month: parseInt(month),
                year: parseInt(year),
            };
        } else {
            return {
                day: null,
                month: null,
                year: null,
            };
        }
    }

    renderHeader(ticket, index, t) {
        return <span>{`${t('subscription')} ${index} - ${ticket.title}`}</span>;
    }

    renderForm() {
        const { user, race, form, competition, t } = this.props;

        const phoneValue = form.phone ? form.phone : guessPhonePrefixByCountry(competition) + '-'; //added for split

        return (
            <Row>
                <Col xs={24} className="gx-mb-4">
                    <SelectField
                        name="copy_helper"
                        label={t('copy_data_from')}
                        onChange={this.onCopySelectChange.bind(this)}
                        options={[
                            { value: '', text: t('empty_value') },
                            { value: 'payer', text: t('payer') },
                        ]}
                    />
                </Col>
                <Col xs={24} md={12} className="gx-mb-4">
                    <TextField
                        name="first_name"
                        type="text"
                        label={t('first_name') + ' *'}
                        onChange={this.onChangeFirstName.bind(this)}
                        value={form.first_name}
                        validationState={get(form.errors, 'first_name.state', null)}
                        help={get(form.errors, 'first_name.message', null)}
                        className="gx-mr-0 gx-mr-md-4"
                    />
                </Col>
                <Col xs={24} md={12} className="gx-mb-4">
                    <TextField
                        name="last_name"
                        type="text"
                        label={t('last_name') + ' *'}
                        onChange={this.onChangeLastName.bind(this)}
                        value={form.last_name}
                        validationState={get(form.errors, 'last_name.state', null)}
                        help={get(form.errors, 'last_name.message', null)}
                        className="gx-ml-0 gx-ml-sm-0"
                    />
                </Col>

                <div className="clearfix"></div>

                <Col xs={24} sm={24} className="gx-mb-4">
                    <TextField
                        name="email"
                        type="email"
                        label={t('email') + ' *'}
                        onChange={this.onChangeEmail.bind(this)}
                        value={form.email}
                        validationState={get(form.errors, 'email.state', null)}
                        help={get(form.errors, 'email.message', null)}
                    />
                </Col>

                <div className="clearfix"></div>

                <Col xs={24} sm={8} className="gx-mb-4">
                    <SelectField
                        className="gx-ml-0"
                        name="gender"
                        label={t('gender') + ' *'}
                        onChange={this.onChangeGender.bind(this)}
                        options={[
                            { value: '', text: '-' },
                            { value: 'm', text: t('male') },
                            { value: 'f', text: t('female') },
                        ]}
                        help={get(form.errors, 'gender.message', null)}
                        value={form.gender}
                        validationState={get(form.errors, 'gender.state', null)}
                    />
                </Col>

                <Col xs={24} sm={16} className="gx-mb-4">
                    <KsDatePicker
                        className="gx-ml-sm-4"
                        showSearch={false}
                        label={'birthday_desc'}
                        name="birthday"
                        {...this.getBirthdayFromState()}
                        required={true}
                        onDateChange={this.onBirthdayChange.bind(this)}
                        value={form.birthday}
                        validationState={get(form.errors, 'birthday.state', null)}
                        help={get(form.errors, 'birthday.message', null)}
                        disabledDate={(current) => {
                            const minAge = get(race, 'rules.minAge', null);
                            const maxAge = get(race, 'rules.maxAge', null);
                            const intMinAge = parseInt(minAge);
                            const intMaxAge = parseInt(maxAge);
                            if (intMinAge > 0 && intMaxAge > 0) {
                                return (
                                    current &&
                                    (current > moment().subtract(intMinAge, 'years') ||
                                        current < moment().subtract(intMaxAge, 'years'))
                                );
                            } else if (intMinAge > 0) {
                                return current && current > moment().subtract(intMinAge, 'years');
                            } else if (intMaxAge > 0) {
                                return current && current < moment().subtract(intMaxAge, 'years');
                            } else {
                                return current && current > moment().endOf('day');
                            }
                        }}
                    />
                </Col>

                <div className="clearfix"></div>

                {get(race.standardFields, 'phone.active') && (
                    <Col xs={24} className="gx-mb-4">
                        <SelectPhone
                            name="phone"
                            onPhoneChange={this.onPhoneChange.bind(this)}
                            required={get(race.standardFields, 'phone.required')}
                            value={phoneValue}
                            validationState={get(form.errors, 'phone.state', null)}
                            help={get(form.errors, 'phone.message', '')}
                        />
                    </Col>
                )}
                {get(race.standardFields, 'location.active') && (
                    <Col xs={24} className="gx-mb-4">
                        <SelectLocation
                            name="location"
                            noResultsText={t('no_result_found')}
                            placeholder={t('search') + '...'}
                            searchingText={t('searching')}
                            onSelect={this.onLocationChange.bind(this)}
                            countryIp={get(user, 'location.country', null)}
                            fieldLabel={t('residence_location')}
                            forceValue={true}
                            value={form.location}
                            mandatory={!get(race.standardFields, 'location.required') ? false : true}
                            validationState={get(form.errors, 'location.state', null)}
                            help={get(form.errors, 'location.message', ' ')}
                        />
                    </Col>
                )}
                {get(race.standardFields, 'citizenship.active') && (
                    <Col xs={24} className="gx-mb-4">
                        <SelectCountry
                            label={t('citizenship_country')}
                            name="citizenship"
                            onChange={this.onCitizenshipChange.bind(this)}
                            required={get(race.standardFields, 'citizenship.required')}
                            value={get(form, 'citizenship', '')}
                            validationState={get(form.errors, 'citizenship.state', null)}
                            help={get(form.errors, 'citizenship.message', '')}
                        />
                    </Col>
                )}
                {get(race.standardFields, 'athlete_card.active') && (
                    <Col xs={24} className="gx-mb-4">
                        <SelectAthleteCard
                            name="athlete_card"
                            value={get(form, 'athlete_card', '')}
                            form={form}
                            onAthleteCardChange={this.onAthleteCardChange.bind(this)}
                            field={race.standardFields.athlete_card}
                            validationState={get(form.errors, 'athlete_card.state', null)}
                            help={get(form.errors, 'athlete_card.message', ' ')}
                            t={t}
                        />
                    </Col>
                )}
                <Col xs={24}>
                    {race.customFields.map((f) => {
                        return (
                            <CustomField
                                className="gx-mb-4"
                                competition={competition}
                                cb={this.customFieldCallback.bind(this)}
                                key={f.name}
                                field={f}
                                value={form[f.name]}
                                validationState={get(form.errors, `${f.name}.state`, null)}
                                help={get(form.errors, `${f.name}.message`, null)}
                                price={race.price}
                            />
                        );
                    })}
                </Col>

                <ProductFields
                    onChange={this.onChangeProductVariant.bind(this)}
                    formValues={form}
                    products={race.products || []}
                    competition={competition}
                    t={t}
                />
            </Row>
        );
    }

    render() {
        const { team, ticket, competition, index, t } = this.props;

        const teamTransKey = competition.type === EVENT.TYPE.TRAVEL ? 'group' : 'team';

        return (
            <Card
                className="gx-card"
                title={this.renderHeader(ticket, index, t)}
                extra={[<span key="1">{team ? <Tag color={LAYOUT.PRIMARY_COLOR}>{t(teamTransKey)}</Tag> : null}</span>]}
            >
                {this.renderForm()}
            </Card>
        );
    }
}

RaceTicket.propTypes = {
    race: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    competition: PropTypes.object.isRequired,
};

const mapStateToProps = function (state, props) {
    return {
        user: state.user,
        form: state.tickets.players[props.ticket.id] || {},
    };
};

export default connect(mapStateToProps)(withTranslation()(RaceTicket));
