import './polyfill';
import config from './config';
import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from 'react-hot-loader';
import './lngProvider/i18n';
import NextApp from './NextApp';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga';

Sentry.init({
    dsn: "https://3bfee19588444248805f6bd0b37fc4f2@o552328.ingest.sentry.io/5677896",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENV,
    enabled: process.env.REACT_APP_ENV === "production"
});

ReactGA.initialize('UA-51414085-1', {
    debug: config.debug,
    testMode: config.debug,    
});
ReactGA.pageview(window.location.pathname + window.location.search);

// Wrap the rendering in a function:
const render = Component => {
    ReactDOM.render(
        // Wrap App inside AppContainer
        <AppContainer>
            <Component />
        </AppContainer>,
        document.getElementById('root')
    );
};

// Do this once
unregisterServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept('./NextApp', () => {
        render(NextApp);
    });
}
