import { Input } from 'antd';
import React from 'react';

const TextField = ({
    className,
    validationState,
    labelClassName,
    groupStyle,
    help,
    label,
    onChange,
    onPaste,
    onCopy,
    placeholder,
    value,
    name,
    type,
    bsClass,
    ...rest
}) => {

    const _onChange = (e) => {        
        onChange(e);
    }

    const Component = type === 'textarea' ? Input.TextArea : Input;

    return (
        <div className={`${className} ${validationState ? `has-${validationState}` : ''}`} style={groupStyle}  >
            <label className={labelClassName}>{label}</label>

            <Component
                onChange={_onChange}
                onPaste={onPaste}
                onCopy={onCopy}
                placeholder={placeholder}
                value={value}
                name={name}
                type={type}
                className={bsClass}
                {...rest}
            />

            {help && <div className="help-block gx-mt-2">{help}</div>}
        </div>
    );
}

TextField.defaultProps = {
    onChange: () => { },
    onPaste: () => { },
    onCopy: () => { },
    placeholder: "",
    value: undefined,
    name: "",
    type: "text",
    className: '',
    disabled: false,    
};

export default TextField
