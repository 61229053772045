import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postResendActivation } from '../../client/auth';
import { useDispatch } from 'react-redux';
import { genericFormErrorHandler } from '../../util/form';
import { Card, Button, Input, Form, Row, Col, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { getFirstCatchedErrorMessage } from '../../util/errors';

const FormItem = Form.Item;

const ResendActivation = ({ t, i18n }) => {

    const user = useSelector(({ user }) => user);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [dispatch, user])


    const handleSubmit = (values) => {

        setLoading(true);

        postResendActivation({ username: values.email })
            .then(r => r.data)
            .then(() => {
                setLoading(false);
                message.success(t('mail_sent'));
            })
            .catch((err) => {
                setLoading(false);
                const msg = getFirstCatchedErrorMessage(err);
                if (msg && i18n.exists(msg)) {
                    message.error(t(msg));
                } else {
                    genericFormErrorHandler(err, t)
                }
            })
    }

    const handleSubmitError = () => { }

    return (
        <Card title={t('verify_email')}>
            <p>{t('resend_activation_title')}</p>
            <Row>
                <Col xs={24} sm={12} >
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        onFinishFailed={handleSubmitError}
                        className="gx-signup-form gx-form-row0">
                        <FormItem
                            label={t('email')}
                            name="email"
                            rules={[
                                { required: true, message: t('form_error_required') },
                                { type: 'email', message: t('validator_email') }
                            ]}
                        >
                            <Input placeholder={t('email')} />
                        </FormItem>
                        <FormItem >
                            <Button loading={loading} type="primary" className="gx-mb-0" htmlType="submit">
                                <SendOutlined /> {t('send')}
                            </Button>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        </Card>
    )
}

export default withTranslation()(ResendActivation);