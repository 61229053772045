import { Steps } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { url } from 'util/index';
import { LAYOUT } from '../../../constants/App';
import useBreakpointSize from '../../../hooks/useBreakpointSize';
import IntlMessages from '../../../util/IntlMessages';
import { includes } from 'lodash';

const { Step } = Steps;

const HeaderStatus = ({ competition }) => {

    const size = useBreakpointSize(LAYOUT.BREAKPOINT)
    const steps = useSelector(state => state.tickets.steps);
    if (!steps) return <Redirect to={url.eventUrl(competition)} />

    let stepsConfig = {};
    if (includes(['LG', 'XL', 'XXL'], size)) {
        stepsConfig.labelPlacement = 'horizontal'
    } else {
        stepsConfig.labelPlacement = 'vertical'
    }

    if (includes(['XS'], size)) {
        stepsConfig.direction = 'vertical';
    } else {
        stepsConfig.direction = 'horizontal';
    }

    return (
        <Steps
            {...stepsConfig}
            className="gx-mt-3"            
            current={steps.current}            
            progressDot={false}
        >
            <Step status={steps.status[0]} title={<IntlMessages id="select_tickets" />} />
            <Step status={steps.status[1]} title={<IntlMessages id="compile_form" />} />
            <Step status={steps.status[2]} title={<IntlMessages id="data_verification" />} />
            <Step status={steps.status[3]} title={<IntlMessages id="completed" />} />
        </Steps>
    )
}
export default HeaderStatus;