/**
 * Created by ruzz on 23/02/16.
 */
import { Col, Row, Select } from 'antd'
import { assign, padStart, times } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

const { Option } = Select;

class SelectDatepicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: props.day,
            month: props.month,
            year: props.year
        }
    }

    onDateChange(period, value) {

        let date = parseInt(value);

        //create new object for correct reference
        let d = assign({}, this.state, { [period]: date });
        let m = moment(d);
        if (!m.isValid()) {
            let d = {
                ...this.state,
                day: 1
            };
            this.setState(d)
            m = moment(d);
        } else {
            this.setState({
                [period]: date
            });
        }

        this.props.onDateChange(m);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value != this.props.value) {
            const date = nextProps.value.split('-');

            this.setState({
                day: parseInt(date[2]),
                month: parseInt(date[1]) - 1,
                year: parseInt(date[0])
            });
        }
    }

    render() {

        let { t, minAge, showSearch } = this.props;
        let { month, year } = this.state;
        var currentYear = new Date().getFullYear();
        var years = [];

        if (this.props.name === 'birthday') {
            for (var i = currentYear - minAge; i > currentYear - 100; i--) {
                years.push(<Option key={i} value={i}>{i}</Option>);
            }
        } else {
            for (var ii = currentYear; ii < currentYear + 10; ii++) {
                years.push(<Option key={ii} value={ii}>{ii}</Option>);
            }
        }

        var days = [];
        var daysOfMonth = new Date(year, parseInt(month) + 1, 0).getDate();

        for (var d = 1; d <= daysOfMonth; d++) {
            days.push(<Option key={d} value={d}>{padStart(d, 2, '0')}</Option>)
        }

        var label = t(this.props.label) || t(this.props.name);

        if (this.props.required) {
            label += ' *';
        }

        return (
            <div name={this.props.name} label={label} >
                <label>{label}</label>
                <Row>
                    <Col xs={8}>
                        <Select
                            showSearch={showSearch}
                            filterOption={(input, option) =>
                                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.day}
                            className="gx-b-block gx-w-100 gx-pr-2"
                            onChange={this.onDateChange.bind(this, 'day')}
                        >
                            {days}
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <Select
                            showSearch={showSearch}
                            filterOption={(input, option) =>
                                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.month}
                            className="gx-b-block gx-w-100 "
                            onChange={this.onDateChange.bind(this, 'month')}
                        >
                            {times(12, i =>
                                <Option key={i} value={i}>{padStart(i + 1, 2, '0')}</Option>
                            )}
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <Select
                            showSearch={showSearch}
                            filterOption={(input, option) => {
                                return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            value={this.state.year}
                            className="gx-b-block gx-w-100 gx-pl-2"
                            onChange={this.onDateChange.bind(this, 'year')}
                        >
                            {years}
                        </Select>
                    </Col>
                </Row>
                {this.props.help && <div className="help-block">{this.props.help}</div>}
            </div>
        )
    }
}

SelectDatepicker.defaultProps = {
    minAge: 0,
    showSearch: false
};

export default withTranslation()(SelectDatepicker)
