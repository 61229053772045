import React from "react";
import { Avatar } from "antd";
import { FileOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';
import { url } from 'util/index'
import { empAccessUrl } from "../../util/url";

const OrganizationItem = ({ onClickMorph, org, t }) => {
    const badge = 0;
    const { id, complete_name } = org;

    return (
        <li className="gx-media">
            <div className="gx-user-thumb gx-mr-3">
                <Avatar className="gx-size-40"
                    alt={complete_name}
                    src={url.getProfileImage(org.id, '200x200', true) || <UserOutlined />} />
                {badge > 0 ? <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">{badge}</span> : null}
            </div>
            <div className="gx-media-body">
                <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
                    <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
                        <a href="#" onClick={e => onClickMorph(e, id)}><span className="gx-link">{complete_name}</span></a></h5>
                </div>
                {org.email && <p className="gx-fs-sm">{org.email}</p>}
                <a href="#" onClick={e => onClickMorph(e, id)}><span className="gx-btn gx-btn-sm gx-top2 gx-text-muted"><FileOutlined />&nbsp;{t('profile')}</span></a>
                <a href={empAccessUrl(id)} target="_blank" rel="noreferrer">
                    <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted"><CalendarOutlined />&nbsp;{t('manage_events')}</span>
                </a>
            </div>
        </li>
    );
};

export default OrganizationItem;
