import { Alert, Col, Row } from 'antd';
import { cleanTickets, setTicketsOrder } from "appRedux/actions/Tickets";
import { generateOrderId } from "appRedux/reducers/helpers/tickets";
import Price from 'components/Competition/Price';
import { findIndex, get, includes } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import update from "react-addons-update";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { areSubscriptionsOpen } from 'util/Competition';
import { url } from 'util/index';
import IntlMessages from 'util/IntlMessages';
import ContestCard from './ContestCard';
import DiscountCode from './DiscountCode';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import './styles/race_list.css';

class RacesSubscriptionList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            expand: false,
            showDiscountField: get(props.tickets, 'discount.valid', false),
            tickets: [],
            availabilityError: false
        }
    }

    componentDidMount() {
        this.props.cleanTickets();
    }

    /**
     *
     * @param {object} counter
     * @param {string} counter.action
     * @param {object} counter.data order data set to store
     * @param {number} counter.data.fee
     * @param {number} counter.data.price
     * @param {string} counter.data.raceId
     * @param {number} counter.data.tickets
     * @param {string} counter.data.title
     * @param {number} counter.data.total
     * @param {number} counter.data.quantity const equal to 1
     */
    onUpdateCounters(counter) {

        const newState = update(this.state, {
            tickets: {
                $apply: (ts) => {
                    if (counter.action === 'add') {
                        ts = ts.concat(counter.data)
                    } else if (counter.action === 'remove') {
                        const i = findIndex(ts, t => t.raceId === counter.data.raceId);
                        ts.splice(i, counter.data.quantity);
                    }

                    return ts;
                }
            }
        });

        this.setState(newState);
    }

    onClickOrderButton() {

        const { user, competition, setTicketsOrder, history } = this.props;

        const { tickets } = this.state;

        const orderId = generateOrderId(competition.organization.ksId);        

        //init team state
        const teams = competition.racesSummary
            .filter(r =>
                r.hasTeams &&
                r.teamPlayers &&
                r.teamPlayers > 0 &&
                includes(tickets.map(t => t.raceId), r.id) //è all'interno dell'ordine
            )
            .map(r => {
                return {
                    raceId: r.id,
                    raceName: r.name,
                    racePlayers: r.racePlayers,
                    name: ''
                }
            })

        // console.log("setTicketsOrder", {
        //     order: tickets,
        //     teams: teams,
        //     orderId: orderId,
        // }, competition.organization);

        setTicketsOrder({
            order: tickets,
            teams: teams,
            user: user,
            compId: competition._id,
            id: orderId
        }).then(() => {
            this.setState({ availabilityError: false });
            //TODO RR get router
            history.push(`${url.eventUrl(competition)}/subscribe/order/${orderId}`)
        }).catch(() => {
            this.setState({ availabilityError: true });
        });

    }

    renderTotals(competition) {

        const totals = { quantity: 0, total: 0 };
        this.state.tickets.map(t => {
            totals.quantity += t.quantity;
            totals.total += t.price;
        });

        return (
            <Row className="subscription-total-wrapper">
                <Col xs={24} sm={16} className="totals">
                    <span><IntlMessages id="quantity" />: {totals.quantity}</span>
                    <span style={{ margin: "0 10px" }}>|</span>
                    <span><IntlMessages id="total" />: <Price price={{ price: totals.total }} competition={competition} showFree={false} /> </span>
                </Col>
                <Col xs={24} sm={8} className="subscription-btn-wrapper">
                    <button
                        disabled={totals.quantity === 0}
                        className="ant-btn gx-btn-outline-primary gx-btn-lg btn-subscribe pull-right-sm gx-mb-0"
                        onClick={this.onClickOrderButton.bind(this)}>
                        <IntlMessages id="buy" />
                    </button>
                </Col>
            </Row>
        )
    }

    renderDiscountButton() {
        return (
            <Col xs={24} className="gx-mt-xs-3 size20 gx-mt-4 gx-mb-3" >
                <a href="#" onClick={e => {
                    e.preventDefault();
                    this.setState({ showDiscountField: !this.state.showDiscountField });
                }}
                >
                    {this.state.showDiscountField ? <CaretUpOutlined /> : <CaretDownOutlined />}<IntlMessages id='insert_discount_code' />
                </a>
            </Col>
        )
    }

    renderDiscountField(competition, tickets) {
        if (this.state.showDiscountField) {
            return (
                <DiscountCode competition={competition} tickets={tickets} />
            )
        }
    }

    renderDiscountForm() {
        const { competition, tickets } = this.props;
        if (areSubscriptionsOpen(competition)) {
            return (
                <>
                    {this.renderDiscountButton()}
                    {this.renderDiscountField(competition, tickets)}
                </>
            )
        }
    }

    render() {

        const { competition, ticketsLimit } = this.props;

        const { racesSummary } = competition;

        return (
            <Col xs={24} md={{ span: 20, offset: 2 }} xl={{ span: 14, offset: 5 }} className="race-card-list gx-pt-5">
                {this.state.availabilityError &&
                    <Alert
                        className="gx-d-block gx-mb-3"
                        type="error"
                        message={<IntlMessages id='not_available_tickets_on_first_reservation' />}
                    />
                }
                <h2 className="gx-mb-3 gx-text-center v1 title gx-text-uppercase"><IntlMessages id='contests' /></h2>
                <div>
                    {racesSummary.map((race, index) => {
                        return (
                            <ContestCard
                                key={race.id}
                                race={race}
                                competition={competition}
                                raceTicketsLimit={ticketsLimit[race.id]}
                                index={index}
                                listener={this.onUpdateCounters.bind(this)}
                            />
                        )
                    })}
                </div>
                {this.renderDiscountForm()}
                <div className="list-card order-totals gx-mt-4 gx-mb-4">
                    {this.renderTotals(competition)}
                </div>
            </Col>
        )
    }
}

RacesSubscriptionList.propTypes = {
    competition: PropTypes.object.isRequired
};

RacesSubscriptionList.contextTypes = {
    router: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        cleanTickets: () => dispatch(cleanTickets()),
        setTicketsOrder: (params) => {
            return dispatch(setTicketsOrder(params))
        }
    }
}

function mapStateToProps(state) {
    const { ticketsLimit } = state;
    return {
        ticketsLimit: ticketsLimit
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RacesSubscriptionList))