import { Button, Card, Col, Row, Divider } from 'antd'
import { get as _get, sumBy } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { isExpiredBankPayment, isExpiredBoletoPayment } from "util/Competition"
import { date } from 'util/index'
import { applyDiscount } from '../../../../util/Tickets'
import FreePayment from './Payment/FreePayment'
import Payment from './Payment/index'


class PaymentWrapper extends Component {

    constructor(props, context) {
        super(props, context);        

        const competitionStartDate = date.fromMongoDateToMoment(props.competition.date_start);

        //todo fixme, get lower race subscription date for boleto/bank payments date reference
        this.providers = _get(props.competition.payments, 'providers', []).filter(provider => {

            if (provider.slug === 'bank' && isExpiredBankPayment(competitionStartDate, provider)) {
                return false;
            } else if (provider.slug === 'boleto' && isExpiredBoletoPayment(competitionStartDate, provider)) {
                return false;
            } else {
                return provider.active === true;
            }

        });       

        this.state = {
            active: props.tickets.payment.active || (this.providers.length ? this.providers[0].slug : null)
        }
    }

    componentDidMount() { }

    haveToPay(tickets) {
        const totalOrder = sumBy(tickets.order, o => o.total);
        if (tickets.discount) {
            const discountData = applyDiscount(totalOrder, tickets.discount);
            return discountData.newPrice > 0;
        }
        return totalOrder > 0
    }

    renderPaymentSelector(providers, t) {

        if (providers.length > 0) {
            return <Payment active={this.state.active} {...this.props} />
        } else {
            return <h3 style={{ padding: 15 }}>{t('no_payment_method_active')}</h3>
        }
    }

    renderSelection(t) {        

        let title;

        if (this.providers.length > 0) {
            title = <h3 style={{ whiteSpace: 'normal' }}>{t('select_payment_method')}</h3>;
        }

        return (
            <Col xs={24} className="gx-text-center">
                <Card title={title} className="gx-card">
                    <Row>
                        {this.providers.map((provider) => {
                            return (
                                <Col key={provider._id} xs={24} lg={24 / this.providers.length} className="gx-text-center">
                                    <Button style={{ width: '95%' }} className={`gx-btn payment-method-btn gx-text-truncate  ${provider.slug && 'btn-default'} ${provider.slug === this.state.active && 'active'}`}
                                        onClick={() => this.setState({ active: provider.slug })}>
                                        {t(provider.slug)}
                                    </Button>
                                </Col>
                            )
                        })}
                    </Row>
                    <Divider />
                    {this.renderPaymentSelector(this.providers, t)}
                </Card>
            </Col>
        )
    }

    renderFreePayment(tickets) {
        return <FreePayment tickets={tickets} />
    }

    render() {
        const { t, tickets } = this.props;

        if (this.haveToPay(tickets)) {
            return this.renderSelection(t)
        } else {
            return this.renderFreePayment(tickets, t);
        }
    }
}

export default withTranslation(['common'])(PaymentWrapper)
