import { SET_REGISTERING_USER, SIGNOUT_USER_SUCCESS, UPDATE_PROFILE_TIME, UPDATE_CURRENT_USER, SET_NEW_MORPH_USER } from "../../constants/ActionTypes";
import { orgToUserMorph } from './helpers/user';
import { get } from 'lodash';
const INIT_STATE = 'loading';

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
        const path = get(action, 'payload.location.pathname');
        if (path === '/chat') {
            return {
                ...state,
                unread_messages: false
            }
        }
        return state;
    }
    case "FETCH_USER_SUCCESS": {
        return action.payload
    }
    case 'FETCH_USER_REQUEST': {
        return action.payload
    }
    case 'FETCH_USER_ERROR': {
        return null;
    }
    case SIGNOUT_USER_SUCCESS: {
        return INIT_STATE
    }
    case SET_REGISTERING_USER: {
        return {
            ...state,
            ...action.payload,
            registering: true
        }
    }
    case UPDATE_PROFILE_TIME: {
        return {
            ...state,
            updated_at: Date.now()
        }
    }
    case UPDATE_CURRENT_USER: {
        return {
            ...state,
            ...action.payload,
            updated_at: Date.now()
        }
    }
    case SET_NEW_MORPH_USER: {

        const morphState = orgToUserMorph(action.payload);
        let morphs = state.morphs;
        if (!state.morphs) {
            morphs = [morphState];
        } else {
            morphs = morphs.concat([morphState]);
        }

        return {
            ...state,
            morphs: morphs
        }

    }
    }
    return state
}