const hasPersistence = () => ! !window.localStorage;

/**
 *
 * @param key
 * @param value
 */
export const persistJSON = (key, value) => {
    if (hasPersistence()) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    return value;
};

export const persist = (key, value) => {
    if (hasPersistence()) {
        localStorage.setItem(key, value);
    }
    return value;
}

export const removeKey = (key) => {
    if (hasPersistence()) {
        localStorage.removeItem(key);
    }
};


/**
 *
 * @param key
 * @param defaultValue
 * @returns {*}
 */
export const getPersistKey = (key, defaultValue) => {

    if (hasPersistence()) {
        return localStorage.getItem(key);
    }

    return defaultValue;
};


/**
 *
 * @param key
 * @param defaultValue
 * @returns {*}
 */
export const getPersistJSONKey = (key, defaultValue) => {

    if (hasPersistence()) {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
    }

    return defaultValue;
};


export const getAuthToken = () => getPersistKey('auth_token');
export const setAuthToken = token => {
    if(!token) return;    
    persist('auth_token', token)
}

export const unsetAuthToken = () => removeKey('auth_token')

export const getMorphUserId = () => getPersistKey('morph');
export const setMorphUserId = id => persist('morph', parseInt(id));
export const unsetMorphUserId = () => removeKey('morph');

