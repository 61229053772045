import { HIDE_MESSAGE, INIT_URL, ON_HIDE_LOADER, ON_SHOW_LOADER, SHOW_MESSAGE, SIGNIN_FACEBOOK_USER_SUCCESS, SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS, SIGNUP_USER_SUCCESS } from "constants/ActionTypes";
import { unsetAuthToken, unsetMorphUserId } from "../../util/persistence";
import { SET_MORPH_USER, UNSET_MORPH_USER, SET_NEW_MORPH_USER } from "../../constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authToken: localStorage.getItem('auth_token'),
    morph: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload
        }
    }
    case SIGNIN_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authToken: action.payload
        }
    }
    case INIT_URL: {
        return {
            ...state,
            initURL: action.payload
        }
    }

    case SIGNOUT_USER_SUCCESS: {
        unsetAuthToken();
        unsetMorphUserId()
        return {
            ...state,
            authToken: null,
            initURL: '/',
            loader: false
        }
    }

    case SHOW_MESSAGE: {
        return {
            ...state,
            alertMessage: action.payload,
            showMessage: true,
            loader: false
        }
    }
    case HIDE_MESSAGE: {
        return {
            ...state,
            alertMessage: '',
            showMessage: false,
            loader: false
        }
    }

    case SIGNIN_FACEBOOK_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload
        }
    }

    case ON_SHOW_LOADER: {
        return {
            ...state,
            loader: true
        }
    }
    case ON_HIDE_LOADER: {
        return {
            ...state,
            loader: false
        }
    }

    case SET_NEW_MORPH_USER:
    case SET_MORPH_USER:
        return {
            ...state,
            morph: action.payload.id
        }

    case UNSET_MORPH_USER:
        return {
            ...state,
            morph: null
        }

    default:
        return state;
    }
}
