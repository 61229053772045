import { get } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Price from '../../../Competition/Price';
import SelectField from '../SelectField';

const FIELD_SEARCH_THRESHOLD = 10;

class CustomSelect extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: props.value || ""
        }
    }

    componentDidMount() {
    }

    handleChange(field, value) {

        const { price } = this.props;

        this.setState({
            value: value
        });

        const selectedOption = field.options.find(o => o.value === value);

        let label = "";
        if (selectedOption) {
            label = `${field.label}: ${selectedOption.label}`;
        }

        this.props.cb(value, field.name, {
            label: label,
            value: get(price, `additions.${field.name}_${value}`, 0),
            field_value: value
        });
    }

    render() {
        let { field, price, t, validationState, competition } = this.props;

        return (
            <SelectField
                showSearch={field.options.length > FIELD_SEARCH_THRESHOLD}
                filterOption={(input, option) => {
                    return option.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
                }}
                name={field.name}
                label={`${field.label} ${field.required ? '*' : ''}`}
                onChange={this.handleChange.bind(this, field)}
                options={[{ value: '', text: t('empty_value') }].concat(field.options.map((o) => {
                    let optionLabel;
                    if (price.additions && price.additions[field.name + '_' + o.value]) {
                        optionLabel = <span>
                            {o.label}&nbsp;
                            (
                            {price.additions[field.name + '_' + o.value] > 0 ? '+' : ''}&nbsp;
                            <Price price={{ price: price.additions[field.name + '_' + o.value] }} competition={competition} />
                            )
                        </span>
                    } else {
                        optionLabel = o.label;
                    }
                    return { value: o.value, text: optionLabel }
                }))}
                value={this.state.value}
                validationState={validationState}
            />
        )
    }
}

export default withTranslation()(CustomSelect)
